import React, { memo, useEffect, useState } from 'react';

import { Icon, Text } from '@printi/printi-components';
import PropTypes from 'prop-types';
import slugify from 'slugify';

import MoreInfos from '../MoreInfos';
import * as S from './FunnelBlock.styles';

const FunnelBlock = ({ children, title, infos, order, isComplete }) => {
  const [idFunnelBlock, setIdFunnelBlock] = useState('');

  useEffect(() => {
    const id = slugify(title, {
      replacement: '',
      remove: /[0-9]/,
      lower: true,
      strict: true,
    });
    setIdFunnelBlock(`funnel-tooltip-${id}`);
  }, [idFunnelBlock, title]);

  return (
    <S.FunnelBlock aria-describedby={idFunnelBlock}>
      <S.FunnelBlockHeader key={idFunnelBlock}>
        <S.FunnelBlockTitle>
          <S.Order complete={isComplete}>
            {isComplete ? <Icon data-testid="icon-order-test" name="Checkmark" width="22" height="22" /> : order}
          </S.Order>
          <Text as="h2" size="xlg" weight="bold">
            {title}
          </Text>
        </S.FunnelBlockTitle>

        {infos && <MoreInfos text={infos} id={idFunnelBlock} />}
      </S.FunnelBlockHeader>
      {children && <S.FunnelBlockBody>{children}</S.FunnelBlockBody>}
    </S.FunnelBlock>
  );
};

FunnelBlock.propTypes = {
  title: PropTypes.string.isRequired,
  infos: PropTypes.string,
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isComplete: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(FunnelBlock);
