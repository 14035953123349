import React, { memo } from 'react';

import { Text } from '@printi/printi-components';
import { formatMoney, formatDate } from '@utils';
import PropTypes from 'prop-types';

import * as S from './styles';

const MatrixDisplay = ({ price, deliveryDueDate, businessDays }) => (
  <S.MatrixDisplayRow isFull>
    <S.DateCol xs={6} lg={12}>
      <Text weight="bold">{formatDate(deliveryDueDate, { month: 'long', day: 'numeric' })}</Text>
      <Text color="gray" weight="normal" size="sm" margin="0.2rem 0">
        {businessDays} dias úteis
      </Text>
    </S.DateCol>

    <S.PriceCol xs={6} lg={12}>
      <Text color="primary" weight="bold" size="lg">
        {formatMoney(price)}
      </Text>
    </S.PriceCol>
  </S.MatrixDisplayRow>
);

MatrixDisplay.propTypes = {
  deliveryDueDate: PropTypes.string,
  businessDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.number,
};

export default memo(MatrixDisplay);
