import React, { memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import StructuredData from '@components/Seo';
import { Breadcrumb, Row, Col, Text } from '@printi/printi-components';
import { cloudFront } from '@utils';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { gtmLandingPageViewItem } from '../../../utils/gtmDataLayers';
import CrossSelling from './CrossSelling';
import Details from './Details';
import Featured from './Featured';
import Informations from './Informations';

const Benefits = dynamic(() => import('@components/Benefits'));
const CallToAction = dynamic(() => import('./CallToAction'));
const Rating = dynamic(() => import('./Rating'));
const TextVideo = dynamic(() => import('@components/TextVideo'));
const Tutorial = dynamic(() => import('./Tutorial'));
const UseTerm = dynamic(() => import('@components/UseTerm'));

const Products = ({ content }) => {
  const {
    product: { contentList: detailsProduct },
    informations: { contentList: productInformation },
    opinions: { contentList: clientsOpinions },
    tutorials: { contentList: tutorials },
  } = content;

  const tutorialVideo = tutorials?.big_box?.videos?.[0];
  const tutorialBlog = tutorials?.small_box?.links;
  const { locale } = useSelector((state) => state.locale);

  const breadcrumb = useMemo(
    () => [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: detailsProduct?.title,
      },
    ],
    [detailsProduct],
  );
  const { imagelist, title, subtitle, final_product_id, minimum_price, minimum_quantity, product_disabled } =
    detailsProduct;
  let inStock = false;

  if (!product_disabled) {
    inStock = true;
  }

  const { list, avgNumber, count } = clientsOpinions;

  const renderComments = list.map((comment) => ({
    '@type': 'http://schema.org/Review',
    itemReviewed: {
      '@type': 'http://schema.org/Organization',
      name: 'Printi',
    },
    author: [
      {
        '@type': 'http://schema.org/Person',
        name: comment.author_opinion,
      },
    ],
    reviewBody: comment.content,
    reviewRating: {
      '@type': 'http://schema.org/Rating',
      bestRating: 5,
      ratingValue: comment.stars,
      worstRating: 1,
    },
  }));

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    description: subtitle,
    name: title,
    offers: {
      '@type': 'Offer',
      availability: `http://schema.org/${inStock ? 'InStock' : 'OutOfStock'}`,
      price: minimum_price.replace(',', '.'),
      priceCurrency: locale.CURRENCY,
    },
    image: `${cloudFront}${imagelist[0].filename}`,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: avgNumber,
      reviewCount: count,
    },
    review: renderComments,
  };

  useEffect(() => {
    const gtmQty = minimum_quantity > 0 ? parseInt(minimum_quantity, 10) : 1;
    const gtmPriceUnit = parseFloat((minimum_price.replace(',', '.') / gtmQty).toFixed(2));
    gtmLandingPageViewItem({
      items: [
        {
          item_name: title,
          item_id: final_product_id,
          price: gtmPriceUnit,
        },
      ],
    });
  }, [final_product_id, minimum_quantity, minimum_price, title]);

  return (
    <>
      <StructuredData data={structuredData} />
      <Row>
        <Col xs={12}>
          {!detailsProduct || !productInformation ? (
            <Text color="danger" size="lg" weight="bold" align="center" margin="4rem 0 6rem">
              Não conseguimos carregar as informações deste produto, por favor, recarregue a página.
            </Text>
          ) : (
            <>
              <Breadcrumb nextLink={Link} pages={breadcrumb} />
              {detailsProduct && <Featured details={detailsProduct} />}
              {detailsProduct && <Details details={detailsProduct} />}
              {productInformation && <Informations informations={productInformation} />}
              {detailsProduct?.crossSelling.length > 0 && (
                <CrossSelling products={detailsProduct?.crossSelling.slice(0, 4)} />
              )}
              {detailsProduct && <UseTerm slug={detailsProduct.slug} />}
              <Benefits advantages={locale.translate.page.product_landing_page.advantages} product={detailsProduct} />
              {tutorialVideo && tutorialBlog && <Tutorial videoInfo={tutorialVideo} blogInfo={tutorialBlog} />}
              <TextVideo />
              {clientsOpinions && <Rating ratingInfo={clientsOpinions} />}
              {detailsProduct && <CallToAction productInfo={detailsProduct} />}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

Products.propTypes = {
  content: PropTypes.shape({
    tutorials: PropTypes.any,
    product: PropTypes.any,
    opinions: PropTypes.any,
    informations: PropTypes.any,
  }).isRequired,
};

export default memo(Products);
