import React, { memo, useCallback, useRef } from 'react';

import BannerLP from '@components/BannerLP';

import ContactForm from './ContactForm';
import * as S from './CorporateSale.styles';
import FourBusiness from './FourBusiness';
import MainBenefits from './MainBenefits';

const CorporateSale = ({ content }) => {
  const formRef = useRef(null);
  const {
    banner: { contentList: banner },
    mainBenefits: { contentList: mainBenefits },
    fourBusiness: { contentList: fourBusiness },
    hubspot_form: { contentList: hubspotFormSettings },
  } = content;
  const handleClickBanner = useCallback((e) => {
    e.preventDefault();
    if (formRef !== null) {
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <S.PageBackground>
      {banner && <BannerLP content={banner[0]} onClick={handleClickBanner} />}
      {mainBenefits && <MainBenefits mainBenefits={mainBenefits} />}
      {fourBusiness && <FourBusiness fourBusiness={fourBusiness} />}
      {!!hubspotFormSettings?.hubspot_enable_form && (
        <ContactForm formRef={formRef} hubspotFormSettings={hubspotFormSettings} />
      )}
    </S.PageBackground>
  );
};

export default memo(CorporateSale);
