import React, { memo } from 'react';

import Locale from '@components/Locale';
import { useRouter } from 'next/router';

import LandingPages from '../contents/LandingPages';
import Settings from '../contents/ProductsSettings';

const Component = () => {
  const router = useRouter();
  const { slug, combId, templateId, edit } = router.query;

  if (Array.isArray(slug)) {
    if (slug[0] && slug[0].indexOf('configuracao-') === 0) {
      const slugId = slug[0].replace('configuracao-', '');

      return (
        <Settings slugId={slugId} combId={combId || ''} templateId={templateId || ''} templateIsEditable={edit || ''} />
      );
    }
  }

  return <LandingPages />;
};

const ProductSettings = () => (
  <Locale>
    <Component />
  </Locale>
);

export default memo(ProductSettings);
