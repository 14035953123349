import React, { memo } from 'react';

import { cloudFront } from '@utils';
import PropTypes from 'prop-types';

import ArrowLeft from './assets/ArrowLeft.svg';
import ArrowRight from './assets/ArrowRight.svg';
import * as S from './ProductImages.styles';
const SliderArrow = ({ position, currentSlide, slideCount, ...props }) => {
  let cssClasses = `slick-prev slick-arrow ${currentSlide === 0 ? 'slick-disabled' : ''}`;
  let isDisabled = currentSlide === 0;

  if (position === 'right') {
    cssClasses = `slick-next slick-arrow ${currentSlide === slideCount - 1 && 'slick-disabled'}`;
    isDisabled = currentSlide === slideCount - 1;
  }

  return (
    <button {...props} className={cssClasses} aria-hidden="true" aria-disabled={isDisabled} type="button">
      {position === 'left' && <ArrowLeft />}
      {position === 'right' && <ArrowRight />}
    </button>
  );
};

SliderArrow.propTypes = {
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  position: PropTypes.oneOf(['left', 'right']),
};

const ProductImages = ({ banners }) => {
  const settings = {
    arrows: true,
    autoplay: false,
    speed: 1000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    prevArrow: <SliderArrow position="left" />,
    nextArrow: <SliderArrow position="right" />,
  };

  return (
    <S.ProductImages {...settings}>
      {banners.map(({ id, title, filename }) => (
        <img key={id} src={`${cloudFront}${filename}`} alt={title} itemProp="image" width={530} height={420} />
      ))}
    </S.ProductImages>
  );
};

ProductImages.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  children: PropTypes.node,
};

export default memo(ProductImages);
