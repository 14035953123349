/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';

import { Row, Col, Text } from '@printi/printi-components';

import * as S from './ContactForm.styles';

const HandleLoadHubspot = (hubspotFormSettings) => {
  const hubspotForm = document.getElementById('hubspot-form');
  if (!hubspotForm) {
    const script = document.createElement('script');
    script.id = 'hubspot-form';
    script.setAttribute('type', 'text/javascript');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.head.append(script);

    script.addEventListener('load', () => {
      const hubscript = document.createElement('script');
      hubscript.id = 'hubspot-script';
      // eslint-disable-next-line no-undef
      hbspt.forms.create({
        region: hubspotFormSettings?.hubspot_region || 'na1',
        portalId: hubspotFormSettings?.hubspot_portalId_form || '44103013',
        formId: hubspotFormSettings?.hubspot_form_id || '7985c1c8-c964-4551-a2d1-abf0778ae66c',
        target: '.hubspot-warpper',
      });
    });
  } else {
    hubspotForm.remove();
    HandleLoadHubspot(hubspotFormSettings);
  }
};

const ContactForm = ({ hubspotFormSettings }) => {
  useEffect(() => {
    HandleLoadHubspot(hubspotFormSettings);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Text as="h2" weight="bold" size="xlg" margin="0 0 2rem">
            Preencha os campos abaixo para que um dos nossos executivos entre em contato:
          </Text>
          <S.HorizontalSeparator />
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="hubspot-warpper" className="hubspot-warpper"></div>
        </Col>
      </Row>
    </>
  );
};

export default ContactForm;
