import React from 'react';

import { theme } from '@printi/printi-components';
import { useWindowSize } from '@utils';

const Cimpress3d = () => {
  const { breakpoint } = theme;
  const breakpointXL = parseInt(breakpoint.xl, 10) * 16;
  const windowSize = useWindowSize();
  const isMobile = windowSize && windowSize.width < breakpointXL;

  return (
    <svg width="251" height="236" viewBox="0 0 251 236" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4076_6554)">
        <mask id="path-1-inside-1_4076_6554" fill="white">
          <path d="M0 4C0 1.79086 1.79086 0 4 0H247C249.209 0 251 1.79086 251 4V235.633H0V4Z" />
        </mask>
        <path d="M251 0H0V235.633H251V0Z" fill="white" />
        <path d="M251 0H0V235.633H251V0Z" fill="#DBDBDB" />
        <path
          d="M187.627 90.9262C177.189 84.3593 146.707 69.1699 139.849 64.0851C132.991 59.0112 104.503 41.7121 79.94 66.9846C55.3877 92.2678 44.9176 99.9274 56.5198 127.212C68.1221 154.497 109.021 172.694 137.477 171.482C165.933 170.271 185.493 168.085 197.742 129.235C202.217 107.868 194.291 95.124 187.627 90.9262Z"
          fill="#E5F4FD"
        />
        <g opacity="0.7">
          <g opacity="0.7">
            <path opacity="0.7" d="M85.6604 130.627H87.0083L88.3776 123.822H87.0406L85.6604 130.627Z" fill="white" />
          </g>
        </g>
        <path
          d="M103.225 100.247C103.225 100.237 103.225 100.237 103.225 100.226C103.257 100.226 103.3 100.215 103.332 100.247H103.225Z"
          fill="#E9E9E9"
        />
        <path
          d="M110.094 100.238C110.094 100.249 110.094 100.249 110.094 100.259H109.986C110.018 100.216 110.062 100.238 110.094 100.238Z"
          fill="#E9E9E9"
        />
        <path d="M64.5365 78.2358H54.9568V79.9556H64.5365V78.2358Z" fill="white" />
        <path
          d="M119.223 189.381L190.64 162.444L191.836 146.005M119.223 189.381L78.7845 145.122L77.9404 129.453M119.223 189.381L120.618 170.202M191.836 146.005L143.359 116.769M191.836 146.005L179.628 150.153L171.488 152.918M143.359 116.769L77.9404 129.453M143.359 116.769L135.902 55.6307L142.811 56.1335L153.496 113.835L143.359 116.769ZM77.9404 129.453L91.8571 142.741M120.618 170.202L91.8571 142.741M120.618 170.202L171.488 152.918M142.23 132.295L91.8571 142.741M142.23 132.295L171.488 152.918M142.23 132.295L143.293 117.683M134.061 53.6605L135.048 53.7323L134.981 54.6456L71.4132 55.5282M134.061 53.6605L145.051 52.624L143.808 42.4342L135.324 36.308M134.061 53.6605L135.324 36.308M135.324 36.308L134.403 35.3229L65.9663 34.9331L66.4781 55.1691L66.7539 37.7448L69.4491 41.6134L70.4262 55.4564L67.4651 55.2409L67.3322 57.0675M80.0473 127.77L67.2657 57.9807L72.2008 58.3399L80.9114 115.897L80.0473 127.77Z"
          stroke="#AFAFAF"
        />
        <path d="M79.8904 127.356C79.7934 128.688 79.7692 129.022 79.7692 129.022" stroke="#AFAFAF" />
        <path d="M96.8163 122.185H75.1189V130.628H96.8163V122.185Z" fill="#0E55DE" />
        <g opacity="0.7">
          <g opacity="0.7">
            <path
              opacity="0.7"
              d="M81.7914 126.407L84.4537 127.242V128.151L80.5654 126.859V125.943L84.4537 124.645V125.561L81.7914 126.407Z"
              fill="white"
            />
            <path
              opacity="0.7"
              d="M91.4043 126.859L87.5161 128.151V127.242L90.1784 126.407L87.5161 125.561V124.645L91.4043 125.943V126.859Z"
              fill="white"
            />
          </g>
        </g>
        <path d="M157.44 78.9141H133.167V88.523H157.44V78.9141Z" fill="#0E55DE" />
        <g opacity="0.7">
          <g opacity="0.7">
            <path opacity="0.7" d="M145.486 80.7417H136.862V81.543H145.486V80.7417Z" fill="white" />
          </g>
        </g>
        <g opacity="0.7">
          <g opacity="0.7">
            <path opacity="0.7" d="M147.734 82.3364H142.026V83.1377H147.734V82.3364Z" fill="white" />
          </g>
        </g>
        <g opacity="0.7">
          <g opacity="0.7">
            <path opacity="0.7" d="M140.764 82.3364H136.862V83.1377H140.764V82.3364Z" fill="white" />
          </g>
        </g>
        <g opacity="0.7">
          <g opacity="0.7">
            <path opacity="0.7" d="M149.711 83.9331H136.862V84.7344H149.711V83.9331Z" fill="white" />
          </g>
        </g>
        <g opacity="0.7">
          <g opacity="0.7">
            <path opacity="0.7" d="M143.671 85.5356H136.862V86.337H143.671V85.5356Z" fill="white" />
          </g>
        </g>
        <path
          d="M219.844 178.712C219.844 178.702 219.844 178.702 219.844 178.693C219.872 178.693 219.91 178.684 219.939 178.712H219.844Z"
          fill="#E9E9E9"
        />
        <path
          d="M225.884 178.693C225.884 178.702 225.884 178.702 225.884 178.712H225.789C225.818 178.684 225.856 178.693 225.884 178.693Z"
          fill="#E9E9E9"
        />
        <path
          d="M231.843 186.323H152.75C150.985 186.323 149.553 184.899 149.553 183.145V128.29C149.553 126.535 150.985 125.111 152.75 125.111H231.843C233.607 125.111 235.039 126.535 235.039 128.29V183.145C235.049 184.899 233.607 186.323 231.843 186.323ZM152.75 126.281C151.63 126.281 150.729 127.186 150.729 128.29V183.145C150.729 184.258 151.64 185.154 152.75 185.154H231.843C232.962 185.154 233.863 184.248 233.863 183.145V128.29C233.863 127.177 232.952 126.281 231.843 126.281H152.75Z"
          fill="white"
        />
        <path
          d="M234.8 131.883H150.481V128.299C150.481 126.866 151.648 125.706 153.09 125.706H232.183C233.624 125.706 234.791 126.866 234.791 128.299V131.883H234.8Z"
          fill="white"
        />
        <path
          d="M206.451 138.113C202.726 137.888 198.968 137.776 195.164 137.776C191.36 137.776 187.568 137.888 183.786 138.113C183.028 138.158 182.349 138.777 182.247 139.519L179.745 157.207C179.541 158.624 180.65 159.883 182.088 159.883C182.088 159.883 182.292 159.883 182.36 159.883C185.711 160.007 188.213 163.032 187.964 166.36C187.579 171.476 183.288 175.412 178.216 175.412C177.616 175.412 177.107 175.851 177.016 176.447L177.005 176.525C176.926 177.076 177.232 177.593 177.752 177.807C181.035 179.134 184.749 179.348 188.326 178.144C197.496 175.086 203.654 167.113 204.968 158.028L207.583 139.508C207.696 138.777 207.198 138.158 206.451 138.113Z"
          fill="#0E55DE"
        />
        <path
          d="M93.8028 113.245H19.4052C17.7455 113.245 16.3982 111.832 16.3982 110.092V55.6825C16.3982 53.9424 17.7455 52.5298 19.4052 52.5298H93.8028C95.4623 52.5298 96.8103 53.9424 96.8103 55.6825V110.092C96.8103 111.832 95.4623 113.245 93.8028 113.245ZM19.4052 53.6898C18.3523 53.6898 17.5046 54.5879 17.5046 55.6825V110.092C17.5046 111.196 18.3612 112.085 19.4052 112.085H93.8028C94.8561 112.085 95.7032 111.186 95.7032 110.092V55.6825C95.7032 54.5786 94.8473 53.6898 93.8028 53.6898H19.4052Z"
          fill="white"
        />
        <path
          d="M96.2582 59.2473H16.9436V55.6923C16.9436 54.2704 18.0411 53.1196 19.3973 53.1196H93.795C95.1511 53.1196 96.2486 54.2704 96.2486 55.6923L96.2582 59.2473Z"
          fill="white"
        />
        <path
          d="M21.3861 55.6673C21.3861 56.3035 20.7933 56.8273 20.0491 56.8273C19.3166 56.8273 18.7122 56.3129 18.7122 55.6673C18.7122 55.0312 19.3059 54.5073 20.0491 54.5073C20.7933 54.5166 21.3861 55.0312 21.3861 55.6673Z"
          fill="#05B884"
        />
        <path
          d="M25.3991 55.6673C25.3991 56.3035 24.8062 56.8273 24.0621 56.8273C23.3179 56.8273 22.7251 56.3129 22.7251 55.6673C22.7251 55.0312 23.3179 54.5073 24.0621 54.5073C24.8062 54.5073 25.3991 55.0312 25.3991 55.6673Z"
          fill="#05B884"
        />
        <path
          d="M29.3988 55.6673C29.3988 56.3035 28.8051 56.8273 28.0618 56.8273C27.3284 56.8273 26.7249 56.3129 26.7249 55.6673C26.7249 55.0312 27.3177 54.5073 28.0618 54.5073C28.8051 54.5166 29.3988 55.0312 29.3988 55.6673Z"
          fill="#05B884"
        />
        <path
          d="M155.115 128.7C155.115 129.337 154.522 129.861 153.778 129.861C153.046 129.861 152.441 129.346 152.441 128.7C152.441 128.064 153.035 127.541 153.778 127.541C154.522 127.55 155.115 128.064 155.115 128.7Z"
          fill="#0E55DE"
        />
        <path
          d="M159.128 128.7C159.128 129.337 158.535 129.861 157.791 129.861C157.047 129.861 156.454 129.346 156.454 128.7C156.454 128.064 157.047 127.541 157.791 127.541C158.535 127.541 159.128 128.064 159.128 128.7Z"
          fill="#0E55DE"
        />
        <path
          d="M163.128 128.7C163.128 129.337 162.534 129.861 161.791 129.861C161.057 129.861 160.454 129.346 160.454 128.7C160.454 128.064 161.047 127.541 161.791 127.541C162.534 127.55 163.128 128.064 163.128 128.7Z"
          fill="#0E55DE"
        />
        <path d="M33.7609 68.1963H26.1006V69.6832H33.7609V68.1963Z" fill="white" />
        <path d="M36.8846 70.6772H29.2244V72.1643H36.8846V70.6772Z" fill="#05B884" />
        <path d="M55.4911 73.1685H33.7512V74.6548H55.4911V73.1685Z" fill="white" />
        <path d="M65.8013 75.6592H33.7512V77.1455H65.8013V75.6592Z" fill="#B366FF" />
        <path d="M73.5924 78.1514H33.7512V79.6385H73.5924V78.1514Z" fill="#8000FF" />
        <path d="M43.2945 80.6313H29.2244V82.1177H43.2945V80.6313Z" fill="#05B884" />
        <path d="M41.2903 83.124H33.7512V84.6111H41.2903V83.124Z" fill="#8000FF" />
        <path d="M46.4503 85.6147H33.7512V87.1011H46.4503V85.6147Z" fill="#B366FF" />
        <path d="M41.2903 90.5874H33.7512V92.0737H41.2903V90.5874Z" fill="white" />
        <path d="M41.2903 93.0776H33.7512V94.564H41.2903V93.0776Z" fill="#05B884" />
        <path d="M70.6137 92.8369H61.8152V94.3233H70.6137V92.8369Z" fill="#8000FF" />
        <path d="M36.8846 95.5703H29.2244V97.0566H36.8846V95.5703Z" fill="white" />
        <path d="M41.2903 98.0498H33.7512V99.5369H41.2903V98.0498Z" fill="#B366FF" />
        <path d="M55.4911 100.543H33.7512V102.029H55.4911V100.543Z" fill="#8000FF" />
        <path
          d="M215.88 201.69C215.88 203.883 166.999 205.653 118.415 205.653C69.8322 205.653 39.9548 203.883 39.9548 201.69C39.9548 199.497 69.8322 197.727 118.415 197.727C166.999 197.727 215.88 199.511 215.88 201.69Z"
          fill="#979797"
          fillOpacity="0.64"
        />
        {!isMobile && (
          <>
            <rect x="172" y="16" width="63" height="19" rx="9.5" fill="#FF004E" />
            <path
              d="M196.16 30H194.66L190.46 23.556V30H189.02V21.492H190.856L194.72 27.528V21.492H196.16V30ZM199.816 28.44C200.136 28.768 200.524 28.932 200.98 28.932C201.436 28.932 201.82 28.768 202.132 28.44C202.452 28.112 202.612 27.656 202.612 27.072C202.612 26.496 202.452 26.048 202.132 25.728C201.82 25.4 201.436 25.236 200.98 25.236C200.524 25.236 200.136 25.4 199.816 25.728C199.504 26.048 199.348 26.496 199.348 27.072C199.348 27.648 199.504 28.104 199.816 28.44ZM200.98 23.976C201.86 23.976 202.584 24.268 203.152 24.852C203.728 25.436 204.016 26.176 204.016 27.072C204.016 27.968 203.728 28.712 203.152 29.304C202.584 29.888 201.86 30.18 200.98 30.18C200.108 30.18 199.384 29.888 198.808 29.304C198.24 28.712 197.956 27.968 197.956 27.072C197.956 26.176 198.24 25.436 198.808 24.852C199.384 24.268 200.108 23.976 200.98 23.976ZM210.826 24.156L208.534 30H207.142L204.766 24.156H206.302L207.85 28.332L209.362 24.156H210.826ZM213.439 28.44C213.759 28.768 214.147 28.932 214.603 28.932C215.059 28.932 215.443 28.768 215.755 28.44C216.075 28.112 216.235 27.656 216.235 27.072C216.235 26.496 216.075 26.048 215.755 25.728C215.443 25.4 215.059 25.236 214.603 25.236C214.147 25.236 213.759 25.4 213.439 25.728C213.127 26.048 212.971 26.496 212.971 27.072C212.971 27.648 213.127 28.104 213.439 28.44ZM214.603 23.976C215.483 23.976 216.207 24.268 216.775 24.852C217.351 25.436 217.639 26.176 217.639 27.072C217.639 27.968 217.351 28.712 216.775 29.304C216.207 29.888 215.483 30.18 214.603 30.18C213.731 30.18 213.007 29.888 212.431 29.304C211.863 28.712 211.579 27.968 211.579 27.072C211.579 26.176 211.863 25.436 212.431 24.852C213.007 24.268 213.731 23.976 214.603 23.976Z"
              fill="white"
            />
          </>
        )}
      </g>
      <path
        d="M-1 4C-1 1.23858 1.23858 -1 4 -1H247C249.761 -1 252 1.23858 252 4H250C250 2.34315 248.657 1 247 1H4C2.34315 1 1 2.34315 1 4H-1ZM251 235.633H0H251ZM-1 235.633V4C-1 1.23858 1.23858 -1 4 -1V1C2.34315 1 1 2.34315 1 4V235.633H-1ZM247 -1C249.761 -1 252 1.23858 252 4V235.633H250V4C250 2.34315 248.657 1 247 1V-1Z"
        fill="#D4DADE"
        mask="url(#path-1-inside-1_4076_6554)"
      />
      <defs>
        <clipPath id="clip0_4076_6554">
          <path d="M0 4C0 1.79086 1.79086 0 4 0H247C249.209 0 251 1.79086 251 4V235.633H0V4Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Cimpress3d;
