import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, space } = theme;

export const Wrapper = styled.div`
  @media (min-width: ${breakpoint.lg}) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    h1 {
      font-size: 2.2rem;
    }
  }
`;

export const WrapperInfos = styled.div`
  width: 100%;
  margin-bottom: ${space.xl};

  @media (min-width: ${breakpoint.lg}) {
    width: 50%;

    &:first-of-type {
      margin-right: ${space.xl3};
    }
  }
`;

export const ProductAvailableForm = styled.form`
  margin: 0;

  fieldset {
    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoint.xl}) {
      flex-direction: row;
    }
  }
`;

export const WrapperTeaser = styled.div`
  padding-top: ${space.lg};

  p {
    margin: 0;
    padding: 0;
    text-align: left;
    font-weight: 400;
    font-size: ${space.sm};
    line-height: 1.333333333;
  }
`;
