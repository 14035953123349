import React, { memo, useState, useCallback, useMemo } from 'react';

import { Text } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './DebugMatrix.styles';

const DebugMatrix = ({ matrix }) => {
  const {
    rows,
    dates,
    selection: { quantity, date, isCustom },
    customQuantity,
  } = matrix;

  const [isOpen, setIsOpen] = useState(quantity && date);

  const singleFieldColumns = useMemo(
    () => ['column', 'quantity', 'expected_delivery_date', 'upload_deadline_time', 'upload_deadline_date'],
    [],
  );

  const infosMatrix = {
    column: date,
    quantity,
  };

  if (quantity && date) {
    const debug = isCustom ? customQuantity.rows[quantity][date] : rows[quantity][date];
    const deliveryDueDate = isCustom ? customQuantity.dates[date].delivery_due_date : dates[date].delivery_due_date;
    infosMatrix.production_type = debug.production_type;
    infosMatrix.delivery_method = debug.delivery_method;
    infosMatrix.expected_delivery_date = deliveryDueDate;
    infosMatrix.upload_deadline_date = debug.upload_deadline_date;
    infosMatrix.upload_deadline_time = debug.upload_deadline_time;
    infosMatrix.prices = debug.prices;
    infosMatrix.weight = debug.weight;
    infosMatrix.custom_quantity = debug.custom_quantity;
  }

  const handleOpen = useCallback(() => {
    setIsOpen((oldState) => !oldState);
  }, []);

  return (
    <S.DebugMatrix role="button" tabIndex="0" isOpen={isOpen} onClick={handleOpen}>
      <Text weight="bold" align="center" padding="0.75rem 0 1rem">
        Debug da Matrix
      </Text>

      <S.MatrixList>
        {Object.entries(infosMatrix).map(([key, value]) => (
          <li key={key}>
            <b>{key}: </b>
            {singleFieldColumns.includes(key) ? value : ''}

            {!singleFieldColumns.includes(key) && (
              <ul>
                {value &&
                  Object.entries(value).map(([childKey, childValue]) => (
                    <li key={childKey}>
                      <b>{childKey}: </b>
                      {childValue}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </S.MatrixList>
    </S.DebugMatrix>
  );
};

DebugMatrix.propTypes = {
  matrix: PropTypes.object.isRequired,
};

export default memo(DebugMatrix);
