import React, { memo } from 'react';

import PropTypes from 'prop-types';

import StarFillIcon from './assets/StarFillIcon.svg';
import StarOutlineIcon from './assets/StarOutlineIcon.svg';

const RatingStar = ({ numStar }) => {
  const renderRatings = () => {
    let i = 0;
    const stars = parseFloat(numStar);

    const StarFill = [...Array(stars)].map(() => <StarFillIcon key={i++} width={18} height={18} />);
    const ii = 5 - i;
    const StarOutline = [...Array(ii)].map(() => <StarOutlineIcon key={i++} width={18} height={18} />);
    return StarFill.concat(StarOutline);
  };
  return renderRatings();
};

RatingStar.propTypes = {
  numStar: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RatingStar.defaultProps = {
  numStar: 5,
};

export default memo(RatingStar);
