import { theme, Button } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, color, font, space } = theme;

export const PartsLabel = styled.div`
  width: calc(100% + ${space.xl});
  margin-left: -${space.md};
  margin-bottom: ${space.lg};
  padding: ${space.md} ${space.lg};
  background-color: ${color.primary.dark};
  color: ${color.grayscale.white};
  font-weight: ${font.weight.bold};
  font-size: ${font.size.h6};
  text-align: center;
`;

export const ViewType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.lg};

  @media (min-width: ${breakpoint.lg}) {
    flex-direction: row;
  }
`;

export const WrapperOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${space.xl};
`;

export const WrapperCollapse = styled.div`
  margin-bottom: ${space.lg};
`;

export const CollapseBody = styled.div`
  padding: 0 ${space.xs};
`;

export const RemoveButton = styled(Button)`
  color: ${color.danger.dark};
  font-size: ${font.size.xsmall};
  position: absolute;
  right: 0;
  text-decoration: underline;
  transform: translateY(-75%);
  padding: ${space.md};
  position: absolute;
  padding: ${space.md};
  right: ${space.xl};
`;
