import { useCallback, useState, useEffect } from 'react';

import { customApi } from '@utils';

const useFetchLandingPage = () => {
  const [landingPage, setLandingPage] = useState({});
  const [landingPageIsLoading, setLandingPageIsLoading] = useState(false);
  const [landingPageError, setLandingPageError] = useState('');

  const getLandingPage = useCallback(async (slug) => {
    try {
      setLandingPageIsLoading(true);
      const response = await customApi.get(`/v1/landing_page_get/${slug}`);
      setLandingPage(response.data);
    } catch (error) {
      const { status, data } = error.response;
      if ([301, 302].includes(status)) {
        window.location.href = data;
      } else if (status === 404) {
        setLandingPageError('404');
      } else {
        setLandingPageError('Ocorreu um erro! Tente novamente.');
      }
    } finally {
      setLandingPageIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (landingPageError === '404') {
      window.location.href = '/404';
    }
  }, [landingPageError]);

  return { getLandingPage, landingPage, landingPageIsLoading, landingPageError };
};

export default useFetchLandingPage;
