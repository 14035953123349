import Slider from 'react-slick';

import { Buttons, Row, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, color, space } = theme;

export const InformationsRow = styled(Row)`
  margin-bottom: ${space.xl};
`;

export const Carousel = styled(Slider)`
  position: relative;
  background-color: ${color.grayscale.white};

  .slick-dots {
    position: absolute;
    bottom: ${space.md};
    right: 50%;
    transform: translateX(50%);
    width: auto;
    height: auto;
    line-height: 0;

    li {
      width: ${space.xs};
      height: ${space.xs};
      margin: 0 ${space.xs};

      button {
        background-color: lightgray;
        border-radius: 50%;
        width: ${space.xs};
        height: ${space.xs};
        padding: 0;
        transition: 0.3s ease;

        &:before {
          display: none;
        }

        &:hover,
        &:focus {
          background-color: gray;
        }
      }

      &.slick-active {
        button {
          background-color: ${color.primary.dark};
        }
      }
    }
  }
`;

export const CardInfo = styled.div`
  display: initial;
  padding: ${space.xs} ${space.md};

  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.md} ${space.xl2};
    display: flex !important;
  }
`;

export const CardImage = styled.div`
  min-width: 17rem;
  text-align: center;
  margin-bottom: ${space.xs2};

  @media (min-width: ${breakpoint.lg}) {
    text-align: initial;
    margin-bottom: 0;
  }
`;

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;

  @media (min-width: ${breakpoint.lg}) {
    margin-left: ${space.md};
  }

  h4 {
    @media (max-width: ${breakpoint.xl}) {
      font-size: 1.2rem;
    }
  }

  p {
    margin-bottom: ${space.xs2};
  }

  > p {
    color: ${color.grayscale.dark};
    font-size: 0.9rem;
    line-height: 1.5;

    @media (min-width: ${breakpoint.xl}) {
      font-size: 1.1rem;
    }
  }
`;

export const CardTitle = styled.h4`
  color: ${color.grayscale.black};
  font-size: 2.1rem;
  line-height: 1.43;

  @media (min-width: ${breakpoint.xl}) {
    font-weight: 300;
    font-size: 3.6rem;
  }
`;

export const ButtonsGroup = styled(Buttons)`
  width: 100%;
  text-align: center;
  margin-bottom: ${space.md};
  button {
    margin-right: 1rem;
  }
`;
