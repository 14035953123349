import React from 'react';

const HireDesigner = () => (
  <svg width="245" height="230" viewBox="0 0 245 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect width="245" height="230" fill="white" />
      <rect width="245" height="230" fill="#DBDBDB" />
      <path
        d="M131.62 12.2725H27.9014V115.991H131.62V12.2725ZM48.4666 115.085H28.8071V95.4256H48.4666V115.085ZM48.4666 94.5199H28.8071V74.8604H48.4666V94.5199ZM48.4666 73.968H28.8071V54.3086H48.4666V73.968ZM48.4666 53.4028H28.8071V33.7434H48.4666V53.4028ZM48.4666 32.8376H28.8071V13.1782H48.4666V32.8376ZM69.0317 115.085H49.3723V95.4256H69.0317V115.085ZM69.0317 94.5199H49.3723V74.8604H69.0317V94.5199ZM69.0317 73.968H49.3723V54.3086H69.0317V73.968ZM69.0317 53.4028H49.3723V33.7434H69.0317V53.4028ZM69.0317 32.8376H49.3723V13.1782H69.0317V32.8376ZM89.5969 115.085H69.9375V95.4256H89.5969V115.085ZM89.5969 94.5199H69.9375V74.8604H89.5969V94.5199ZM89.5969 73.968H69.9375V54.3086H89.5969V73.968ZM89.5969 53.4028H69.9375V33.7434H89.5969V53.4028ZM89.5969 32.8376H69.9375V13.1782H89.5969V32.8376ZM110.162 115.085H90.5027V95.4256H110.162V115.085V115.085ZM110.162 94.5199H90.5027V74.8604H110.162V94.5199V94.5199ZM110.162 73.968H90.5027V54.3086H110.162V73.968V73.968ZM110.162 53.4028H90.5027V33.7434H110.162V53.4028V53.4028ZM110.162 32.8376H90.5027V13.1782H110.162V32.8376V32.8376ZM130.714 115.085H111.055V95.4256H130.714V115.085ZM130.714 94.5199H111.055V74.8604H130.714V94.5199ZM130.714 73.968H111.055V54.3086H130.714V73.968ZM130.714 53.4028H111.055V33.7434H130.714V53.4028ZM130.714 32.8376H111.055V13.1782H130.714V32.8376Z"
        fill="white"
      />
      <path d="M106.712 34.8621H86.3469V47.995H106.712V34.8621Z" fill="#8000FF" />
      <path d="M67.6568 52.6935L47.825 57.3225L50.8101 70.1114L70.6419 65.4823L67.6568 52.6935Z" fill="white" />
      <path d="M96.5895 25.6051H93.8989V38.5116H96.5895V25.6051Z" fill="#2A3756" />
      <path d="M59.2154 46.2634H56.5249V59.1699H59.2154V46.2634Z" fill="#2A3756" />
      <path d="M117.263 66.1964L114.975 78.899L117.623 79.3761L119.911 66.6735L117.263 66.1964Z" fill="#2A3756" />
      <path d="M220.061 180.39H-41V191.192H220.061V180.39Z" fill="#FFFAE6" />
      <path d="M133.751 115.897H104.874V180.377H133.751V115.897Z" fill="#2A3756" />
      <path d="M142.329 177.393H96.2966V180.377H142.329V177.393Z" fill="#1F293D" />
      <path d="M172.47 85.3694H65.8879V155.483H172.47V85.3694Z" fill="#1F293D" />
      <path d="M169.647 88.2065H68.7654V152.446H169.647V88.2065Z" fill="#2A3756" />
      <path d="M90.0632 99.9542H79.1279V101.886H90.0632V99.9542Z" fill="white" />
      <path d="M94.5248 103.177H83.5896V105.109H94.5248V103.177Z" fill="#05B884" />
      <path d="M121.084 106.414H90.0498V108.345H121.084V106.414Z" fill="white" />
      <path d="M135.802 109.651H90.0498V111.582H135.802V109.651Z" fill="#B366FF" />
      <path d="M146.924 112.887H90.0498V114.819H146.924V112.887Z" fill="#8000FF" />
      <path d="M103.675 116.111H83.5896V118.042H103.675V116.111Z" fill="#05B884" />
      <path d="M100.812 119.347H90.0498V121.279H100.812V119.347Z" fill="#8000FF" />
      <path d="M112.293 119.347H101.531V121.279H112.293V119.347Z" fill="white" />
      <path d="M108.178 122.584H90.0498V124.515H108.178V122.584Z" fill="#B366FF" />
      <path d="M115.53 125.821H90.0498V127.752H115.53V125.821Z" fill="white" />
      <path d="M100.812 129.044H90.0498V130.975H100.812V129.044Z" fill="white" />
      <path d="M100.812 132.28H90.0498V134.211H100.812V132.28Z" fill="#05B884" />
      <path d="M137.147 132.28H101.531V134.211H137.147V132.28Z" fill="white" />
      <path d="M150.466 132.28H137.906V134.211H150.466V132.28Z" fill="#8000FF" />
      <path d="M94.5248 135.517H83.5896V137.448H94.5248V135.517Z" fill="white" />
      <path d="M100.812 138.74H90.0498V140.672H100.812V138.74Z" fill="#B366FF" />
      <path d="M121.084 141.977H90.0498V143.908H121.084V141.977Z" fill="#8000FF" />
      <path
        d="M72.6946 90.564C72.6946 91.2166 72.1751 91.7361 71.5224 91.7361C70.8698 91.7361 70.3503 91.2166 70.3503 90.564C70.3503 89.9113 70.8698 89.3918 71.5224 89.3918C72.1751 89.3785 72.6946 89.9113 72.6946 90.564Z"
        fill="#8000FF"
      />
      <path
        d="M76.5039 90.564C76.5039 91.2166 75.9844 91.7361 75.3318 91.7361C74.6791 91.7361 74.1597 91.2166 74.1597 90.564C74.1597 89.9113 74.6791 89.3918 75.3318 89.3918C75.9711 89.3785 76.5039 89.9113 76.5039 90.564Z"
        fill="#05B884"
      />
      <path
        d="M80.3005 90.564C80.3005 91.2166 79.7811 91.7361 79.1284 91.7361C78.4758 91.7361 77.9563 91.2166 77.9563 90.564C77.9563 89.9113 78.4758 89.3918 79.1284 89.3918C79.7677 89.3785 80.3005 89.9113 80.3005 90.564Z"
        fill="white"
      />
      <path
        d="M37.664 101.886C35.2665 124.262 31.9633 132.773 26.6888 132.214C21.4143 131.655 18.7504 121.052 21.1346 98.6757C23.5321 76.299 36.4519 50.4993 41.7264 51.0587C47.0009 51.6315 40.0615 79.5223 37.664 101.886Z"
        fill="#05B884"
      />
      <path
        d="M26.2635 142.017C25.118 130.509 24.9315 119.187 25.7307 108.385C26.3567 99.7409 27.6087 91.3896 29.4468 83.5711C31.9509 72.9156 35.1076 65.4034 36.4262 62.926L37.3186 63.4055C36.0133 65.8563 32.9099 73.2885 30.4191 83.8642C28.5944 91.6161 27.3557 99.9141 26.7296 108.505C25.9438 119.241 26.1303 130.495 27.2624 141.937L26.2635 142.017Z"
        fill="#048560"
      />
      <path
        d="M28.8866 103.191C21.7607 127.539 21.2679 146.599 27.0485 148.29C32.8292 149.982 35.9326 131.681 43.0585 107.346C50.1844 82.9985 62.052 63.8852 56.2713 62.1936C50.4774 60.502 35.9992 78.8562 28.8866 103.191Z"
        fill="#05B884"
      />
      <path
        d="M26.5425 148.264C27.1285 137.422 28.6869 126.7 31.191 116.417C33.2022 108.186 35.8128 100.194 38.9562 92.6818C43.245 82.4391 47.7204 75.1401 49.4918 72.7026L50.3043 73.302C48.5595 75.7128 44.1241 82.9453 39.8619 93.108C36.7452 100.567 34.1479 108.505 32.15 116.683C29.6592 126.913 28.1009 137.555 27.5281 148.33L26.5425 148.264Z"
        fill="#048560"
      />
      <path
        d="M8.92085 106.667C19.7229 126.513 23.7454 143.016 19.0303 145.573C14.3152 148.144 8.16164 132.8 -2.64041 112.954C-13.4291 93.108 -27.3746 78.723 -22.6595 76.1524C-17.9444 73.5817 -1.86788 86.8212 8.92085 106.667Z"
        fill="#05B884"
      />
      <path
        d="M22.7072 155.856L21.8281 156.029C19.9634 146.652 16.8599 137.022 12.6244 127.405C9.22792 119.707 5.0989 112.008 0.343865 104.496C-6.12937 94.28 -12.536 86.4881 -15.0134 83.7976L-14.3475 83.1849C-11.8567 85.8888 -5.41012 93.7206 1.08975 104.003C5.87143 111.542 10.0271 119.294 13.4369 127.032C17.6991 136.716 20.8158 146.412 22.7072 155.856Z"
        fill="#048560"
      />
      <path
        d="M35.0006 170.88V147.904H12.6506V170.88C12.6506 174.902 14.7684 178.419 17.9518 180.39H29.6995C32.8828 178.419 35.0006 174.902 35.0006 170.88Z"
        fill="white"
      />
      <path
        d="M25.9299 170.054C25.9299 168.443 26.516 167.324 27.6748 166.738C28.6471 166.231 29.8991 166.192 31.0979 166.192C31.3243 166.192 31.5507 166.192 31.7905 166.192C32.27 166.192 32.7894 166.192 33.2689 166.192C33.5087 166.192 33.7351 166.192 33.9749 166.192C35.8129 166.192 39.2227 166.192 39.2227 170.054C39.2227 173.717 36.2392 176.701 32.5763 176.701C28.9002 176.701 25.9299 173.717 25.9299 170.054ZM33.2823 168.363C32.7894 168.363 32.27 168.363 31.7638 168.363C31.5374 168.363 31.311 168.363 31.0845 168.363C28.434 168.363 28.0877 168.709 28.0877 170.054C28.0877 172.518 30.0856 174.516 32.5497 174.516C35.0138 174.516 37.0117 172.518 37.0117 170.054C37.0117 168.802 36.7586 168.363 33.9482 168.363C33.7484 168.363 33.522 168.363 33.2823 168.363Z"
        fill="#997800"
      />
      <path
        d="M32.3899 176.794C32.3899 178.778 34.0015 180.39 35.9861 180.39H45.8292C47.8138 180.39 49.4254 178.778 49.4254 176.794V163.235H32.3899V176.794Z"
        fill="#FFC800"
      />
      <path
        d="M41.4604 173.837C41.4604 175.329 42.6725 176.541 44.1643 176.541C45.6561 176.541 46.8681 175.329 46.8681 173.837V163.488H41.4604V173.837V173.837Z"
        fill="#FFDE66"
      />
      <path
        d="M31.458 163.448C31.458 163.954 31.8709 164.367 32.3771 164.367H49.3593C49.8655 164.367 50.2783 163.954 50.2783 163.448C50.2783 162.942 49.8655 162.529 49.3593 162.529H32.3771C31.8709 162.529 31.458 162.942 31.458 163.448Z"
        fill="#997800"
      />
      <path
        d="M153.757 200.982C153.757 200.982 116.356 203.126 104.195 212.969C99.4803 216.792 86.0676 231.497 86.0676 231.497H216.318C222.179 226.702 222.898 221.227 222.525 217.245C219.795 187.569 153.757 200.982 153.757 200.982Z"
        fill="#005D99"
      />
      <path
        d="M200.668 90.6838L191.704 96.238L184.165 97.1304L185.51 72.2764L198.763 71.9301L200.668 90.6838Z"
        fill="#D3A27A"
      />
      <path
        d="M219.928 98.9819C217.797 96.8641 207.288 90.4308 199.789 89.685C199.576 89.6583 184.831 90.3509 184.525 90.4308C181.088 91.4165 170.087 97.2104 165.305 102.312C165.305 102.312 156.674 110.37 154.41 126.526C154.41 126.526 160.337 157.374 159.178 164.846C156.994 179.098 152.025 190.633 153.73 200.955C162.974 223.558 210.884 224.877 223.431 220.215C233.873 205.005 223.617 124.449 219.928 98.9819Z"
        fill="#6700CC"
      />
      <path
        d="M210.91 204.951L219.727 208.92C219.727 208.92 241.411 185.691 243.729 172.758C246.899 155.096 242.53 119.627 219.927 98.9817C212.641 100.993 211.283 115.498 211.802 129.843C212.348 144.92 227.253 167.563 227.253 167.563L210.91 204.951Z"
        fill="#6700CC"
      />
      <path
        d="M227.054 208.974L226.161 208.854C231.049 170.52 223.591 130.496 223.524 130.096L224.403 129.923C224.47 130.349 231.955 170.507 227.054 208.974Z"
        fill="#4D0099"
      />
      <path
        d="M121.564 179.418C121.564 179.418 118.74 179.977 117.901 180.364L101.385 180.35C100.466 180.39 93.9659 180.523 93.8461 178.299C93.7395 176.288 100.04 176.141 100.572 176.075C101.118 176.008 104.954 175.649 104.888 175.116C104.821 174.57 112.786 171.693 115.503 173.504C118.234 175.329 121.564 179.418 121.564 179.418Z"
        fill="#BA8863"
      />
      <path
        d="M105.034 179.577C105.034 176.101 101.531 173.277 97.2024 173.277C92.8736 173.277 86.3071 176.101 86.3071 179.577C86.3071 179.937 86.3471 180.283 86.4137 180.616H104.928C104.994 180.27 105.034 179.924 105.034 179.577Z"
        fill="#2A3756"
      />
      <path
        d="M106.713 178.446C105.287 178.059 102.357 176.314 101.385 175.875C100.412 175.422 95.2046 173.677 95.2046 173.677C95.2046 173.677 92.6206 178.459 91.3419 179.072C90.0633 179.684 88.8246 179.791 88.9977 179.205C89.9167 176.035 91.4218 172.585 93.4597 170.987C94.8582 169.895 100.319 169.042 100.319 169.042C100.319 169.042 101.758 168.083 102.877 168.123C103.995 168.163 111.428 170.161 111.428 170.161L121.724 170.414L121.564 179.431C121.564 179.418 110.309 179.391 106.713 178.446Z"
        fill="#D3A27A"
      />
      <path
        d="M157.86 113.98C157.86 113.98 151.053 130.562 148.203 165.259C146.272 167.151 116.836 168.443 116.836 168.443L115.61 182.495C115.61 182.495 141.543 193.164 158.685 187.45C166.144 184.959 171.938 179.365 173.204 168.443C174.615 156.229 157.86 113.98 157.86 113.98Z"
        fill="#6700CC"
      />
      <path
        d="M156.287 187.982L156.274 186.93C161.215 186.837 170.059 180.856 172.697 174.636C175.174 168.775 172.537 146.332 172.51 146.106L173.549 145.986C173.656 146.918 176.253 168.922 173.656 175.049C170.818 181.775 161.575 187.889 156.287 187.982Z"
        fill="#4D0099"
      />
      <path
        d="M176.666 65.7897C176.466 64.2047 175.787 62.7662 174.761 61.7406C173.722 60.7017 172.417 60.1822 171.125 60.3021C170.925 60.3288 170.726 60.3554 170.539 60.4087C168.195 61.0214 166.73 63.7518 167.116 66.7753C167.316 68.3604 167.995 69.7989 169.021 70.8245C169.953 71.7568 171.099 72.2763 172.257 72.2763C172.391 72.2763 172.524 72.263 172.657 72.2496C172.857 72.223 173.056 72.1964 173.243 72.1431C175.587 71.5437 177.052 68.8132 176.666 65.7897ZM173.017 71.304C172.857 71.3439 172.71 71.3706 172.55 71.3839C172.444 71.3972 172.337 71.3972 172.231 71.3972C171.298 71.3972 170.366 70.971 169.593 70.1984C168.701 69.306 168.115 68.054 167.942 66.6688C167.609 64.0848 168.821 61.7539 170.766 61.2478C170.912 61.2078 171.072 61.1812 171.232 61.1679C171.338 61.1546 171.445 61.1546 171.551 61.1546C172.484 61.1546 173.416 61.5808 174.189 62.3533C175.081 63.2457 175.667 64.4977 175.84 65.883C176.173 68.4669 174.961 70.7978 173.017 71.304Z"
        fill="white"
      />
      <path
        d="M195.566 58.2777C193.728 50.7256 186.815 47.3292 179.809 47.9419C172.803 48.5679 169.713 53.4694 170.339 60.5021C170.339 60.5021 170.393 61.0482 170.472 61.874C170.566 62.9129 171.165 64.378 171.298 65.7899C171.485 67.8011 171.178 69.1597 171.272 70.2652C171.618 74.7805 177.132 84.6502 182.194 84.2107C188.041 83.6912 197.311 75.0336 196.858 69.9722C196.818 69.546 196.259 61.1414 195.566 58.2777Z"
        fill="#D3A27A"
      />
      <path
        d="M205.516 55.3341C206.049 61.3012 203.372 70.838 198.537 75.6463C198.537 75.6463 192.663 78.0171 181.701 66.0296C181.301 64.0717 176.946 52.4305 172.391 51.5914C174.748 46.4368 180.422 42.3078 187.388 41.6951C192.849 41.2022 197.924 43.0803 201.227 46.6366C204.357 47.3958 205.21 51.8445 205.516 55.3341Z"
        fill="#2C2C3A"
      />
      <path d="M180.581 64.2007L167.485 66.3193L167.621 67.1609L180.717 65.0423L180.581 64.2007Z" fill="white" />
      <path
        d="M180.302 63.2192C180.569 62.3401 181.714 61.3145 183.912 62.5132C186.109 63.7253 187.961 70.9045 184.325 72.4096C180.688 73.9147 179.556 65.6167 180.302 63.2192Z"
        fill="#D3A27A"
      />
      <path
        d="M185.723 80.3079L185.75 83.1582C185.75 83.1582 183.433 84.2371 182.007 84.2238L185.723 80.3079Z"
        fill="#BA8863"
      />
      <path
        d="M172.324 61.1945C172.324 61.1945 171.471 61.1013 170.406 61.1812C169.526 61.2478 169.766 63.4721 170.619 63.3922C171.178 63.3389 171.604 63.2723 171.791 63.1791C172.763 62.7395 172.324 61.1945 172.324 61.1945Z"
        fill="#283558"
      />
      <path
        d="M201.227 46.6233C196.752 39.5774 179.037 34.2363 172.484 37.7126C172.097 41.0025 176.932 44.3457 176.839 44.7853C175.161 44.7053 173.269 42.3611 172.097 41.0025C164.425 52.9767 179.41 53.9757 179.41 53.9757L192.982 54.5217C192.982 54.5217 202.133 47.3159 201.227 46.6233Z"
        fill="#2C2C3A"
      />
      <path
        d="M198.67 44.8119L198.75 44.9184L199.323 44.4789L199.243 44.3724C195.846 39.897 191.118 37.686 187.748 36.6204C184.099 35.4617 181.208 35.3684 181.168 35.3551H181.035L181.022 35.9412V36.0744H181.155C181.275 36.0744 184.072 36.2075 187.548 37.2997C190.812 38.352 195.393 40.4964 198.67 44.8119Z"
        fill="#2C2C3A"
      />
      <path
        d="M207.367 46.3303C206.368 46.1705 205.329 46.0906 204.29 46.0906C199.922 46.0906 196.112 47.5158 193.701 48.7145C190.292 50.4061 188.107 52.3507 188.094 52.364L188.001 52.4573L188.387 52.8968L188.48 52.99L188.573 52.8968C188.8 52.697 190.838 50.9388 194.034 49.3538C196.379 48.195 200.068 46.8098 204.29 46.8098C205.289 46.8098 206.288 46.8897 207.247 47.0363L207.38 47.0629L207.487 46.3436L207.367 46.3303Z"
        fill="#2C2C3A"
      />
      <path
        d="M134.188 96.1339C130.988 95.9436 127.754 95.8467 124.484 95.8433C121.213 95.8399 117.952 95.9376 114.698 96.1364C114.373 96.1624 114.066 96.297 113.826 96.5188C113.587 96.7405 113.428 97.0366 113.375 97.3596L111.222 112.734C111.181 113.025 111.202 113.322 111.285 113.604C111.368 113.886 111.511 114.146 111.704 114.367C111.897 114.589 112.136 114.765 112.403 114.885C112.671 115.006 112.961 115.066 113.254 115.063C113.254 115.063 113.426 115.063 113.485 115.063C116.371 115.168 118.522 117.8 118.309 120.693C118.156 122.821 117.208 124.814 115.654 126.272C114.101 127.729 112.057 128.544 109.93 128.554C109.682 128.558 109.442 128.65 109.256 128.815C109.07 128.98 108.948 129.207 108.914 129.454V129.525C108.882 129.756 108.928 129.991 109.044 130.192C109.161 130.394 109.341 130.55 109.557 130.636C112.453 131.83 115.681 131.936 118.649 130.935V130.935C126.538 128.274 131.834 121.348 132.959 113.45L135.211 97.3596C135.238 97.2095 135.232 97.0551 135.193 96.9076C135.154 96.76 135.083 96.6229 134.986 96.5059C134.888 96.3889 134.766 96.2949 134.628 96.2306C134.49 96.1663 134.34 96.1333 134.188 96.1339V96.1339Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="245" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HireDesigner;
