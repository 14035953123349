import { Box, theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, space, color } = theme;

export const MatrixWarningBox = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin: ${space.lg} 0;

  > div {
    flex: 1;
    padding: ${space.md};
    display: inline-flex;
  }

  @media (max-width: ${breakpoint.lg}) {
    display: block;
  }
`;

export const MatrixInfoBox = styled(MatrixWarningBox)`
  border-color: ${color.success.dark};
  background-color: #e6fff6;
`;

export const MatrixWarningInfo = styled.div`
  flex-direction: column;
  padding-left: ${space.xl};
  width: calc(100% - ${space.xl});
`;
