/* eslint-disable react/no-danger */
import React, { memo, useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

import * as S from './Custom.styles';

const Custom = ({ content }) => {
  const {
    main_lp_content: { contentList },
  } = content;

  const loadScript = useCallback((src, index) => {
    if (document.getElementById(`custom-lp-script-${index}`)) {
      return;
    }
    const oScript = document.createElement('script');
    oScript.id = `custom-lp-script-${index}`;
    oScript.type = 'text/javascript';
    document.body.appendChild(oScript);
    oScript.src = src;
  }, []);

  const loadStyles = useCallback((src, index) => {
    if (document.getElementById(`custom-lp-styles-${index}`)) {
      return;
    }
    const oStyle = document.createElement('link');
    oStyle.id = `custom-lp-styles-${index}`;
    oStyle.type = 'text/css';
    oStyle.rel = 'stylesheet';
    document.head.appendChild(oStyle);
    oStyle.href = src;
  }, []);

  useEffect(() => {
    const { jslist, csslist } = contentList;
    if (jslist.length > 0) {
      const js = jslist.split(',');
      js.forEach((link, index) => {
        loadScript(link, index);
      });
    }

    if (csslist.length > 0) {
      const css = csslist.split(',');
      css.forEach((link, index) => {
        loadStyles(link, index);
      });
    }
  }, [contentList, loadScript, loadStyles]);

  return (
    <S.CustomContainer>
      <div dangerouslySetInnerHTML={{ __html: contentList.body }} />
    </S.CustomContainer>
  );
};

Custom.propTypes = {
  content: PropTypes.any.isRequired,
};

export default memo(Custom);
