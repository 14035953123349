import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { api } from '@utils';
import { useRouter } from 'next/router';

import { gtmAddToCart } from '../../utils/gtmDataLayers';

const useAddToCart = () => {
  const router = useRouter();
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [addToCartError, setAddToCartError] = useState('');
  const { locale } = useSelector((state) => state.locale);
  const productSettingsState = useSelector((state) => state.settings);
  const getAdditionalOptionsStructure = (currentOption, additionalOptions) => {
    const option = additionalOptions[currentOption].filter((attribute) => attribute.price === 0);
    const currentId = option.length === 0 ? additionalOptions[currentOption][0].id : option[0].id;
    if (currentOption === 'extra_cost') {
      return { id: currentId, price: 0 };
    }

    return currentId;
  };

  const uploadFinishAction = useCallback(
    async (itemId, request) => {
      await api.post(`/v2/upload/cart/${itemId}`, request).then(() => {
        router.push(locale.dynamic_routes.cart);
      });
    },
    [locale, router],
  );

  const cimpressTemplatesSetTemplateAction = useCallback(
    async (cartItemId, templateId, productSlug, templateIsEditable) => {
      const endpoint = `/v2/cimpress_template/set_template_by_cart_item/${cartItemId}/template/${templateId}`;
      return api.put(endpoint, {}).then(() => {
        if (parseInt(templateIsEditable, 10) !== 0) {
          router.push(`/editar-modelo/${productSlug}/${cartItemId}`);

          return true;
        }

        uploadFinishAction(cartItemId, {
          isRepurchase: true,
          upload_type: 'canvas',
          upload_strategy: 4,
          auto_upload: true,
        });

        return true;
      });
    },
    [router, uploadFinishAction],
  );

  const addToCart = useCallback(
    async (templateId, templateIsEditable, selectedCombination, isMobile) => {
      const { source, config, finalProduct, product, selection, matrix, additionalOptions } = productSettingsState;
      setAddToCartLoading(true);
      const selectedMatrix = matrix.selection.isCustom ? matrix.customQuantity : matrix;
      const cell = selectedMatrix.rows[matrix.selection.quantity][matrix.selection.date];
      const payload = {
        hash: cell.hash,
        date: matrix.selection.date,
        cart_origin: isMobile ? 'MOBILE' : 'SITE',
        use_pickup_places: config.zipcode.usePickupPlaces,
        pickup_place_id: config.zipcode.usePickupPlaces && config.zipcode.pickupPlaceId,
        zipcode: config.zipcode.usePickupPlaces
          ? config.zipcode.pickupPlaceZipcode.replace('-', '')
          : config.zipcode.value.replace('-', ''),
        item: {
          final_product_id: finalProduct.id,
          type: source.selectedSource,
          product_parts: selection,
          quantity: matrix.selection.quantity,
          is_free_shipping: cell.is_free_shipping,
          options: {
            production_type: cell.production_type.id,
            delivery_method: {
              id: cell.delivery_method.id,
              business_days: cell.delivery_method.fixed_delivery_days,
              price: cell.delivery_method.price,
              provider_shipping_cost: cell.delivery_method.provider_shipping_cost || null,
              price_without_discount: cell.delivery_method.price_without_discount
                ? cell.delivery_method.price_without_discount
                : cell.delivery_method.price,
            },
            ...Object.keys(additionalOptions)
              .filter((option) => additionalOptions[option].length > 0)
              .reduce(
                (prevOption, currentOption) => ({
                  ...prevOption,
                  [currentOption]: getAdditionalOptionsStructure(currentOption, additionalOptions),
                }),
                {},
              ),
          },
        },
        available_additional_options: additionalOptions,
        selectedSource: source.selectedSource,
        uploadType: source.selectedSource === 'cimpress_3d' ? finalProduct.upload_type_3d : finalProduct.upload_type,
        templateId,
        templateIsEditable,
        selectedCombination,
      };

      await api
        .post('/v2/cart', payload)
        .then((response) => {
          if (response.status === 201) {
            const routes = locale.dynamic_routes;
            const { slug } = product;
            const gtmQty = matrix.selection.quantity > 1 ? parseInt(matrix.selection.quantity, 10) : 1;
            const gtmQtyPrice =
              cell.prices && undefined !== cell.prices.quantity_price ? cell.prices.quantity_price : 0;
            const gtmPriceUnit = parseFloat((gtmQtyPrice / gtmQty).toFixed(2));
            gtmAddToCart({
              items: [
                {
                  item_name: product?.title,
                  item_id: finalProduct.id,
                  item_variant: response.data.productVariant ?? '',
                  price: gtmPriceUnit,
                  quantity: gtmQty,
                },
              ],
            });

            switch (payload.selectedSource) {
              case 'template':
                if (templateId) {
                  cimpressTemplatesSetTemplateAction(response.data.id, templateId, slug, templateIsEditable);
                  return true;
                }

                router.push(`/escolher-modelo/${slug}/${response.data.id}`);
                return true;
              case 'upload':
              case 'cimpress_3d':
                if (payload.uploadType === 'skip_upload') {
                  router.push(routes.cart);
                  return true;
                }

                router.push(`/upload/${slug}/${response.data.id}`);
                return true;
              default:
                router.push(routes.cart);
                return true;
            }
          }

          return true;
        })
        .catch(() => {
          setAddToCartError('Algo de errado não está correto');
        });

      setAddToCartLoading(false);
    },
    [cimpressTemplatesSetTemplateAction, locale.dynamic_routes, productSettingsState, router],
  );

  return {
    addToCart,
    addToCartLoading,
    addToCartError,
  };
};

export default useAddToCart;
