import { theme } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, space } = theme;

export const ArtCreationSection = styled.section`
  .content-row {
    display: flex;
    flex-direction: column;
    margin: ${space.xs} 0;

    @media (min-width: ${breakpoint.lg}) {
      flex-direction: row;
    }
  }

  .col-50p {
    padding: 0 0.3rem;

    @media (min-width: ${breakpoint.lg}) {
      padding: 0 ${space.xl};
    }
  }

  ul {
    margin-left: ${space.xs};
    padding: ${space.xs};

    li {
      font-size: 1rem;
      line-height: 1.5rem;
      list-style-type: square;
      margin-bottom: 1rem;
    }
  }
`;
