import { theme, Box, Col, Row } from '@printi/printi-components';
import styled from 'styled-components';

const { space, color, breakpoint } = theme;

export const ColBlock = styled(Col)`
  vertical-align: middle;
  margin: auto;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: left;

  @media (min-width: ${breakpoint.lg}) {
    text-align: center;
  }
`;

export const ColBlockPrice = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  align-items: flex-start;

  @media (min-width: ${breakpoint.lg}) {
    align-items: center;
  }
`;

export const CustomQtyBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  border-color: ${color.primary.pure};
  border-style: dashed;
  margin-bottom: ${space.md};
  padding-bottom: ${space.md};
  padding-top: ${space.md};
  min-height: auto;

  @media (min-width: ${breakpoint.lg}) {
    min-height: 8rem;
  }
`;

export const CustomQtyButton = styled.span`
  margin: auto;
  text-align: center;

  @media (min-width: ${breakpoint.lg}) {
    margin: ${space.xs} auto;
  }
`;

export const InfosRow = styled(Row)`
  z-index: 1;
`;

export const Separator = styled.hr`
  display: none;
  width: 80%;
  padding: 0;
  margin: ${space.xs} auto;

  @media (min-width: ${breakpoint.lg}) {
    display: block;
  }
`;
