export const hasBanner = (banner) => {
  const {
    path: { mobile, desktop, tablet },
  } = banner[0];

  if (mobile || desktop || tablet) {
    return true;
  }

  return false;
};

export const hasMainContent = (mainContent) => {
  const { content_1, content_2 } = mainContent;

  if (content_1 === '' && content_2 === '') {
    return false;
  }

  return true;
};

export const hasProductList = (list) => {
  const { medium, small } = list;

  if (medium || small) {
    return true;
  }

  return false;
};
