import React, { memo, useEffect } from 'react';

import Page from '@components/Page';
import { Loader } from '@printi/printi-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import useFetchLandingPage from './useFetchLandingPage';
const Custom = dynamic(() => import('./Custom'));
const CorporateSale = dynamic(() => import('./CorporateSale'));
const Dynamic = dynamic(() => import('./Dynamic'));
const Product = dynamic(() => import('./Product'));
const Error404 = dynamic(() => import('../Errors/404'));

const LandingPages = () => {
  const router = useRouter();
  const { slug } = router.query;
  const { getLandingPage, landingPage, landingPageIsLoading } = useFetchLandingPage();
  const { seo, type, contentBlocks } = landingPage;

  useEffect(() => {
    getLandingPage(slug);
  }, [getLandingPage, slug]);

  return (
    <Page seo={seo}>
      {landingPageIsLoading && <Loader size="medium" wrapper="20rem" />}
      {!landingPageIsLoading && type === 'product' && <Product content={contentBlocks} />}
      {!landingPageIsLoading && type === 'dynamic' && <Dynamic content={contentBlocks} />}
      {!landingPageIsLoading && type === 'custom' && <Custom content={contentBlocks} />}
      {!landingPageIsLoading && type === 'corporate_sale' && <CorporateSale content={contentBlocks} />}
      {!landingPageIsLoading && type === 'error' && <Error404 />}
    </Page>
  );
};

export default memo(LandingPages);
