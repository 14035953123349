import React, { memo, useCallback } from 'react';

import { CardProduct } from '@printi/printi-components';
import { formatMoney, cloudFront } from '@utils';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import getNavigationLink from './getNavigationLink';
import * as S from './ProductList.styles';

const ProductSeeMore = ({ product, medium }) => {
  const router = useRouter();
  const { product_default_combinations, slug, image, title, text, minimum_price, minimum_quantity, is_externel } =
    product;

  const handleNavigate = useCallback(
    (e) => {
      e.preventDefault();

      if (is_externel) {
        window.open(slug, '_blank');
        return;
      }

      const prodLink = getNavigationLink(slug, product_default_combinations);
      router.push(prodLink);
    },
    [product_default_combinations, slug, router, is_externel],
  );

  return (
    <S.ProductSeeMoreContainer medium={medium}>
      <CardProduct
        href={`/${slug}`}
        onClick={handleNavigate}
        title={title}
        subtitle={text}
        label="A partir de"
        price={formatMoney(minimum_price)}
        unity={minimum_quantity}
      >
        <img src={`${cloudFront}${image.filename}`} alt={image.alt} title={image.alt} width={360} height={270} />
      </CardProduct>
    </S.ProductSeeMoreContainer>
  );
};

ProductSeeMore.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.shape({
      alt: PropTypes.string,
      filename: PropTypes.string,
    }).isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    minimum_price: PropTypes.string.isRequired,
    minimum_quantity: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    product_default_combinations: PropTypes.string,
    is_externel: PropTypes.bool,
  }).isRequired,
  medium: PropTypes.bool,
};

ProductSeeMore.defaultProps = {
  medium: false,
};

export default memo(ProductSeeMore);
