import React, { memo } from 'react';

import { Col } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './ProductList.styles';
import ProductSeeMore from './ProductSeeMore';

const ProductList = ({ content }) => {
  const { medium, small } = content;
  const productList = medium || small;

  return (
    <S.ProductRow isFull>
      <Col>
        {productList &&
          productList.length > 0 &&
          productList.map((product) => <ProductSeeMore key={product.id} product={product} medium={!!medium} />)}
      </Col>
    </S.ProductRow>
  );
};

ProductList.propTypes = {
  content: PropTypes.shape({
    medium: PropTypes.arrayOf(PropTypes.any),
    small: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
};

export default memo(ProductList);
