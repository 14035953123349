/* eslint-disable react/no-danger */
import React, { memo } from 'react';

import { Loader, Icon, Text } from '@printi/printi-components';
import { formatDate } from '@utils';
import PropTypes from 'prop-types';
import { sprintf } from 'sprintf-js';

import * as S from './styles';

const MatrixWarning = ({ locale, matrix, config, pickupPlaces, selectedSource, isDigital }) => {
  const { flash_messages } = locale;
  const isLoadedMatrix = matrix.selection.isCustom
    ? matrix.customQuantity.isLoaded && !matrix.customQuantity.isRunning
    : matrix.isLoaded && !matrix.isRunning;

  if (!isLoadedMatrix) {
    return <Loader size="medium" wrapper="8rem" />;
  }

  const renderDelivery = () => (
    <div>
      <Icon name="AlertTriangleOutline" size="lg" />
      <S.MatrixWarningInfo>
        <Text weight="bold" margin="0 0 0.5rem">
          {flash_messages.delivery_text.ATTENTION}
        </Text>
        <Text size="sm">
          {!isDigital ? (
            <span dangerouslySetInnerHTML={{ __html: flash_messages.delivery_text.cep.DESCRIPTION }} />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: flash_messages.delivery_text.cep.DESCRIPTION_DIGITAL }} />
          )}
        </Text>
      </S.MatrixWarningInfo>
    </div>
  );

  const renderPickupPlaces = () => {
    if (pickupPlaces.length === 0 || !config.zipcode.pickupPlaceId || config.zipcode.pickupPlaceId === 0) {
      return null;
    }

    const selectPickupPlacess = pickupPlaces.filter((pickupPlace) => pickupPlace.id === config.zipcode.pickupPlaceId);

    if (
      selectPickupPlacess.length === 0 ||
      selectPickupPlacess[0] === undefined ||
      !Object.prototype.hasOwnProperty.call(selectPickupPlacess[0], 'freeText')
    ) {
      return null;
    }

    return (
      <div>
        <S.MatrixWarningInfo>
          <Text weight="bold" margin="0 0 0.5rem">
            {flash_messages.delivery_text.ATTENTION}
          </Text>
          <Text size="sm">
            <span>{selectPickupPlacess[0].freeText}</span>
          </Text>
        </S.MatrixWarningInfo>
      </div>
    );
  };

  const renderDeadline = () => {
    const selectedDelivery = matrix.selection.isCustom
      ? matrix.customQuantity.rows[matrix.selection.quantity][matrix.selection.date]
      : matrix.rows[matrix.selection.quantity][matrix.selection.date];
    return (
      <div>
        <Icon name="CalendarOutline" size="lg" />
        <S.MatrixWarningInfo>
          <Text weight="bold" margin="0 0 0.5rem">
            {flash_messages.UPLOAD_DUE_DATE}
          </Text>
          <Text size="sm">
            {sprintf(
              flash_messages.UPLOAD_DATE_MESSAGE,
              formatDate(selectedDelivery.upload_deadline_date),
              formatDate(selectedDelivery.upload_deadline_date.concat(' ', selectedDelivery.upload_deadline_time), {
                hour: 'numeric',
                minute: 'numeric',
              }),
            )}
          </Text>
        </S.MatrixWarningInfo>
      </div>
    );
  };

  return (
    <S.MatrixWarningBox type="gold">
      {matrix.deliveryMethod !== 'pickupPlaces' ? renderDelivery() : renderPickupPlaces()}
      {matrix.selection.quantity > 0 && matrix.selection.date > 0 && selectedSource === 'upload' && renderDeadline()}
    </S.MatrixWarningBox>
  );
};

MatrixWarning.propTypes = {
  locale: PropTypes.object.isRequired,
  matrix: PropTypes.shape({
    customQuantity: PropTypes.object.isRequired,
    dates: PropTypes.object.isRequired,
    deliveryMethod: PropTypes.oneOf(['', 'zipcode', 'pickupPlaces']).isRequired,
    rows: PropTypes.object.isRequired,
    selection: PropTypes.shape({
      date: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
      isCustom: PropTypes.bool,
      quantity: PropTypes.number,
    }).isRequired,
    error: PropTypes.string,
    isLoaded: PropTypes.bool,
    isRunning: PropTypes.bool,
  }).isRequired,
  config: PropTypes.object.isRequired,
  pickupPlaces: PropTypes.array.isRequired,
  selectedSource: PropTypes.string.isRequired,
  isDigital: PropTypes.bool,
};

export default memo(MatrixWarning);
