import React, { memo } from 'react';

import { Text } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Summary.styles';

const Selection = ({ selection }) => {
  const selections = Object.keys(selection);

  return (
    selections.length > 0 &&
    selections.map((part) => (
      <S.SelectionPart key={part}>
        {selections.length > 1 && (
          <Text weight="bold" color="primary" margin="0 0 0.25rem">
            {part}
          </Text>
        )}

        <S.AttributeList>
          {Object.keys(selection[part]).map((attribute) => (
            <S.AttributeListItem key={attribute}>
              <b>{attribute}:</b> {selection[part][attribute]}
            </S.AttributeListItem>
          ))}
        </S.AttributeList>
      </S.SelectionPart>
    ))
  );
};

Selection.propTypes = {
  selection: PropTypes.object.isRequired,
};

export default memo(Selection);
