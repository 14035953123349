import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, Text, Row, Col, Icon, Modal, theme } from '@printi/printi-components';
import {
  setQtySelection,
  resetQtySelection,
  settingsQtyListFetch,
  checkCustomQtyFetch,
  settingsMatrixReset,
  settingsZipcodeReset,
  settingsQtyListFetchReset,
} from '@store/settings/actions';
import { formatMoney, useWindowSize } from '@utils';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import * as S from './styles';
const CustomQtyModal = dynamic(() => import('../../Modals/CustomQtyModal'));
const FunnelBlock = dynamic(() => import('@components/Funnel/FunnelBlock'));
const BoxCard = dynamic(() => import('../BoxCard'));

const QtyBlock = ({ finalProduct, locale, multipleQty, order, selection, source, matrixQty }) => {
  const dispatch = useDispatch();
  const { breakpoint } = theme;
  const breakpointXL = parseInt(breakpoint.xl, 10) * 16;
  const windowSize = useWindowSize();
  const isMobile = windowSize && windowSize.width < breakpointXL;
  const productSettingsState = useSelector((state) => state.settings);
  const { selectedSource } = source;
  const {
    matrixQtyList: { qtyList, blockedValues, isLoaded, isRunning, isUpdating, errorMessage },
  } = productSettingsState;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMount, setIsMount] = useState(false);
  const isCustomEnabled = finalProduct.custom_qty === '1';
  const handleResetQty = useCallback(async () => {
    await dispatch(resetQtySelection());
  }, [dispatch]);

  const handleCustomSubmit = useCallback(
    async (qty, isCustomQty = false, isValid = true) => {
      if (isCustomQty && !isValid) {
        dispatch(checkCustomQtyFetch(finalProduct.id, qty, selectedSource, selection));
      }
      dispatch(setQtySelection(qty, isCustomQty));
    },
    [dispatch, finalProduct.id, selectedSource, selection],
  );
  const mount = useCallback(() => {
    if (!isMount) {
      dispatch(settingsQtyListFetch(finalProduct.id, selectedSource, selection));
      dispatch(resetQtySelection());
      setIsMount(true);
    }
  }, [dispatch, finalProduct, isMount, selectedSource, selection]);

  useEffect(() => {
    mount();
  }, [mount]);

  useEffect(
    () => () => {
      dispatch(settingsZipcodeReset());
      dispatch(settingsMatrixReset());
    },
    [dispatch],
  );

  const handleConfChange = (qty, isCustomQty = false) => {
    handleResetQty();
    if (isCustomQty) {
      setIsModalOpen(true);
      return;
    }

    if (!qty) return;
    const qtyInfo = qtyList.filter((info) => parseInt(info.value, 10) === parseInt(qty, 10));
    handleCustomSubmit(parseInt(qty, 10), qtyInfo[0].custom);
  };
  const handleSettingsQtyListFetchReset = useCallback(() => {
    dispatch(settingsQtyListFetchReset());
  }, [dispatch]);

  return (
    <>
      {!isLoaded && isRunning && qtyList.length === 0 ? (
        <Loader size="small" wrapper="8rem" />
      ) : (
        <FunnelBlock order={order} title="Escolha a quantidade" isComplete={matrixQty.currentQty > 0}>
          <Row isFull>
            {qtyList &&
              qtyList.map((qty) => (
                <Col xs={12} lg={3} key={`selected-${qty.value}`}>
                  <BoxCard
                    kind="base"
                    name="qty"
                    onChange={() => handleConfChange(qty.value)}
                    value={qty.value}
                    checked={qty.value === matrixQty.currentQty}
                  >
                    <S.InfosRow isFull>
                      <S.ColBlock xs={6} lg={12}>
                        <Text weight="bold" size="lg" margin="0 0.25rem 0 1rem">
                          {qty.label}
                          {` unidade${Number(qty.label) === 1 ? '' : 's'}`}
                        </Text>
                      </S.ColBlock>

                      <S.Separator />

                      <S.ColBlockPrice xs={6} lg={12}>
                        <Text color="primary" weight="bold" size="lg">
                          {formatMoney(qty.price)}
                        </Text>
                        <Text color="gray" weight="normal">
                          {formatMoney(qty.unit_price)} / <abbr title="unidade">un</abbr>
                        </Text>
                      </S.ColBlockPrice>
                    </S.InfosRow>
                  </BoxCard>
                </Col>
              ))}
            {isCustomEnabled && (
              <Col xs={12} lg={3}>
                <S.CustomQtyBox
                  kind="default"
                  type="default"
                  tabIndex="0"
                  onClick={(e) => handleConfChange(e, true)}
                  onKeyUp={(e) => e.code === 'Enter' && handleConfChange(e, true)}
                >
                  <S.CustomQtyButton kind="inverse" isOutlined>
                    <Icon
                      aria-hidden="true"
                      fill="#009BFF"
                      id="activity"
                      name="PlusCircleOutline"
                      size="md"
                      title="Activity"
                    />
                  </S.CustomQtyButton>
                  <Text color="gray" weight="normal" size="xs" margin="0.5rem">
                    Adicionar nova quantidade
                  </Text>
                </S.CustomQtyBox>
              </Col>
            )}
          </Row>
        </FunnelBlock>
      )}
      {isModalOpen && (
        <CustomQtyModal
          onClose={() => setIsModalOpen(false)}
          locale={locale}
          onSubmit={handleCustomSubmit}
          multipleQty={multipleQty}
          blockedValues={blockedValues}
          isUpdating={isUpdating}
        />
      )}

      {errorMessage && (
        <Modal size="medium" infos={{ title: 'Atenção' }} close={handleSettingsQtyListFetchReset}>
          <Text size="sm" align="left">
            {errorMessage.replace(' .', '. ')}
          </Text>
          {isMobile ? (
            <Text size="sm" align="left" weight="bold">
              Consulte o Televendas: <a href="tel: (11)4118-3820">(11) 4118-3820</a>
            </Text>
          ) : (
            <Text size="sm" align="left" weight="bold">
              Consulte o Televendas: (11) 4118-3820
            </Text>
          )}
        </Modal>
      )}
    </>
  );
};

QtyBlock.propTypes = {
  finalProduct: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  multipleQty: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  selection: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  matrixQty: PropTypes.object.isRequired,
};

export default QtyBlock;
