import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@printi/printi-components';
import { settingsOptionsFetch, settingsOptionsView, settingsMatrixReset } from '@store/settings/actions';
import { isSelectionComplete, nUpdateSelection } from '@utils';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import * as S from './Options.styles';
import SelectOption from './SelectOption';
import SelectView from './SelectView';
const FunnelBlock = dynamic(() => import('@components/Funnel/FunnelBlock'));

const Options = ({ source, order, options, selection, finalProduct, isLoading }) => {
  const dispatch = useDispatch();
  const { parts, viewType } = options;
  const [currentViewType, setCurrentViewType] = useState(viewType);
  const { locale } = useSelector((state) => state.locale);

  const t = useMemo(
    () => ({
      ...locale.translate.page.product_settings.options,
      AND: locale.translate.common.AND,
      REMOVE: locale.translate.page.cart.voucher.REMOVE,
      COUNTRY_CODE: locale.COUNTRY_CODE,
    }),
    [locale],
  );

  const handleOptionSelection = useCallback(
    async (partId, key, optionId) => {
      dispatch(settingsMatrixReset());
      dispatch(
        settingsOptionsFetch(nUpdateSelection(selection, partId, key, optionId), partId, finalProduct.id, source),
      );
    },
    [dispatch, finalProduct.id, selection, source],
  );

  const handleViewSelection = useCallback(
    async (type) => {
      setCurrentViewType(type);
      dispatch(settingsOptionsView(type));
    },
    [dispatch],
  );

  return (
    <FunnelBlock
      title={t.TITLE}
      order={order}
      isComplete={Object.keys(selection).length > 0 && isSelectionComplete(selection)}
      infos={t.MORE_INFO_TEXT}
    >
      {isLoading && parts.length === 0 ? (
        <Loader size="medium" wrapper="8rem" />
      ) : (
        <>
          {parts.length !== 1 && (
            <S.PartsLabel>
              {t.TWO_PART_PRODUCT}
              {': '}
              {parts.reduce(
                (prevPart, currentPart) =>
                  prevPart !== '' ? `${prevPart} ${t.AND} ${currentPart.name}` : currentPart.name,
                '',
              )}
            </S.PartsLabel>
          )}

          <SelectView viewType={currentViewType} selectView={handleViewSelection} />
          <SelectOption
            t={t}
            parts={parts}
            selection={selection}
            selectSize={handleOptionSelection}
            disabled={options.isLoadingSettings}
            viewType={currentViewType}
          />
        </>
      )}
    </FunnelBlock>
  );
};

Options.propTypes = {
  source: PropTypes.object.isRequired,
  order: PropTypes.number.isRequired,
  options: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  finalProduct: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(Options);
