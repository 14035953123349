import React, { memo } from 'react';

import { CardProduct, Col, Text } from '@printi/printi-components';
import { formatMoney, cloudFront } from '@utils';
import { PropTypes } from 'prop-types';

import * as S from './CrossSelling.styles';

const CrossSelling = ({ products }) => (
  <Col>
    <Text as="h3" size="xlg" weight="bold" transform="uppercase" margin="0 0 1.5rem">
      Produtos recomendados
    </Text>
    <S.Products>
      {products.map(({ id, title, slug, minimum_price, minimum_quantity, image_small }) => (
        <CardProduct
          key={id}
          href={`/${slug}`}
          title={title}
          label="a partir de"
          price={formatMoney(minimum_price)}
          unity={minimum_quantity || 1000}
        >
          <img
            src={`${cloudFront}${image_small?.filename}`}
            alt={image_small?.alt}
            title={title}
            width={350}
            height={350}
            loading="lazy"
          />
        </CardProduct>
      ))}
    </S.Products>
  </Col>
);

CrossSelling.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      minimum_price: PropTypes.number,
      minimum_quantity: PropTypes.number,
    }),
  ),
};

export default memo(CrossSelling);
