import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { color, font, space } = theme;

export const Message = styled.p`
  font-size: ${font.size.xsmall};
  line-height: ${font.lineHeight.xsmall};
  margin-bottom: ${space.sm};
  font-weight: ${font.weight.normal};

  span {
    color: ${color.grayscale.gray};
  }
`;

export const DisplayTitle = styled.strong`
  display: block;
  min-width: 100%;
  margin-bottom: ${space.sm};
`;

export const PickupCardContainer = styled.div`
  flex-wrap: wrap;
  padding: ${space.xs};
  width: 70%;
`;
