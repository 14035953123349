/* eslint-disable react/no-danger */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

import * as S from './Details.styles';

const Details = ({ content }) => {
  if (!content || content === '') {
    return null;
  }

  return (
    <S.DetailsContainer>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </S.DetailsContainer>
  );
};

Details.propTypes = {
  content: PropTypes.string.isRequired,
};

export default memo(Details);
