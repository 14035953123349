import { Row, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { space } = theme;

export const DetailsRow = styled(Row)`
  margin-bottom: ${space.xl};
`;

export const ContentText = styled.div`
  margin-bottom: ${(p) => (p.noMargin ? 0 : space.md)};

  p {
    margin-bottom: ${space.xs};
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
