import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';

import { Modal, Button, Icon, Text } from '@printi/printi-components';
import { api } from '@utils';
import PropTypes from 'prop-types';

import * as S from './CartZipcodeModal.styles';

const CartZipcodeModal = ({ close, onZipcodeChange, onCartClear, cartZipcode, matrixZipcode }) => {
  const [isClearCart, setIsClearCart] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formatedCartZipCode, setFormatedCartZipCode] = useState(cartZipcode);

  const formatZipCode = (zipCode) => zipCode.replace(/\D/g, '').replace(/^(\d{5})(\d{3})$/, '$1-$2');

  useEffect(() => setFormatedCartZipCode(formatZipCode(cartZipcode)), [cartZipcode]);

  const handleClearCart = useCallback(async () => {
    setIsClearCart(true);
    await api
      .delete('/v2/cart')
      .then(() => {
        setIsClearCart(false);
        onCartClear();
      })
      .catch(() => {
        setShowError(() => true);
      });
    close();
  }, [close, onCartClear]);

  const handleZipcodeChange = useCallback(() => {
    onZipcodeChange();
    close();
  }, [close, onZipcodeChange]);

  const modalInfos = useMemo(
    () => ({
      title: 'Atenção!',
    }),
    [],
  );

  return (
    <Modal
      size="medium"
      close={close}
      showActions={false}
      infos={showError ? {} : modalInfos}
      func={handleClearCart}
      isLoading={isClearCart}
    >
      {showError ? (
        <S.ErrorContent>
          <Icon name="AlertTriangle" size="lg" color="#FF004E" />
          <Text weight="bold" size="xlg" padding="1rem 0 0">
            Atenção
          </Text>
          <Text>Houve algum erro ao remover os items do carrinho.</Text>
        </S.ErrorContent>
      ) : (
        <>
          <S.ContainerText>
            <Text align="center">Todos os produtos do carrinho devem possuir o mesmo CEP de entrega!</Text>
            <Text align="center">
              O CEP dos produtos do carrinho é: <b>{formatedCartZipCode}</b>
            </Text>
            <Text align="center">
              O CEP do produto atual é: <b>{matrixZipcode}</b>
            </Text>
          </S.ContainerText>
          <S.ContainerButton>
            <Button kind="danger" onClick={handleZipcodeChange}>
              Alterar CEP
            </Button>
            <Button kind="success" onClick={handleClearCart}>
              Limpar o carrinho e adicionar este produto
            </Button>
          </S.ContainerButton>
        </>
      )}
    </Modal>
  );
};

CartZipcodeModal.propTypes = {
  close: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  onCartClear: PropTypes.func.isRequired,
  cartZipcode: PropTypes.string.isRequired,
  matrixZipcode: PropTypes.string.isRequired,
};

export default memo(CartZipcodeModal);
