import { Alert } from '@printi/printi-components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  p {
    text-align: center;
    margin-top: 0.75rem;
  }
`;

export const WrapperUl = styled.ul`
  list-style: none;
  padding-left: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 0.5rem;

    > label {
      min-height: 13.5rem;
    }
  }
`;

export const SelectSize = styled.select`
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const AlertStyle = styled(Alert)`
  margin: 0.75rem 0;
  padding: 0.75rem;
  line-height: 1.3;
  font-size: 0.75rem;
  white-space: normal;

  button {
    display: none;
  }
`;

export const DisplayTitle = styled.strong`
  display: block;
  min-width: 100%;
  margin-bottom: 0.75rem;
`;

export const Message = styled.p`
  font-size: 0.75rem;
  line-height: 1.333333333;
  margin-bottom: 0.75rem;
`;

export const PickupCardContainer = styled.div`
  flex-wrap: wrap;
  padding: 0.5rem;
`;
