import React from 'react';

const CreateOnlineTemplate = () => (
  <svg width="245" height="230" viewBox="0 0 245 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="245" height="230" fill="white" />
    <rect width="245" height="230" fill="#DBDBDB" />
    <path
      d="M129.007 217.466C129.007 219.148 103.187 220.516 84.2712 220.516C65.3426 220.516 25 219.299 25 217.617C25 215.935 65.3426 214.416 84.2712 214.416C103.187 214.416 129.007 215.784 129.007 217.466Z"
      fill="#AFAFAF"
    />
    <path d="M182.391 75.7399H44.7947V83.5599H182.391V75.7399Z" fill="white" />
    <path d="M182.391 83.5598H44.7947V167.571H182.391V83.5598Z" fill="#E6F4FF" />
    <path
      d="M69.3466 97.4801C69.3466 101.459 66.1207 104.685 62.1417 104.685C58.1627 104.685 54.9368 101.459 54.9368 97.4801C54.9368 93.501 58.1627 90.2751 62.1417 90.2751C66.1207 90.2877 69.3466 93.501 69.3466 97.4801Z"
      fill="#0050E3"
    />
    <path
      d="M89.9323 97.4801C89.9323 101.459 86.7064 104.685 82.7274 104.685C78.7484 104.685 75.5225 101.459 75.5225 97.4801C75.5225 93.501 78.7484 90.2751 82.7274 90.2751C86.7064 90.2877 89.9323 93.501 89.9323 97.4801Z"
      fill="#4E5EAF"
    />
    <path
      d="M110.505 97.4801C110.505 101.459 107.279 104.685 103.3 104.685C99.3211 104.685 96.0952 101.459 96.0952 97.4801C96.0952 93.501 99.3211 90.2751 103.3 90.2751C107.279 90.2877 110.505 93.501 110.505 97.4801Z"
      fill="#0050E3"
    />
    <path
      d="M131.078 97.4801C131.078 101.459 127.852 104.685 123.873 104.685C119.894 104.685 116.668 101.459 116.668 97.4801C116.668 93.501 119.894 90.2751 123.873 90.2751C127.852 90.2877 131.078 93.501 131.078 97.4801Z"
      fill="#0050E3"
    />
    <path
      d="M151.651 97.4801C151.651 101.459 148.425 104.685 144.446 104.685C140.467 104.685 137.241 101.459 137.241 97.4801C137.241 93.501 140.467 90.2751 144.446 90.2751C148.437 90.2877 151.651 93.501 151.651 97.4801Z"
      fill="#0050E3"
    />
    <path
      d="M172.237 97.4801C172.237 101.459 169.011 104.685 165.032 104.685C161.053 104.685 157.827 101.459 157.827 97.4801C157.827 93.501 161.053 90.2751 165.032 90.2751C169.011 90.2877 172.237 93.501 172.237 97.4801Z"
      fill="#0050E3"
    />
    <path
      d="M69.3466 116.208C69.3466 120.187 66.1207 123.413 62.1417 123.413C58.1627 123.413 54.9368 120.187 54.9368 116.208C54.9368 112.229 58.1627 109.003 62.1417 109.003C66.1207 109.003 69.3466 112.229 69.3466 116.208Z"
      fill="#0050E3"
    />
    <path
      d="M89.9323 116.208C89.9323 120.187 86.7064 123.413 82.7274 123.413C78.7484 123.413 75.5225 120.187 75.5225 116.208C75.5225 112.229 78.7484 109.003 82.7274 109.003C86.7064 109.003 89.9323 112.229 89.9323 116.208Z"
      fill="#4E5EAF"
    />
    <path
      d="M110.505 116.208C110.505 120.187 107.279 123.413 103.3 123.413C99.3211 123.413 96.0952 120.187 96.0952 116.208C96.0952 112.229 99.3211 109.003 103.3 109.003C107.279 109.003 110.505 112.229 110.505 116.208Z"
      fill="#0050E3"
    />
    <path
      d="M131.078 116.208C131.078 120.187 127.852 123.413 123.873 123.413C119.894 123.413 116.668 120.187 116.668 116.208C116.668 112.229 119.894 109.003 123.873 109.003C127.852 109.003 131.078 112.229 131.078 116.208Z"
      fill="white"
    />
    <path
      d="M151.651 116.208C151.651 120.187 148.425 123.413 144.446 123.413C140.467 123.413 137.241 120.187 137.241 116.208C137.241 112.229 140.467 109.003 144.446 109.003C148.437 109.003 151.651 112.229 151.651 116.208Z"
      fill="#0050E3"
    />
    <path
      d="M172.237 116.208C172.237 120.187 169.011 123.413 165.032 123.413C161.053 123.413 157.827 120.187 157.827 116.208C157.827 112.229 161.053 109.003 165.032 109.003C169.011 109.003 172.237 112.229 172.237 116.208Z"
      fill="#0050E3"
    />
    <path
      d="M69.3466 134.936C69.3466 138.915 66.1207 142.141 62.1417 142.141C58.1627 142.141 54.9368 138.915 54.9368 134.936C54.9368 130.957 58.1627 127.731 62.1417 127.731C66.1207 127.731 69.3466 130.957 69.3466 134.936Z"
      fill="#0050E3"
    />
    <path
      d="M89.9323 134.936C89.9323 138.915 86.7064 142.141 82.7274 142.141C78.7484 142.141 75.5225 138.915 75.5225 134.936C75.5225 130.957 78.7484 127.731 82.7274 127.731C86.7064 127.731 89.9323 130.957 89.9323 134.936Z"
      fill="#4E5EAF"
    />
    <path
      d="M110.505 134.936C110.505 138.915 107.279 142.141 103.3 142.141C99.3211 142.141 96.0952 138.915 96.0952 134.936C96.0952 130.957 99.3211 127.731 103.3 127.731C107.279 127.731 110.505 130.957 110.505 134.936Z"
      fill="#0050E3"
    />
    <path
      d="M131.078 134.936C131.078 138.915 127.852 142.141 123.873 142.141C119.894 142.141 116.668 138.915 116.668 134.936C116.668 130.957 119.894 127.731 123.873 127.731C127.852 127.731 131.078 130.957 131.078 134.936Z"
      fill="#0050E3"
    />
    <path
      d="M151.651 134.936C151.651 138.915 148.425 142.141 144.446 142.141C140.467 142.141 137.241 138.915 137.241 134.936C137.241 130.957 140.467 127.731 144.446 127.731C148.437 127.731 151.651 130.957 151.651 134.936Z"
      fill="#0050E3"
    />
    <path
      d="M172.237 134.936C172.237 138.915 169.011 142.141 165.032 142.141C161.053 142.141 157.827 138.915 157.827 134.936C157.827 130.957 161.053 127.731 165.032 127.731C169.011 127.731 172.237 130.957 172.237 134.936Z"
      fill="#0050E3"
    />
    <path
      d="M69.3466 153.663C69.3466 157.642 66.1207 160.868 62.1417 160.868C58.1627 160.868 54.9368 157.642 54.9368 153.663C54.9368 149.684 58.1627 146.458 62.1417 146.458C66.1207 146.458 69.3466 149.684 69.3466 153.663Z"
      fill="#0050E3"
    />
    <path
      d="M89.9323 153.663C89.9323 157.642 86.7064 160.868 82.7274 160.868C78.7484 160.868 75.5225 157.642 75.5225 153.663C75.5225 149.684 78.7484 146.458 82.7274 146.458C86.7064 146.458 89.9323 149.684 89.9323 153.663Z"
      fill="#4E5EAF"
    />
    <path
      d="M110.505 153.663C110.505 157.642 107.279 160.868 103.3 160.868C99.3211 160.868 96.0952 157.642 96.0952 153.663C96.0952 149.684 99.3211 146.458 103.3 146.458C107.279 146.458 110.505 149.684 110.505 153.663Z"
      fill="#0050E3"
    />
    <path
      d="M51.2717 79.6561C51.2717 80.6226 50.4809 81.4134 49.5144 81.4134C48.5479 81.4134 47.7571 80.6226 47.7571 79.6561C47.7571 78.6896 48.5479 77.8988 49.5144 77.8988C50.4809 77.8988 51.2717 78.6896 51.2717 79.6561Z"
      fill="#8000FF"
    />
    <path
      d="M57.3344 79.6561C57.3344 80.6226 56.5436 81.4134 55.5771 81.4134C54.6106 81.4134 53.8198 80.6226 53.8198 79.6561C53.8198 78.6896 54.6106 77.8988 55.5771 77.8988C56.5436 77.8988 57.3344 78.6896 57.3344 79.6561Z"
      fill="#9933FF"
    />
    <path
      d="M63.4094 79.6561C63.4094 80.6226 62.6186 81.4134 61.6521 81.4134C60.6856 81.4134 59.8948 80.6226 59.8948 79.6561C59.8948 78.6896 60.6856 77.8988 61.6521 77.8988C62.6312 77.8988 63.4094 78.6896 63.4094 79.6561Z"
      fill="#B366FF"
    />
    <path
      d="M181.224 55.5559C181.224 62.2587 175.789 67.6938 169.086 67.6938H104.493C97.7898 67.6938 92.3547 62.2587 92.3547 55.5559V48.7527C92.3547 42.0498 97.7898 36.6147 104.493 36.6147H169.086C175.789 36.6147 181.224 42.0498 181.224 48.7527V55.5559V55.5559Z"
      fill="#33ADFF"
    />
    <path d="M172.701 43.3804H100.853V45.69H172.701V43.3804Z" fill="white" />
    <path d="M172.701 48.5896H100.853V50.8992H172.701V48.5896Z" fill="white" />
    <path d="M172.701 53.8113H100.853V56.1209H172.701V53.8113Z" fill="white" />
    <path d="M114.434 59.033H100.865V61.3426H114.434V59.033Z" fill="white" />
    <path
      d="M122.731 23.8242C122.731 20.6108 120.12 18 116.907 18H98.1665C94.9531 18 92.3423 20.6108 92.3423 23.8242V27.0877C92.3423 30.3011 94.9531 32.9119 98.1665 32.9119H116.907C120.12 32.9119 122.731 30.3011 122.731 27.0877V23.8242V23.8242Z"
      fill="#FF7800"
    />
    <path
      d="M103.853 25.456C103.853 26.0836 103.338 26.5982 102.71 26.5982C102.083 26.5982 101.568 26.0836 101.568 25.456C101.568 24.8284 102.083 24.3137 102.71 24.3137C103.338 24.3137 103.853 24.8158 103.853 25.456Z"
      fill="white"
    />
    <path
      d="M108.66 25.456C108.66 26.0836 108.145 26.5982 107.518 26.5982C106.89 26.5982 106.375 26.0836 106.375 25.456C106.375 24.8284 106.89 24.3137 107.518 24.3137C108.145 24.3137 108.66 24.8158 108.66 25.456Z"
      fill="white"
    />
    <path
      d="M113.467 25.456C113.467 26.0836 112.953 26.5982 112.325 26.5982C111.697 26.5982 111.183 26.0836 111.183 25.456C111.183 24.8284 111.697 24.3137 112.325 24.3137C112.965 24.3137 113.467 24.8158 113.467 25.456Z"
      fill="white"
    />
    <path
      d="M213.734 190.027C213.734 196.73 208.299 202.165 201.596 202.165H137.003C130.3 202.165 124.865 196.73 124.865 190.027V174.726C124.865 168.023 130.3 162.588 137.003 162.588H201.596C208.299 162.588 213.734 168.023 213.734 174.726V190.027V190.027Z"
      fill="#33ADFF"
    />
    <path d="M204.571 171.45H132.722V173.759H204.571V171.45Z" fill="white" />
    <path d="M204.571 176.672H132.722V178.981H204.571V176.672Z" fill="white" />
    <path d="M204.571 181.893H132.722V184.203H204.571V181.893Z" fill="white" />
    <path d="M204.571 187.102H132.722V189.412H204.571V187.102Z" fill="white" />
    <path d="M173.002 192.324H132.722V194.634H173.002V192.324Z" fill="white" />
    <path
      d="M93.359 96.1998C93.359 101.898 88.7398 106.53 83.0411 106.53C77.3424 106.53 72.7107 101.911 72.7107 96.1998C72.7107 90.5011 77.3299 85.8694 83.0411 85.8694C88.7398 85.8694 93.359 90.4886 93.359 96.1998Z"
      fill="#9933FF"
    />
    <path
      d="M88.0616 95.4717C88.0491 93.9404 89.4047 93.4257 90.8608 94.4173C91.8775 95.1077 92.6683 96.2625 92.9695 98.07C93.4967 101.158 94.1243 106.53 92.1787 107.208C91.3126 107.509 88.0114 107.208 88.0114 107.208L88.9528 112.179L82.6517 110.886L83.6433 105.614C83.6433 105.626 79.3881 97.6558 88.0616 95.4717Z"
      fill="#E2857E"
    />
    <path
      d="M91.8401 103.216C90.3589 102.915 89.5682 104.748 88.7899 104.497C88.3255 104.359 87.9364 102.689 87.7983 101.02C87.7983 100.957 87.7858 100.894 87.7858 100.832L86.6937 102.162V102.175C86.6059 103.58 86.3046 105.2 86.5682 106.103C87.0075 107.635 92.3924 107.848 92.8317 107.848C93.271 107.848 93.3212 106.48 93.4719 105.288C93.6225 104.032 93.3212 103.505 91.8401 103.216Z"
      fill="#2A3756"
    />
    <path
      d="M83.6437 105.626C83.6437 105.626 90.9616 96.2249 92.6813 94.3421C93.5976 93.3379 91.9658 92.6978 91.9658 92.6978C90.7984 88.3171 82.0873 92.6978 80.5936 96.1245C78.9994 99.7897 82.9785 105.714 83.6437 105.626Z"
      fill="#9933FF"
    />
    <path
      d="M82.7273 144.977C82.7273 144.977 89.1791 178.102 89.4427 180.688C89.7188 183.261 88.9029 195.876 91.8778 207.575L97.5514 207.763C97.7648 200.031 98.1288 185.533 98.179 182.395C98.2543 177.927 98.3924 152.609 98.3924 152.609C98.3924 152.609 85.2754 138.789 82.7273 144.977Z"
      fill="#606060"
    />
    <path d="M106.225 216.801L91.6643 217.253V211.215H97.363L106.35 215.156L106.225 216.801Z" fill="white" />
    <path d="M92.5053 211.215H96.7479V207.763L92.3923 207.587L92.5053 211.215Z" fill="#E2857E" />
    <path d="M80.5307 213.474L76.4387 212.357L76.5266 207.123H80.8571L80.5307 213.474Z" fill="#E2857E" />
    <path
      d="M73.3381 152.345C73.3381 152.345 74.7942 167.86 75.3214 182.182C74.38 194.157 75.2586 205.855 75.4092 207.474L81.4719 207.487C81.5221 204.349 82.1999 193.014 83.5305 182.797C85.476 167.797 88.3128 151.304 88.3128 151.304C88.3128 151.304 75.836 146.12 73.3381 152.345Z"
      fill="#606060"
    />
    <path d="M81.4845 217.617H74.3926V215.947L76.3256 211.215H80.6435L81.4845 215.947V217.617Z" fill="white" />
    <path
      d="M82.8904 108.89C80.1038 109.166 75.1332 111.865 74.3424 112.656C72.9742 122.107 72.7231 158.358 72.7231 158.358L99.2584 158.559C99.2584 158.559 99.9111 126.4 96.0074 112.53C94.7396 110.409 89.8694 109.38 88.4133 108.965C88.2878 108.928 82.9657 108.877 82.8904 108.89Z"
      fill="#FFC800"
    />
    <path
      d="M75.5224 111.827C68.7317 115.819 64.5769 129.877 65.8572 137.358C67.0873 144.551 78.8236 149.283 78.8236 149.283C78.8236 149.283 78.8236 117.174 75.5224 111.827Z"
      fill="#FFC800"
    />
    <path d="M119.919 122.898L121.639 119.848L119.781 118.467L117.534 120.664L119.919 122.898Z" fill="#E2857E" />
    <path
      d="M120.798 115.392C119.229 117.124 118.84 119.321 119.907 120.3C120.221 120.589 120.622 120.727 121.074 120.752C121.526 120.777 122.631 119.258 123.045 118.568C123.258 118.204 123.547 117.852 123.873 117.513C124.589 116.785 125.417 116.333 126.12 116.195C126.283 115.304 126.108 114.513 125.568 114.024C124.513 113.045 122.367 113.66 120.798 115.392Z"
      fill="#E2857E"
    />
    <path
      d="M95.9948 112.53C101.254 118.467 102.861 130.128 107.756 128.459C112.375 126.89 117.534 120.664 117.534 120.664L120.823 123.739C120.823 123.739 112.149 141.488 101.681 138.425C91.2124 135.362 89.907 129.375 89.907 129.375C89.907 129.375 91.5137 112.58 95.9948 112.53Z"
      fill="#FFC800"
    />
    <path
      d="M195.107 193.78C196.738 191.809 199.073 191.169 201.031 190.14C202.939 189.136 204.822 188.081 206.617 187.755C208.337 187.454 209.943 188.383 211.374 188.458C214.387 188.621 215.642 187.868 216.935 188.345C218.454 188.897 219.621 189.826 219.596 191.433C219.571 193.039 218.24 194.27 216.608 194.182C216.571 194.182 215.002 193.579 212.629 194.295C212.278 193.843 211.726 193.567 211.111 193.567C210.056 193.567 209.19 194.408 209.165 195.462C208.148 195.713 207.094 195.889 206.24 195.763C203.805 195.437 202.236 197.069 199.914 199.78C198.734 201.173 196.512 200.91 195.144 199.843C194.906 199.654 194.692 199.454 194.491 199.203C193.161 197.558 192.948 196.391 195.107 193.78Z"
      fill="#05B884"
    />
    <path
      d="M184.023 180.362C186.044 175.354 189.91 169.253 190.424 166.818C190.776 165.211 189.257 156.952 189.094 153.914C190.236 153.588 191.077 152.546 191.077 151.291C191.077 150.212 190.449 149.283 189.546 148.843C190.085 145.768 191.265 142.266 193.901 141.601C199.537 140.17 198.081 154.341 199.161 158.295C201.684 167.559 198.357 174.475 196.512 177.462C194.265 181.09 192.295 183.5 192.495 186.437C192.822 191.27 193.111 195.788 192.696 197.106C192.044 199.14 189.985 200.332 187.939 199.956C187.763 199.918 187.6 199.855 187.424 199.83C181.738 198.788 180.935 187.981 184.023 180.362Z"
      fill="#048560"
    />
    <path
      d="M190.123 209.721L190.45 209.633C190.286 209.081 189.91 206.345 189.571 203.947C189.307 202.039 189.069 200.395 188.981 199.981C186.345 188.847 188.83 182.872 192.27 174.613C192.496 174.073 192.721 173.521 192.96 172.956C194.127 170.144 195.42 165.211 195.044 157.705C194.818 153.224 194.077 149.898 194.077 149.86L193.751 149.935C193.763 149.973 194.491 153.274 194.717 157.73C195.094 165.161 193.801 170.044 192.659 172.831C192.42 173.395 192.194 173.948 191.968 174.487C188.504 182.809 186.006 188.809 188.667 200.056C188.755 200.445 189.006 202.165 189.257 203.985C189.571 206.407 189.96 209.144 190.123 209.721Z"
      fill="#346009"
    />
    <path d="M188.316 184.956L188.592 184.768L184.6 179.006L184.437 179.37L188.316 184.956Z" fill="#346009" />
    <path d="M187.964 186.788L188.215 186.563L183.647 181.353L183.521 181.717L187.964 186.788Z" fill="#346009" />
    <path d="M194.88 163.956L199.813 161.471L199.763 161.119L194.73 163.655L194.88 163.956Z" fill="#346009" />
    <path d="M194.68 166.027L199.952 162.638L199.914 162.262L194.491 165.751L194.68 166.027Z" fill="#346009" />
    <path
      d="M175.312 190.203C175.136 189.964 174.383 189.45 173.768 189.048C172.023 187.881 169.626 186.286 167.504 183.048C166.676 181.768 166.287 180.299 165.885 178.743C165.333 176.646 166.086 174.525 164.316 172.58C162.76 170.872 161.517 167.96 163.224 166.404C164.931 164.847 167.592 164.973 169.149 166.68C172.312 170.157 173.053 178.391 178.425 182.069C185.755 187.09 186.019 194.947 186.107 195.55C186.107 195.55 186.96 201.638 181.877 201.977C176.693 202.328 176.843 192.374 175.312 190.203Z"
      fill="#05B884"
    />
    <path
      d="M183.935 210.587L184.274 210.575V210.525C184.236 208.642 184.111 202.918 183.935 201.474C183.345 196.705 182.027 192.826 179.906 189.638C178.111 186.939 176.102 185.295 174.496 183.964C173.454 183.111 172.638 182.433 172.174 181.73C171.27 180.337 170.73 178.504 170.153 176.546C169.4 173.998 168.634 171.349 166.939 169.617L166.701 169.856C168.333 171.525 169.098 174.123 169.826 176.646C170.404 178.617 170.956 180.487 171.885 181.918C172.374 182.671 173.215 183.362 174.27 184.228C177.458 186.851 182.278 190.818 183.596 201.512C183.709 202.454 183.822 205.491 183.935 210.537V210.587Z"
      fill="#7DC93A"
    />
    <path d="M170.592 178.818L170.63 178.492L165.76 178.077L165.81 178.416L170.592 178.818Z" fill="#7DC93A" />
    <path d="M171.132 180.324L171.182 179.998L165.998 179.182L166.098 179.533L171.132 180.324Z" fill="#7DC93A" />
    <path d="M179.542 189.274L181.739 184.994L181.5 184.73L179.241 189.123L179.542 189.274Z" fill="#7DC93A" />
    <path
      d="M192.935 205.993C193.173 204.575 194.617 200.872 195.207 199.818C196.5 197.521 198.42 195.65 200.943 194.232C202.964 193.102 205.374 192.261 208.085 191.734C212.73 190.843 216.734 191.194 216.771 191.194L216.797 190.855C216.759 190.855 212.717 190.504 208.023 191.395C205.261 191.922 202.826 192.776 200.767 193.931C198.194 195.374 196.211 197.295 194.893 199.642C194.278 200.734 192.835 204.449 192.583 205.918L192.935 205.993Z"
      fill="#346009"
    />
    <path d="M206.83 191.935L208.324 187.78L207.985 187.73L206.516 191.822L206.83 191.935Z" fill="#346009" />
    <path d="M205.286 192.374L207.521 187.692H207.144L204.985 192.224L205.286 192.374Z" fill="#346009" />
    <path d="M201.684 197.822L197.667 196.316L197.554 196.629L201.42 198.085L201.684 197.822Z" fill="#346009" />
    <path
      d="M199.6 210.751C199.6 213.399 198.345 215.784 196.299 217.529H180.295C178.262 215.796 176.994 213.399 176.994 210.751C176.994 208.679 177.76 206.772 179.065 205.203H197.504C198.835 206.772 199.6 208.679 199.6 210.751Z"
      fill="#FF7800"
    />
    <path
      d="M25.7656 152.709C25.7656 152.709 35.6567 153.914 42.1964 162.739C48.736 171.563 47.8825 175.68 47.8825 175.68C47.8825 175.68 45.7612 173.533 41.8449 173.358C39.0081 173.232 40.0876 169.642 39.0332 168.688C37.9789 167.734 35.6442 167.923 34.7404 166.391C33.8366 164.86 34.8408 164.985 33.4475 163.128C32.2174 161.483 30.5229 162.877 29.6191 160.316C29.1296 158.923 28.7279 155.094 25.7656 152.709Z"
      fill="#048560"
    />
    <path
      d="M63.7485 158.509C63.7485 158.509 57.2088 159.299 52.8909 165.136C48.573 170.96 48.5479 174.5 48.5479 174.5C48.5479 174.5 51.0959 172.466 53.1168 172.153C54.9745 171.864 54.2842 169.692 54.9745 169.065C55.6649 168.437 57.2088 168.563 57.8113 167.546C58.4013 166.529 57.7486 166.617 58.6649 165.387C59.4682 164.308 60.5979 165.224 61.2004 163.529C61.5142 162.613 61.7904 160.078 63.7485 158.509Z"
      fill="#05B884"
    />
    <path
      d="M47.895 174.487C47.895 174.487 52.7904 150.651 35.5563 138.576C35.5563 138.576 35.6442 144.538 35.4684 146.383C35.3053 148.065 36.209 150.425 37.1379 151.392C38.0542 152.371 39.4475 153.776 39.6859 154.881C39.9119 155.986 38.5563 158.295 39.0834 159.827C39.5981 161.358 40.9914 161.747 41.5186 163.078C42.0457 164.421 41.6064 166.391 42.5855 167.433C43.552 168.475 45.3218 169.065 46.1126 170.37C46.8783 171.701 47.895 174.487 47.895 174.487Z"
      fill="#05B884"
    />
    <path d="M56.8949 173.872H38.1545V177.148H56.8949V173.872Z" fill="#005D99" />
    <path
      d="M55.2633 180.676C55.2633 184.943 51.7989 188.408 47.5312 188.408C43.2635 188.408 39.7991 184.943 39.7991 180.676V175.517H55.2633V180.676Z"
      fill="#005D99"
    />
    <path d="M55.2504 182.483H39.7861V183.073H55.2504V182.483Z" fill="white" />
    <path d="M31.7155 217.064H31.1506L40.0501 182.709L40.6275 182.86L31.7155 217.064Z" fill="white" />
    <path d="M63.2464 217.064L54.3469 182.86L54.9243 182.709L63.8238 217.064H63.2464Z" fill="white" />
    <path d="M52.8531 217.064L49.4138 182.809L50.0038 182.747L53.4431 217.064H52.8531Z" fill="white" />
    <path d="M59.0792 199.592H35.8828V200.182H59.0792V199.592Z" fill="white" />
    <g opacity="0.3">
      <g opacity="0.3">
        <path opacity="0.3" d="M39.7861 177.161H46.1752L39.7861 179.194V177.161Z" fill="#2A3756" />
      </g>
    </g>
    <path d="M46.0751 152.308L35.9831 149.383L35.8325 148.969L45.9119 151.894L46.0751 152.308Z" fill="#7DC93A" />
    <path d="M46.6523 153.965L38.7193 153.174L38.4055 152.785L46.5267 153.601L46.6523 153.965Z" fill="#7DC93A" />
    <path d="M48.5226 165.45L41.7194 163.919L41.644 163.529L48.5101 165.086L48.5226 165.45Z" fill="#7DC93A" />
    <path d="M48.5352 166.63L41.9705 166.103L41.9077 165.739L48.5352 166.278V166.63Z" fill="#7DC93A" />
    <path d="M40.5145 142.982L35.5815 141.639V141.275L40.0626 142.492L40.5145 142.982Z" fill="#7DC93A" />
  </svg>
);

export default CreateOnlineTemplate;
