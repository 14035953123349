import React, { memo } from 'react';

import ImageFallback from '@components/ImageFallback';
import { Modal } from '@printi/printi-components';
import PropTypes from 'prop-types';

const ImageZoomModal = ({ onClose, data }) => (
  <Modal size="medium" close={onClose} hideCancelButton>
    <ImageFallback alt={data.text} src={data.link} width={480} height={480} />
  </Modal>
);

ImageZoomModal.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
};

export default memo(ImageZoomModal);
