import React, { memo, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, Text } from '@printi/printi-components';
import PropTypes from 'prop-types';

import Calculator from './Calculator';
import PrePressTemplate from './PrePressTemplate';
import Selection from './Selection';
import filteredSettingsSelector from './selectors';
import * as S from './Summary.styles';
import SummaryPrice from './SummaryPrice';

const Summary = ({
  matrix,
  options,
  handleCartAdd,
  handleArtModal,
  productTitle,
  selectionIsComplete,
  productSettingsState,
  selectedSource,
  loading,
  isArtCreationAutoSelected,
}) => {
  const [isActive, setIsActive] = useState(false);
  const { finalProduct, selection: productSelection } = productSettingsState;
  const { locale } = useSelector((state) => state.locale);

  const t = useMemo(
    () => ({
      ...locale,
      ...locale.translate.page.product_settings.prepress_template,
    }),
    [locale],
  );

  const selection = filteredSettingsSelector(productSettingsState);

  const handleToggle = useCallback(() => {
    setIsActive((oldState) => !oldState);
  }, []);

  return (
    <S.StickyBoxContainer offsetTop={20} offsetBottom={20}>
      <S.Container isActive={isActive}>
        <S.ButtonStickMobile onClick={handleToggle} aria-label="menu do resumo">
          {isActive ? <Icon fill="#000" name="Close" /> : <Icon fill="#000" name="List" />}
        </S.ButtonStickMobile>

        <S.WrapperSummary>
          <S.BoxSummary>
            <S.Summary>
              <Text size="xlg" weight="bold" margin="0 0 1rem">
                {t.translate.page.product_settings.sidebar.TITLE}
              </Text>
              <Selection selection={selection} />
              <Calculator options={options} productSettings={productSettingsState} />
            </S.Summary>
            <SummaryPrice matrix={matrix} t={t} selectedSource={selectedSource} />
          </S.BoxSummary>

          {selectionIsComplete && (
            <PrePressTemplate
              t={t}
              selection={productSelection}
              productTitle={productTitle}
              productId={finalProduct.id}
            />
          )}
        </S.WrapperSummary>

        <S.SummaryButton>
          {isArtCreationAutoSelected ? (
            <Button isBlock kind="success" onClick={handleArtModal} data-optimum="product-settings-advance-button">
              {t.translate.page.product_settings.ADD_TO_CART}
            </Button>
          ) : (
            <Button
              isBlock
              kind="success"
              isDisabled={matrix.selection.date <= 0 || matrix.selection.quantity <= 0}
              isLoading={loading}
              onClick={handleCartAdd}
              data-optimum="product-settings-advance-button"
            >
              {locale.translate.page.product_settings.ADD_TO_CART}
            </Button>
          )}
        </S.SummaryButton>
      </S.Container>
    </S.StickyBoxContainer>
  );
};

Summary.propTypes = {
  handleArtModal: PropTypes.func.isRequired,
  handleCartAdd: PropTypes.func.isRequired,
  isArtCreationAutoSelected: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  matrix: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  productSettingsState: PropTypes.object.isRequired,
  productTitle: PropTypes.string.isRequired,
  selectedSource: PropTypes.string.isRequired,
  selectionIsComplete: PropTypes.bool.isRequired,
};
export default memo(Summary);
