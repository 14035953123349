import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, borders, color, font, space } = theme;

export const OptionContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 calc(50% - 0.5rem);
  margin-bottom: ${space.md};

  &:nth-of-type(odd) {
    margin-right: ${space.md};
  }

  @media (min-width: ${breakpoint.sm}) {
    flex: 0 0 calc(33.33% - 0.67rem);

    &:nth-of-type(odd) {
      margin-right: 0;
    }

    &:not(:nth-of-type(3n)) {
      margin-right: ${space.md};
    }
  }

  @media (min-width: ${breakpoint.md}) {
    flex: 0 0 calc(25% - 0.75rem);

    &:not(:nth-of-type(3n)) {
      margin-right: 0;
    }

    &:not(:nth-of-type(4n)) {
      margin-right: ${space.md};
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    flex: 0 0 calc(20% - 0.8rem);

    &:not(:nth-of-type(4n)) {
      margin-right: 0;
    }

    &:not(:nth-of-type(5n)) {
      margin-right: ${space.md};
    }
  }
`;

export const ZoomButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  width: ${space.xl2};
  height: ${space.xl2};
  padding: 0;
  border: none;
  opacity: 0.85;
  border-bottom-left-radius: 100%;
  background-color: ${color.grayscale.white};
  cursor: zoom-in;
  outline: none;
  z-index: 1;

  svg {
    position: absolute;
    right: ${space.xs2};
    top: ${space.xs2};
    height: ${space.lg};
    width: ${space.lg};
    fill: ${color.primary.pure};
  }

  &:focus {
    opacity: 1;
    border: ${borders.sizes.md} ${color.grayscale.black} solid;
  }

  &:hover {
    opacity: 1;
  }
`;

export const Label = styled.label`
  width: 100%;
  position: relative;
  border: ${borders.sizes.md} solid ${(p) => (p.checked ? color.primary.pure : color.grayscale.lighter)};
  border-radius: ${borders.radius.md};
  background-color: ${(p) => (p.checked ? color.primary.pure : color.grayscale.white)};
  color: ${color.grayscale.black};
  background-image: ${(props) => `url(${props.background})`} center center no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: all 0.3s ease;

  ${(p) =>
    p.disabled
      ? `
    opacity: 0.4;
  `
      : `
    &:hover {
      border: ${borders.sizes.md} solid ${color.primary.pure};
      background-color: ${color.primary.pure};
      color: ${color.grayscale.black};
    }
  `}

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${space.xl3};
  padding: 0 ${space.xs2};
  font-size: ${font.size.small};
  line-height: ${font.lineHeight.small};
  text-align: center;
`;

export const Input = styled.input`
  display: none;
`;

export const ExtraInfo = styled.div`
  font-size: ${font.size.xsmall};
  text-align: center;

  .description {
    padding: ${space.xs} 0 ${space.xs} 0;
  }

  .price-title {
    color: ${color.success.dark};
  }
`;
