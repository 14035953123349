import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { borders, color, font, space } = theme;

export const MoreInfosContent = styled.div`
  position: absolute;
  z-index: 10;
  right: -${space.lg};
  top: ${space.xl};
  display: none;
  width: 18rem;
  padding: ${space.md};
  border-radius: ${borders.radius.md};
  background-color: ${color.primary.pale};
  color: ${color.grayscale.black};
  box-shadow: 5px 5px 1rem -0.5rem rgb(0 0 0 / 30%);
  white-space: break-spaces;
  text-align: left;

  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 1.75rem;
    width: 0;
    height: 0;
    border: solid transparent;
    bottom: 100%;
    pointer-events: none;
  }

  &:after {
    border-bottom-color: ${color.primary.pale};
    border-width: ${space.xs};
    margin-left: -${space.xs};
  }
`;

export const MoreInfos = styled.div`
  position: relative;
  color: ${color.primary.dark};
  font-size: ${font.size.xsmall};
  text-align: right;
  white-space: nowrap;
  cursor: help;

  &:hover ${MoreInfosContent} {
    display: block;
  }

  &:focus ${MoreInfosContent} {
    display: block;
  }
`;
