import { theme, Checkbox, Box } from '@printi/printi-components';
import styled, { css } from 'styled-components';

const { space, color, font, breakpoint } = theme;

export const BoxBlock = styled(Box)`
  display: ${(p) => (p.hidden ? 'none' : 'flex')};
  padding: ${space.md} ${space.xs2};
  margin-bottom: ${space.md};
  width: 100%;
  cursor: pointer;
  min-height: auto;
  background-color: ${({ checked }) => checked && color.primary.pale};

  @media (min-width: ${breakpoint.lg}) {
    min-height: 8rem;
  }

  @media (max-width: 834px) {
    &:has(#is-free-shiping-card) {
      padding-top: 2rem;
    }
  

  @media (max-width: 767px) {
    &:has(#is-free-shiping-card) {
      padding-top: 1rem;
    }
  }

  ${(p) =>
    p.checked &&
    css`
      border-color: ${color.primary.pure};
      background-color: ${color.primary.pale};
    `}
`;

export const Express = styled.span`
  min-height: ${space.lg};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  font-weight: ${font.weight.bold};
  background-color: ${(p) => (p.isExpress ? color.primary.pure : color.grayscale.white)};
  height: 100%;
  z-index: -10;

  ${(p) =>
    p.checked &&
    !p.isExpress &&
    css`
      background-color: ${color.primary.pale};
    `}
`;

export const FreeShipping = styled(Express)`
  background-color: ${color.success.lighter};
  min-height: ${space.lg};
  height: inherit;
  z-index: inherit;
`;

export const ExpressShipping = styled(Express)`
  background-color: ${color.primary.pure};
  min-height: ${space.lg};
  height: inherit;
  z-index: inherit;
`;

export const ChosenCardContainer = styled.label`
  display: ${(p) => (p.hidden ? 'none' : 'inline-flex')};
  width: 100%;

  @media (min-width: ${breakpoint.lg}) {
    display: inline-flex;
  }

  ${(p) =>
    p.checked &&
    css`
      border-color: ${color.grayscale.white} !important;
    `}

  .funnel-ccard-input {
    appearance: none;
  }
`;

export const CheckboxCustom = styled(Checkbox)`
  width: auto;
  display: block;
  margin: auto;
  opacity: initial;
  position: initial;

  @media (min-width: ${breakpoint.lg}) {
    opacity: 0;
    position: absolute;
  }

  span {
    background-color: ${color.grayscale.white};
  }

  i {
    background-color: ${color.primary.pure};
    width: ${space.md};
    height: ${space.md};
  }

  ${(p) =>
    p.checked &&
    css`
      border-color: ${color.grayscale.white} !important;
    `}
`;
