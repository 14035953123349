import { theme } from '@printi/printi-components';
import styled from 'styled-components';

const { color } = theme;

export const CarouselArrowContainer = styled.button`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 3.5rem;
  height: 100%;
  border: none;
  outline-color: ${color.primary.dark};
  background-color: transparent;
  color: ${color.grayscale.gray};
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    color: ${color.primary.pure};
  }

  svg {
    width: 1.25rem;
  }

  ${(p) =>
    p.rotate
      ? `
      right: -3.5rem;
    `
      : `
      left: -3.5rem;

      > svg {
        transform: rotate(180deg);
      }
    `}
`;
