import React, { memo, useState, useCallback } from 'react';

import { Col, Button, CardProduct } from '@printi/printi-components';
import { formatMoney, cloudFront } from '@utils';
import PropTypes from 'prop-types';

import * as S from './ProductsCategories.styles';
const ProductsCategories = ({ categories }) => {
  const [groupType, setGroupType] = useState(categories[0].title);
  const [products, setProducts] = useState(categories[0].pages[0].products);

  const handleShowProducts = useCallback((title, productsPage) => {
    setGroupType(title);
    setProducts(productsPage);
  }, []);

  return (
    <S.ProductsRow isFull>
      <S.ProductsCol xs={12}>
        <S.ButtonsGroup isPill>
          {categories &&
            categories.map(({ id, title, pages }) => (
              <Button
                key={id}
                isOutlined={groupType !== title}
                onClick={() => handleShowProducts(title, pages[0].products)}
              >
                {title}
              </Button>
            ))}
        </S.ButtonsGroup>
      </S.ProductsCol>

      <Col xs={12}>
        {products &&
          products.map(({ id, slug, image_small, title, minimum_price, minimum_quantity }) => (
            <S.Product key={id}>
              <CardProduct
                href={`/${slug}`}
                title={title}
                label="a partir de"
                price={formatMoney(minimum_price)}
                unity={minimum_quantity}
              >
                <img
                  src={`${cloudFront}${image_small.file}`}
                  alt={image_small.alt}
                  title={title}
                  width="335"
                  height="335"
                />
              </CardProduct>
            </S.Product>
          ))}
      </Col>
    </S.ProductsRow>
  );
};

ProductsCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      pages: PropTypes.arrayOf(PropTypes.any).isRequired,
    }),
  ).isRequired,
};

export default memo(ProductsCategories);
