import { theme, Box } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, color, space, borders, font } = theme;

export const AdvantagesBox = styled(Box)`
  padding: ${space.lg};
  box-shadow: ${space.xs2} ${space.xs2} ${space.md} ${color.grayscale.light};
  margin-bottom: ${space.xl};

  @media (min-width: ${breakpoint.xl}) {
    padding: ${space.xl2};
  }
`;

export const AdvantagesTitle = styled.h3`
  margin: 0 0 ${space.md};
  padding: 0;
  color: ${color.grayscale.black};
  font-family: ${font.family.heading};
  text-align: center;
  font-weight: ${font.weight.semibold};
  line-height: ${font.lineHeight.h3};
  font-size: ${font.size.h5};

  @media (min-width: ${breakpoint.xl}) {
    font-size: ${font.size.h3};
  }
`;

export const AdvantagesList = styled.ul`
  color: ${color.grayscale.black};
  display: flex;
  font-family: ${font.family.text};
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  margin: 0;

  li {
    align-items: center;
    border: ${borders.sizes.sm} solid ${color.grayscale.light};
    display: flex;
    justify-content: center;
    padding: ${space.lg} 0;
    margin-bottom: ${space.md};
    width: 100%;
    font-size: 1.1rem;

    @media (min-width: ${breakpoint.xl}) {
      width: calc((100% / 3) - 1.4rem);
      margin: 0 ${space.xl} ${space.xl} 0;
      font-size: 1.3rem;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
`;
