/* eslint-disable react/no-danger */
import React, { memo, useState } from 'react';

import { Col, Text, Modal } from '@printi/printi-components';
import { cloudFront } from '@utils';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import * as S from './Tutorial.styles';
const Image = dynamic(() => import('@components/Image'));

const Tutorial = ({ videoInfo, blogInfo }) => {
  const [modalVideoIsOpen, setModalVideoIsOpen] = useState(false);

  return (
    <>
      <S.TutorialRow>
        <Col xs={12} md={8}>
          <Text as="h3" size="lg" weight="bold" transform="uppercase" margin="1rem 0">
            Tutoriais
          </Text>
          <S.WrapperVideo onClick={() => setModalVideoIsOpen(true)}>
            <Image src={`${cloudFront}${videoInfo.image.file}`} alt={videoInfo.image.title} width="740" height="420" />
            <S.TitleVideo size="xlg" weight="bold" color="inverse" align="center">
              {videoInfo.video.title}
            </S.TitleVideo>
          </S.WrapperVideo>
        </Col>
        <S.WrapperPosts xs={12} md={4}>
          <Text weight="bold" transform="uppercase" margin="0 0 1rem">
            Artigos
          </Text>
          {blogInfo &&
            blogInfo.map((post) => (
              <S.LinkPost key={post.id} href={post.external_link} rel="noopener" target="_blank">
                <Image src={cloudFront + post.image.file} alt={post.image.title} width="740" height="420" />
                <Text margin="0 0 1rem">{post.name}</Text>
              </S.LinkPost>
            ))}
        </S.WrapperPosts>
      </S.TutorialRow>

      {modalVideoIsOpen && (
        <Modal size="large" close={() => setModalVideoIsOpen(false)}>
          <S.ModalVideo dangerouslySetInnerHTML={{ __html: videoInfo.video.html }} />
        </Modal>
      )}
    </>
  );
};

Tutorial.propTypes = {
  videoInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
      file: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    video: PropTypes.shape({
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  blogInfo: PropTypes.arrayOf(
    PropTypes.shape({
      external_link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.shape({
        file: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export default memo(Tutorial);
