import { theme, Text, Tag } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, borders, color, font, space } = theme;

export const WrapSourceOption = styled.div`
  width: 100%;

  @media (min-width: ${breakpoint.lg}) {
    width: 33.33%;
    padding: ${space.xs};
  }
  svg {
    fill: none !important;
  }
`;

export const TagBadge = styled(Tag)`
  margin: auto 0.2rem auto ${space.lg};
  font-size: ${font.size.xsmall};
`;
export const TagBadgeMobile = styled(Tag)`
  display: none;
  @media (max-width: ${breakpoint.md}) {
    margin: auto 0.5rem;
    font-size: ${font.size.xsmall};
    display: block;
  }
`;
export const TagBagSvg = styled.div`
  display: none;
  @media (max-width: ${breakpoint.md}) {
    display: block;
  }
`;
export const TextWithPlusIconArtCreation = styled(Text)`
  b {
    font-size: 1.4em;
    color: ${color.primary.dark};
  }
  margin: 0;
  font-weight: ${font.weight.bold};
`;

export const SourceOption = styled.label`
  display: flex;
  align-items: center;
  border-radius: ${borders.radius.md};
  border: ${borders.sizes.sm} solid ${(p) => (p.checked ? color.primary.dark : color.grayscale.lighter)};
  background-color: ${(p) => (p.checked ? color.primary.dark : color.grayscale.white)};
  color: ${(p) => (p.checked ? color.grayscale.white : color.grayscale.darker)};
  font-size: ${font.size.small};
  line-height: ${font.lineHeight.small};
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: ${color.primary.dark};
    background-color: ${color.primary.dark};
    color: ${color.grayscale.white};
  }

  @media (min-width: ${breakpoint.lg}) {
    display: block;
  }

  svg {
    width: ${space.xl5};
    height: auto;

    @media (min-width: ${breakpoint.lg}) {
      width: 100%;
    }
  }
`;

export const Input = styled.input`
  display: none;
`;

export const WarpInput = styled.div`
  display: flex;
  align-items: center;
  height: ${space.xl3};
  width: 100%;

  svg {
    width: ${space.lg};
    height: ${space.lg};
    margin: auto ${space.xs};
    border: ${borders.sizes.sm} solid ${color.grayscale.lighter};
    border-radius: ${borders.radius.circle};
    background-color: ${color.grayscale.white};
    fill: ${(p) => (p.checked ? color.primary.dark : color.grayscale.white)};
  }

  p {
    margin: 0;
    font-weight: ${font.weight.bold};
  }
`;

export const SelectedContainer = styled.div`
  width: 100%;
  padding-bottom: ${space.xl};
`;

export const SelectedSource = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SelectedSourceImage = styled.div`
  width: ${space.xl4};
  border-radius: ${borders.radius.md};
  border: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  background-color: ${color.grayscale.white};

  svg {
    fill: none !important;
    width: 100%;
    height: auto;
  }
`;

export const SelectedSourceTitle = styled.span`
  padding-left: ${space.md};
  font-size: ${font.size.h6};
`;

export const SelectedWarp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${breakpoint.md}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SelectedInfoText = styled.div`
  width: 100%;
  margin-top: ${space.md};

  @media (min-width: ${breakpoint.md}) {
    width: 50%;
  }
`;

export const ArtPrice = styled.div`
  padding: ${space.sm};
  background-color: ${color.primary.pale};
  color: ${color.primary.darker};
  font-size: ${font.size.small};
`;

export const ArtDeadline = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-top: ${space.md};
  background-color: ${color.warning.lighter};
  font-weight: ${font.weight.bold};
  ul {
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: ${color.grayscale.darker};
    font-family: ${font.family.text};
    font-size: ${font.size.small};
    line-height: ${font.lineHeight.small};
    font-style: normal;
    font-weight: 300;
    li {
      list-style-type: disc;
    }
  }
`;

export const ArtDeadlineIcon = styled.div`
  width: 6rem;
  background-color: ${color.warning.light};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const ArtDeadlineText = styled.div`
  width: calc(100% - 6rem);
  position: relative;
  float: left;
`;

export const RefreshButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${space.md};
  border: none;
  background-color: transparent;
  color: ${color.primary.dark};
  font-size: ${font.size.small};
  cursor: pointer;

  svg {
    width: ${space.md};
    height: ${space.md};
    margin-right: ${space.xs2};
    fill: ${color.primary.dark};
  }
`;
