import React, { useCallback, useState } from 'react';

import { Button } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './PickupPlace.style';

const PickupCardDisplay = ({ message, neighborhood, street, number, additionalAddress, city, state, name }) => {
  const [showSchedule, setShowSchedule] = useState(false);
  const handleChangeSchedule = useCallback(
    (e) => {
      e.preventDefault();
      setShowSchedule(!showSchedule);
    },
    [showSchedule],
  );
  return (
    <>
      {showSchedule ? (
        <S.PickupCardContainer>
          <S.Message>{message}</S.Message>
          <Button kind="link" isSmall onClick={handleChangeSchedule}>
            Ocultar horários
          </Button>
        </S.PickupCardContainer>
      ) : (
        <S.PickupCardContainer>
          <S.DisplayTitle>{name}</S.DisplayTitle>

          <S.Message>
            {street}
            {', '}
            {number}
            {additionalAddress ? `, ${additionalAddress}` : ''}
            <br />
            {neighborhood}
            <br />
            {city}/{state}
          </S.Message>

          <Button kind="link" isSmall onClick={handleChangeSchedule}>
            Ver horários
          </Button>
        </S.PickupCardContainer>
      )}
    </>
  );
};

PickupCardDisplay.propTypes = {
  message: PropTypes.string,
  neighborhood: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  additionalAddress: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  name: PropTypes.string,
};

export default PickupCardDisplay;
