import { Row, Col, Buttons, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, space } = theme;

export const ProductsRow = styled(Row)`
  margin-bottom: ${space.xl};
`;

export const ProductsCol = styled(Col)`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  @media (min-width: ${breakpoint.xl}) {
    overflow-x: initial;
    overflow-y: initial;
    white-space: initial;
  }
`;

export const Product = styled.div`
  display: inline-flex;
  vertical-align: top;
  width: 100%;
  margin-bottom: ${space.lg};
  padding: 0 ${space.xs2};

  @media (min-width: ${breakpoint.sm}) {
    width: 50%;
  }

  @media (min-width: ${breakpoint.md}) {
    width: 33.33%;
  }

  @media (min-width: ${breakpoint.lg}) {
    width: 25%;
  }

  @media (min-width: ${breakpoint.xl}) {
    width: 20%;
  }
`;

export const ButtonsGroup = styled(Buttons)`
  width: 100%;
  text-align: center;
  display: -webkit-box;
  margin-bottom: ${space.md};

  @media (min-width: ${breakpoint.xl}) {
    display: flex;
  }
`;
