import React, { memo } from 'react';

import PropTypes from 'prop-types';

import * as S from './styles';

const BoxCard = ({
  name,
  desc,
  label,
  kind,
  isSmall,
  isExpress,
  children,
  hidden,
  checked,
  isFreeShipping,
  ...rest
}) => {
  const handleClickCard = (target) => {
    target.querySelector('input').click();
  };

  return (
    <S.ChosenCardContainer hidden={hidden}>
      <S.BoxBlock
        kind="default"
        type="default"
        tabIndex="0"
        onKeyUp={({ target, code }) => code === 'Enter' && handleClickCard(target)}
        checked={checked}
      >
        {isFreeShipping && <S.FreeShipping id="is-free-shiping-card">Elegível a Frete Grátis</S.FreeShipping>}
        {isExpress && <S.ExpressShipping id="is-free-shiping-card">Mais rápido</S.ExpressShipping>}

        <input type="radio" name={name} tabIndex="-1" className="funnel-ccard-input" {...rest} />
        {children}
        <S.CheckboxCustom tabIndex="-1" type="radio" kind="circle" name={name} checked={checked} {...rest} />
      </S.BoxBlock>
    </S.ChosenCardContainer>
  );
};

BoxCard.propTypes = {
  children: PropTypes.node,
  desc: PropTypes.string,
  checked: PropTypes.bool,
  hidden: PropTypes.bool,
  isSmall: PropTypes.bool,
  isExpress: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['primary', 'secondary', 'info', 'base']),
  isFreeShipping: PropTypes.bool,
};
export default memo(BoxCard);
