import Slider from 'react-slick';

import { theme, Col } from '@printi/printi-components';
import styled from 'styled-components';
const { borders, color, space, font } = theme;

export const Carousel = styled(Slider)`
  position: relative;
  width: 100%;
  background-color: ${color.grayscale.white};

  .slick-dots {
    position: absolute;
    bottom: ${space.md};
    right: 50%;
    transform: translateX(50%);
    width: auto;
    height: auto;
    line-height: 0;

    li {
      width: ${space.xs};
      height: ${space.xs};
      margin: 0 ${space.xs};

      button {
        background-color: hsla(0, 0%, 100%, 0.3);
        border-radius: 50%;
        width: ${space.xs};
        height: ${space.xs};
        padding: 0;
        transition: 0.3s ease;

        &:before {
          display: none;
        }

        &:hover,
        &:focus {
          background-color: #fff;
        }
      }

      &.slick-active {
        button {
          background-color: #fff;
        }
      }
    }
  }
`;

export const CarouselArrow = styled.button`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 3.5rem;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${color.grayscale.gray};
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    color: ${color.primary.pure};
  }

  svg {
    width: 1.25rem;
  }

  ${(p) =>
    p.rotate
      ? `
      right: -3.5rem;
    `
      : `
      left: -3.5rem;

      > svg {
        transform: rotate(180deg);
      }
    `}
`;

export const Combination = styled.div`
  width: 100%;
  margin-bottom: ${space.lg};
  background-color: ${color.grayscale.white};
`;

export const Label = styled.label`
  display: block;
  border-radius: ${borders.radius.md};
  border: ${borders.sizes.sm} solid ${(p) => (p.checked ? color.primary.dark : color.grayscale.lighter)};
  background-color: ${(p) => (p.checked ? color.primary.dark : color.grayscale.white)};
  color: ${(p) => (p.checked ? color.grayscale.white : color.grayscale.darker)};
  font-size: ${font.size.small};
  line-height: ${font.lineHeight.small};
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: ${color.primary.dark};
    background-color: ${color.primary.dark};
    color: ${color.grayscale.white};
  }
`;

export const WarpInput = styled.div`
  display: flex;
  align-items: center;
  height: ${space.xl3};
  width: 100%;

  svg {
    width: ${space.lg};
    min-width: ${space.lg};
    height: ${space.lg};
    margin: auto ${space.xs};
    border: ${borders.sizes.sm} solid ${color.grayscale.lighter};
    border-radius: ${borders.radius.circle};
    background-color: ${color.grayscale.white};
    fill: ${(p) => (p.checked ? color.primary.dark : color.grayscale.white)};
  }

  p {
    margin: 0;
    font-weight: ${font.weight.bold};
  }
`;

export const Input = styled.input`
  display: none;
`;

export const CombinationDescription = styled.p`
  margin: ${space.xs} 0 0;
  color: ${color.grayscale.dark};
  font-size: ${font.size.small};
  line-height: ${font.lineHeight.small};
`;

export const CombinationCol = styled(Col)`
  padding: 0 ${space.xs};
`;
