import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, font, space } = theme;

export const ContainerText = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: ${space.md};

  span {
    font-family: ${font.family.text};
    font-size: ${font.size.xsmall};
    line-height: ${font.lineHeight.xsmall};
    font-weight: ${font.weight.normal};
    padding-bottom: ${space.md};
  }
`;

export const ErrorContent = styled.div`
  text-align: center;
  margin-bottom: ${space.md};
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6rem;

  button {
    margin: ${space.sm};
  }

  @media (min-width: ${breakpoint.md}) {
    flex-direction: row;
    height: auto;
  }
`;
