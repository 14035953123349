import { theme, Col, Row } from '@printi/printi-components';
import styled from 'styled-components';
const { space, breakpoint } = theme;

export const MatrixDisplayRow = styled(Row)`
  margin: ${space.md} 0;
`;

export const DateCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
`;

export const PriceCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${space.xs};

  @media (min-width: ${breakpoint.lg}) {
    justify-content: center;
  }
`;
