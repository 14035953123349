import React, { memo } from 'react';

import { Buttons, Button, Text, Icon } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Options.styles';

const SelectView = ({ viewType, selectView }) => (
  <S.ViewType>
    <Text size="sm" color="weak" weight="bold" padding="0 0.5rem 0 0" transform="uppercase">
      Modo de visualização:
    </Text>
    <Buttons>
      <Button isSmall isOutlined={viewType !== 'list'} onClick={() => selectView('list')}>
        <Icon name="List" size="sm" />
        Lista
      </Button>
      <Button isSmall isOutlined={viewType !== 'photos'} onClick={() => selectView('photos')}>
        <Icon name="Grid" size="sm" />
        Fotos
      </Button>
    </Buttons>
  </S.ViewType>
);

SelectView.propTypes = {
  viewType: PropTypes.string.isRequired,
  selectView: PropTypes.func.isRequired,
};

export default memo(SelectView);
