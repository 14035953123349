import { useState, useCallback } from 'react';

import { customApi } from '@utils';
const useDownloadTemplate = () => {
  const [downloadTemplateLoading, setDownloadTemplateLoading] = useState(false);
  const [downloadTemplateError, setDownloadTemplateError] = useState('');

  const fetchDownloadTemplate = useCallback(async (extension, orientation, parts, fileName) => {
    setDownloadTemplateLoading(true);

    const body = {
      packageName: fileName,
      parts,
    };

    await customApi
      .post(`/v1/prepress_template/filepackage_download/${extension}/${orientation}`, body)
      .then(({ data }) => {
        const { filePackageUrl } = data;

        if (filePackageUrl) {
          const win = window.open(filePackageUrl, '_blank');
          win.focus();
        }
      })
      .catch(({ response }) => {
        setDownloadTemplateError(response);
      });

    setDownloadTemplateLoading(false);
  }, []);

  return {
    fetchDownloadTemplate,
    downloadTemplateLoading,
    downloadTemplateError,
  };
};

export default useDownloadTemplate;
