const getNavigationLink = (slug, productDefaultCombination = '') => {
  let productDefault = productDefaultCombination;

  if (typeof slug !== 'string') {
    return null;
  }

  if (typeof productDefault !== 'string') {
    productDefault = '';
  }

  const link = productDefault ? `${slug}?combId=${productDefault}` : slug;
  return link;
};

export default getNavigationLink;
