import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { space, color } = theme;

export const MainBenefitsSection = styled.section`
  width: 100%;
  margin-bottom: ${space.xl4};
`;

export const MainBenefitsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 32rem;
  margin-bottom: ${space.xl};
  padding: ${space.md};
  box-shadow: 0 0 ${space.xs2} ${color.dark.light};
  border-radius: ${space.xs};
  border-bottom: ${space.md} solid ${color.dark.pure};
  background-color: ${color.grayscale.white};

  img {
    display: block;
    width: 80%;
    max-width: 10rem;
    height: auto;
    margin-bottom: ${space.xl};
  }
`;

export const HorizontalSeparator = styled.hr`
  margin-top: ${space.lg};
  width: ${space.xl5};
  height: ${space.xs};
  background-color: ${color.warning.light};
  border: none;
`;
