import styled from 'styled-components';

export const Products = styled.div`
  display: flex;
  margin-bottom: 2.5rem;

  & > a {
    max-width: calc(25% - 2rem);

    &:not(:last-child) {
      margin-right: 2.875rem;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-between;

    & > a {
      max-width: calc(50% - 0.25rem);
      margin-bottom: 1.5rem;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 992px) and (min-width: 601px) {
    & > a {
      max-width: calc(25% - 1rem);

      &:not(:last-child) {
        margin-right: 1.25rem;
      }
    }
  }
`;
