import React, { memo } from 'react';

import PropTypes from 'prop-types';

const Calculator = ({ options, productSettings }) => {
  const opts = options.parts[0];
  const newArrSpine = [];

  if (opts && opts.hasSpine === true) {
    const spineArr = opts.spine;
    const pageArr = opts.attributes.find((field) => field.key === 'page').options;
    const ps = Object.values(productSettings.selection)[0];
    const pageInfo = pageArr.find((field) => field.id === ps.page);
    const quantity = pageInfo !== undefined ? pageInfo.quantity : null;
    spineArr.forEach((item) => {
      if (item.stock === ps.stock && (item.page_quantity === ps.page || item.page_quantity === quantity)) {
        newArrSpine.push(item);
      }
    });
  }

  if (newArrSpine.length === 0) {
    return null;
  }

  return newArrSpine.map((item) => (
    <div className="tpl-canculator-spine" key={item}>
      <div className="org-calculator-spine">
        <p>{`A espessura do ${productSettings.product.title} é:`}</p>
        <p className="lwidth">{item.width_in_cm} cm</p>
      </div>
    </div>
  ));
};

Calculator.propTypes = {
  options: PropTypes.object.isRequired,
  productSettings: PropTypes.object.isRequired,
};

export default memo(Calculator);
