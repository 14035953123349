import { Row, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, space, color, font } = theme;

export const BannerRow = styled(Row)`
  position: relative;
  margin-bottom: ${space.xl};

  > div {
    padding: 0;
  }
`;

export const Imagem = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

export const Content = styled.div`
  color: ${color.inverse.pure};
  position: absolute;
  left: ${space.lg};
  top: ${space.lg};
  width: 45%;

  h1 {
    font-size: ${font.size.h5};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (min-width: ${breakpoint.md}) {
      font-size: ${font.size.h3};
    }
  }

  p {
    margin-bottom: ${space.md};
  }

  div {
    display: block;
  }

  @media (min-width: ${breakpoint.xl}) {
    left: ${space.xl3};
    top: ${space.xl5};
  }

  @media (max-width: ${breakpoint.md}) {
    line-height: 16px;

    p {
      margin-bottom: ${space.sm};

      span {
        font-size: ${font.size.root} !important;
      }
    }
  }
`;
