import { theme } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint } = theme;

export const DetailsContainer = styled.div`
  color: #151617;
  font-size: 1.3rem;
  line-height: 3rem;
  padding: 4rem 0;
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${breakpoint.lg}) {
    font-weight: bold;
    text-align: center;
    width: 68%;
  }

  p {
    margin-bottom: 0;
  }

  .span-blue {
    color: #006ee3;
  }
`;
