import React, { useCallback } from 'react';

import { Row, Col } from '@printi/printi-components';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

const MatrixDisplay = dynamic(() => import('./Table/MatrixDisplay'));

const BoxCard = dynamic(() => import('../BoxCard'));

const MatrixBlock = ({ matrix, quantity, isCustomQty, handleSelect, selectedDate }) => {
  const { matrixDates, matrixRows } = matrix;

  const handleSelectMatrix = useCallback(
    (data, qty) => handleSelect(qty, data, isCustomQty),
    [handleSelect, isCustomQty],
  );

  const filteredMatrixDates = Object.fromEntries(
    Object.entries(matrixDates)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, _]) => (matrixRows[quantity] ? matrixRows[quantity][key].length !== 0 : false)),
  );

  return (
    <Row isFull data-testid="matrix-block-container">
      {Object.entries(filteredMatrixDates).map(
        ([timestamp, cell], index) =>
          !!matrixRows[quantity][timestamp].prices && (
            <Col xs={12} lg={3} key={`${timestamp}-${quantity}`}>
              <BoxCard
                kind="base"
                name="select-card"
                onChange={() => handleSelectMatrix(timestamp, quantity, isCustomQty)}
                value={quantity}
                checked={selectedDate === timestamp}
                isExpress={index === 0}
                isFreeShipping={matrixRows[quantity][timestamp].is_free_shipping}
              >
                <MatrixDisplay
                  key={`${timestamp}-${quantity}`}
                  price={
                    matrixRows[quantity][timestamp].delivery_method.price +
                    matrixRows[quantity][timestamp].production_type.price
                  }
                  businessDays={matrixDates[timestamp].business_days}
                  deliveryDueDate={cell.delivery_due_date}
                />
              </BoxCard>
            </Col>
          ),
      )}
    </Row>
  );
};

MatrixBlock.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  matrix: PropTypes.shape({
    matrixDates: PropTypes.object.isRequired,
    matrixRows: PropTypes.object.isRequired,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
  isCustomQty: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default MatrixBlock;
