import React, { useCallback } from 'react';

import { Col, Text, Button } from '@printi/printi-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import * as S from './UseTerm.styles';

const UseTerm = ({ slug }) => {
  const router = useRouter();

  const handleNavigate = useCallback(
    (e) => {
      e.preventDefault();
      router.push(`configuracao-${slug}`);
    },
    [router, slug],
  );

  return (
    <S.UseTermRow isFull>
      <Col xs={12}>
        <S.WrapperRow center="xs" middle="xs">
          <Col xs={12} lg={3}>
            <S.CenterItem>
              <img src="/images/img_warranty_info.png" alt="100% Garantido" width="222" height="163" />
            </S.CenterItem>
          </Col>
          <Col xs={12} lg={6}>
            <Text as="h3" size="md" weight="bold" margin="0 0 1rem">
              Qualidade garantida!
            </Text>
            <Text size="sm" weight="normal">
              Proporcionamos serviços e produtos de alta qualidade a nossos clientes e parceiros porque a Printi
              trabalha com seriedade, ética e respeito. Não ficou satisfeito com a qualidade do material que recebeu?
              Ele será impresso novamente sem custos*.
            </Text>
            <Text size="sm" weight="normal" margin="0 0 1rem">
              *A reimpressão é feita de acordo com nossos{' '}
              <Link href="/termos-de-servico-e-uso-do-site">termos de uso</Link>
            </Text>
          </Col>
          {slug && (
            <Col xs={12} lg={3}>
              <S.CenterItem>
                <Button data-testid="button-test" kind="success" isBlock onClick={handleNavigate}>
                  Configure este produto
                </Button>
              </S.CenterItem>
            </Col>
          )}
        </S.WrapperRow>
      </Col>
    </S.UseTermRow>
  );
};

UseTerm.propTypes = {
  slug: PropTypes.string,
};

export default UseTerm;
