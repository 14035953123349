import React, { memo, useState } from 'react';

import Image from 'next/image';
import PropTypes from 'prop-types';
const cloudFrontUrl = process.env.CLOUDFRONT;
const boltFrontUrl = process.env.API_BOLT_URL;

const ImageFallback = ({ src, fallbackSrc, ...rest }) => {
  const [imgSrc, set_imgSrc] = useState(cloudFrontUrl + src);
  const [isChecked, setChecked] = useState(false);
  return (
    <Image
      {...rest}
      src={imgSrc}
      priority
      onLoadingComplete={(result) => {
        if (result.naturalWidth === 0 && !isChecked) {
          setChecked(true);
          set_imgSrc(boltFrontUrl + src);
        } else {
          // Broken image
          set_imgSrc(fallbackSrc);
        }
      }}
      onError={() => {
        if (!isChecked) {
          setChecked(true);
          set_imgSrc(boltFrontUrl + src);
        } else {
          set_imgSrc(fallbackSrc);
        }
      }}
    />
  );
};

ImageFallback.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};

export default memo(ImageFallback);
