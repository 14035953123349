import { Alert, theme } from '@printi/printi-components';
import styled from 'styled-components';
const { space } = theme;

export const MatrixSection = styled.section`
  width: 100%;
`;

export const AlertCustom = styled(Alert)`
  margin-bottom: ${space.md};
`;
