/* eslint-disable react/no-danger */
import React, { memo, useCallback, useState } from 'react';

import { Row, Icon, Image } from '@printi/printi-components';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import * as S from './Combination.style';
const FunnelBlock = dynamic(() => import('@components/Funnel/FunnelBlock'));

const DefaultCombinations = ({ order, source, defaultCombinations, onChange }) => {
  const { enabledCombinations, selectedCombination } = defaultCombinations;
  const { selectedSource } = source;
  const [currentCombination, setCurrentCombination] = useState(String(selectedCombination));

  const handleCombinationSelection = useCallback(
    (ev) => {
      const combination = ev.currentTarget.value;
      setCurrentCombination(combination);
      onChange(selectedSource, combination);
    },
    [onChange, selectedSource],
  );

  const renderSlider = (imagelist, alt) => {
    if (!imagelist || imagelist.length === 0) return null;

    return (
      <S.Carousel dots>
        {imagelist.map((image) => (
          <Image key={image} src={image} alt={alt} />
        ))}
      </S.Carousel>
    );
  };

  const handleImage = (combination) => {
    if (combination.position === 'custom') {
      return renderSlider(['/images/default-combinations/combinations-customize-ico.png'], combination.name);
    }

    if (!combination.image_url_json || combination.image_url_json === '[]') {
      return renderSlider(['/images/default-combinations/combinations-default-ico.png'], combination.name);
    }

    return renderSlider(JSON.parse(combination.image_url_json), combination.name);
  };

  const renderBlock = (combination) => {
    const blockName = combination.combination_id.replace(/\s/g, '');
    const isChecked = currentCombination === combination.position;

    return (
      <S.CombinationCol key={blockName} xs={6} md={4} lg={3} noGutter>
        <S.Combination>
          <S.Label
            tabIndex="0"
            onKeyUp={({ target, code }) => code === 'Enter' && target.querySelector('input').click()}
            htmlFor={blockName}
            checked={isChecked}
          >
            {handleImage(combination)}

            <S.WarpInput checked={isChecked}>
              <S.Input
                type="radio"
                id={blockName}
                name={blockName}
                checked={isChecked}
                value={combination.position}
                onChange={handleCombinationSelection}
              />
              <Icon name="CheckmarkOutline" size="sm" />
              <p>{combination.name}</p>
            </S.WarpInput>
          </S.Label>

          {combination.description && (
            <S.CombinationDescription
              size="sm"
              color="weak"
              padding="0.5rem 0 1.5rem"
              dangerouslySetInnerHTML={{ __html: combination.description }}
            />
          )}
        </S.Combination>
      </S.CombinationCol>
    );
  };

  const customCombination = {
    name: 'Personalizar',
    combination_id: 'custom_combination',
    position: 'custom',
    description: '',
  };

  return (
    <FunnelBlock
      order={order}
      isComplete={!!currentCombination}
      title="Selecione a configuração desejada"
      infos="Vamos te ajudar a configurar o produto do jeito que você precisa. Selecione entre as opções abaixo a que representa sua necessidade."
    >
      <Row isFull>
        {enabledCombinations.map((item) => item.is_visible_site === '1' && renderBlock(item))}
        {renderBlock(customCombination)}
      </Row>
    </FunnelBlock>
  );
};

DefaultCombinations.propTypes = {
  order: PropTypes.number.isRequired,
  source: PropTypes.object.isRequired,
  defaultCombinations: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default memo(DefaultCombinations);
