/* eslint-disable react/no-danger */
import React, { memo, useCallback, useState } from 'react';

import { Button, Text, Col } from '@printi/printi-components';
import { cloudFront } from '@utils';
import PropTypes from 'prop-types';

import CarouselArrow from './CarouselArrow';
import * as S from './Informations.styles';

const tabs = [
  { id: 1, title: 'Dicas', value: 'tips' },
  { id: 2, title: 'Como utilizar', value: 'utilizations' },
];

const Informations = ({ informations }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [currentInfo, setCurrentInfo] = useState(informations[tabs[0].value]);

  const handleSelectATab = useCallback(
    (tabId, tabValue) => {
      setSelectedTab(tabId);
      setCurrentInfo(informations[tabValue]);
    },
    [informations],
  );

  const renderCarouselContent = (carouselContent) => {
    if (carouselContent && carouselContent.length === 0) {
      return <span />;
    }

    return carouselContent.map(({ id, image, name, content }) => (
      <S.CardInfo key={id}>
        <S.CardImage>
          <img src={`${cloudFront}${image.filename}`} alt={image.title} title={image.title} width={270} height={270} />
        </S.CardImage>
        <S.CardText>
          <Text as="h4" weight="bold" size="xxlg" margin="0 0 0.5rem">
            {name}
          </Text>
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </S.CardText>
      </S.CardInfo>
    ));
  };

  if (informations.tips.length === 0 && informations.utilizations.length === 0) {
    return null;
  }

  return (
    <S.InformationsRow>
      <Col>
        <S.ButtonsGroup isPill>
          {tabs.map((tab) => (
            <Button
              key={tab.id}
              isOutlined={tab.id !== selectedTab}
              onClick={() => handleSelectATab(tab.id, tab.value)}
            >
              {tab.title}
            </Button>
          ))}
        </S.ButtonsGroup>

        <S.Carousel
          responsive={[
            {
              breakpoint: 6000,
              settings: {
                dots: true,
                prevArrow: <CarouselArrow />,
                nextArrow: <CarouselArrow rotate />,
              },
            },
            {
              breakpoint: 1220,
              settings: {
                dots: false,
                arrows: false,
                prevArrow: null,
                nextArrow: null,
              },
            },
          ]}
        >
          {renderCarouselContent(currentInfo)}
        </S.Carousel>
      </Col>
    </S.InformationsRow>
  );
};

Informations.propTypes = {
  informations: PropTypes.shape({
    tips: PropTypes.arrayOf(PropTypes.any),
    utilizations: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
};

export default memo(Informations);
