import { Row, theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, space } = theme;

export const TextVideoRow = styled(Row)`
  margin-bottom: ${space.xl};
`;

export const List = styled.ul`
  padding: 0;
  margin-bottom: ${space.md};
  list-style: none;

  @media (min-width: ${breakpoint.xl}) {
    margin-bottom: 0;
  }
`;

export const ListItem = styled.li`
  margin-bottom: ${(p) => (p.last ? 0 : space.xs2)};

  &:before {
    content: '•';
    margin-right: 0.3rem;
  }
`;

export const ImageVideo = styled.img`
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
`;
