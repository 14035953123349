export const gtmSuccessfullyLogin = (rest) => {
  window.dataLayer?.push({
    event: 'update_user_id',
    ...rest,
    tipo: 'login',
  });
};

export const gtmSuccessfullyRegister = (rest) => {
  window.dataLayer?.push({
    event: 'update_user_id',
    ...rest,
    tipo: 'cadastro',
  });
};

export const gtmLandingPageViewItem = (rest) => {
  window.dataLayer?.push({ ecommerce: null }); // Clears the previous ecommerce
  window.dataLayer?.push({
    event: 'view_item',
    ecommerce: {
      ...rest,
    },
  });
};

export const gtmAddToCart = (rest) => {
  window.dataLayer?.push({ ecommerce: null }); // Clears the previous ecommerce
  window.dataLayer?.push({
    event: 'add_to_cart',
    ecommerce: {
      ...rest,
    },
  });
};

export const gtmViewCart = (rest) => {
  window.dataLayer?.push({ ecommerce: null }); // Clears the previous ecommerce
  window.dataLayer?.push({
    event: 'view_cart',
    ecommerce: {
      ...rest,
    },
  });
};
