/* eslint-disable no-undef,func-names,prefer-rest-params */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Icon, Link, Button, theme } from '@printi/printi-components';
import { settingsMatrixZipcodeFetch, settingsZipcodeReset, settingsSetDeliveryMethod } from '@store/settings/actions';
import { useWindowSize } from '@utils';

import * as S from './styles';

const breakpointLG = 768;

const ZipBlock = () => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  const zipCodeStorage = localStorage.getItem('printiZipCode');
  const [zipCode, setZipCode] = useState(zipCodeStorage || '');
  const {
    config: { zipcode: matrixZipCode },
  } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(settingsSetDeliveryMethod(''));
  }, [dispatch]);

  useEffect(() => {
    if (
      !matrixZipCode.isRunning &&
      !matrixZipCode.isZipcodeValid &&
      matrixZipCode.zipcodeErrorMessage.length === 0 &&
      zipCode.length === 9
    ) {
      localStorage.setItem('printiZipCode', zipCode);
      dispatch(settingsMatrixZipcodeFetch(zipCode));
    }
  }, [dispatch, matrixZipCode.isRunning, matrixZipCode.isZipcodeValid, zipCode]);

  const handleCepChange = useCallback((event) => {
    dispatch(settingsZipcodeReset());
    setZipCode(event.currentTarget.value);
  }, []);

  const handleCepValidate = useCallback(
    ({ key, type }) => {
      if ((key === 'Enter' || type === 'click') && zipCode.length === 9) {
        localStorage.setItem('printiZipCode', zipCode);
        dispatch(settingsMatrixZipcodeFetch(zipCode));
      }
    },
    [dispatch, zipCode],
  );

  const removeZipCode = useCallback(() => {
    dispatch(settingsZipcodeReset(''));
    setZipCode('');
  }, [dispatch]);

  const findZipCode = useCallback(() => {
    window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank');
  }, []);

  const ActionButton = (
    <S.BlockButton
      kind="primary"
      disabled={!matrixZipCode.isZipcodeValid || matrixZipCode.isZipcodeValid === ''}
      onClick={handleCepValidate}
      data-optimum="matrix-zipcode-button-act"
    >
      Ok
    </S.BlockButton>
  );

  return (
    <>
      <S.DeliveryCep>
        <S.BlockBox noBorder>
          <S.ZipBox>
            <S.CepInput
              data-testid="input-cep"
              isBlock
              label="Digite seu CEP"
              type="text"
              hasMask
              options={{
                delimiters: ['-'],
                blocks: [5, 3],
                numericOnly: true,
              }}
              value={zipCode}
              onChange={handleCepChange}
              removeErrorSpace
              placeholder="xxxxx-xxx"
              data-optimum="matrix-zipcode-input"
              hasError={!matrixZipCode.isZipcodeValid}
              error={matrixZipCode.zipcodeErrorMessage}
            />
            {ActionButton}
          </S.ZipBox>
        </S.BlockBox>

        <Link kind="text" onClick={findZipCode} size="xs">
          <S.TextCep color="gray" weight="normal" size="md" margin="1rem 0">
            Não sabe seu CEP? <b>&gt;</b>
          </S.TextCep>
        </Link>
      </S.DeliveryCep>
      <S.DeliveryCepInfo hidden={!matrixZipCode.isZipcodeValid}>
        <S.DeliveryBlockText>
          <Text weight="bold" align="left" size="lg" margin="0 0 1rem 0">
            <Icon name="PinOutline" size="md" fill={theme.color.grayscale.gray} />
            {`${
              windowSize && windowSize.width > breakpointLG
                ? `${matrixZipCode.street} - ${matrixZipCode.neighborhood}, `
                : ' '
            }
            ${matrixZipCode.city} - ${matrixZipCode.state}`}
          </Text>
        </S.DeliveryBlockText>
        <div>
          <Button isOutlined onClick={removeZipCode}>
            Trocar endereço
          </Button>
        </div>
      </S.DeliveryCepInfo>
    </>
  );
};

export default memo(ZipBlock);
