import { Row, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, space } = theme;

export const ProductRow = styled(Row)`
  margin-bottom: ${space.xl};
`;

export const ProductSeeMoreContainer = styled.div`
  display: block;
  width: 100%;
  padding: 0.4rem;
  position: relative;

  @media (min-width: ${breakpoint.md}) {
    display: inline-flex;
    vertical-align: top;
    width: 50%;
    min-height: 25.5rem;
  }

  @media (min-width: ${breakpoint.xl}) {
    width: ${(p) => (p.medium ? '50%' : '33%')};
    padding: 0.8rem;
  }
`;
