import React, { memo } from 'react';

import { Icon } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './MoreInfos.styles';

const MoreInfos = ({ text, id }) => (
  <S.MoreInfos tabIndex="0">
    <Icon data-testid="icon-test" name="QuestionMarkCircleOutline" width="24" height="24" />
    <S.MoreInfosContent id={id} role="tooltip">
      {text}
    </S.MoreInfosContent>
  </S.MoreInfos>
);

MoreInfos.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default memo(MoreInfos);
