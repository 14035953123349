import React, { memo } from 'react';

import { Text, Row, Col } from '@printi/printi-components';
import { cloudFront } from '@utils';

import * as S from './MainBenefits.styles';

const MainBenefits = ({ mainBenefits }) => {
  const renderBenefits = () =>
    mainBenefits.box.map((benefit) => (
      <Col xs={12} sm={6} lg={4} key={benefit.id}>
        <S.MainBenefitsCard>
          <img
            src={`${cloudFront}${benefit.image.filename}`}
            alt={benefit.image.title}
            title={benefit.image.title}
            width={170}
            height={170}
          />

          <Text as="h5" weight="bold" size="xlg" align="center">
            {benefit?.title}
          </Text>
          <S.HorizontalSeparator />
          <div dangerouslySetInnerHTML={{ __html: benefit.text }} />
        </S.MainBenefitsCard>
      </Col>
    ));

  return (
    <S.MainBenefitsSection>
      <Row>
        <Col xs={12}>
          <Text align="center" as="h4" size="h3" weight="bold" margin="1.5rem 0">
            {mainBenefits?.title}
          </Text>
        </Col>
      </Row>

      <Row>{mainBenefits.box && renderBenefits()}</Row>
    </S.MainBenefitsSection>
  );
};
export default memo(MainBenefits);
