import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { color, space } = theme;

export const HorizontalSeparator = styled.hr`
  margin: 0 0 ${space.xl} 0;
  border: ${color.warning.light} solid ${space.xl};
  width: ${space.xl5};
  border-width: ${space.xs} 0 0 0;
`;
