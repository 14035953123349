import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Collapse, CollapseItem, Modal } from '@printi/printi-components';
import {
  settingsCustomOptionsFetch,
  settingsMatrixReset,
  removePartSelection,
  settingsOptionsReset,
} from '@store/settings/actions';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import attributesList from './attributesList';
import * as S from './Options.styles';

const ImageZoomModal = dynamic(() => import('../Modals/ImageZoom'));
const CustomFormatModal = dynamic(() => import('../Modals/CustomFormatModal'));
const CustomPageModal = dynamic(() => import('../Modals/CustomPageModal'));
const Option = dynamic(() => import('@components/Funnel/Option'));

const SelectOption = ({ t, parts, selection, selectSize, disabled, viewType }) => {
  const dispatch = useDispatch();
  const {
    options: { custom, optionsFetchError },
  } = useSelector((state) => state.settings);
  const [modal, setModal] = useState({
    isOpen: false,
    partId: '',
    optionId: '',
    itemId: '',
    isLoadingSettings: false,
  });

  const handleSettingsOptionsReset = useCallback(() => {
    dispatch(settingsOptionsReset());
  }, [dispatch]);

  const handleModalOpen = useCallback((type, data) => {
    setModal((oldState) => ({
      ...oldState,
      isOpen: true,
      type,
      data,
    }));
  }, []);

  const handleModalClose = useCallback(() => {
    setModal((oldState) => ({
      ...oldState,
      isOpen: false,
      type: '',
      data: {},
    }));
  }, []);

  const handlePartRemove = useCallback(
    (part) => {
      dispatch(removePartSelection(part));
    },
    [dispatch],
  );

  const handleCustomSubmit = useCallback(
    (quantity, attribute, productPart) => {
      dispatch(settingsMatrixReset());
      dispatch(settingsCustomOptionsFetch(productPart, attribute, quantity));
      const qtd = typeof quantity === 'string' ? quantity.replace('/', 'x') : toString(quantity)?.replace('/', 'x');
      selectSize(productPart, attribute, `custom=${qtd}`);
    },
    [dispatch, selectSize],
  );

  const renderModal = () => {
    const modalType = {
      image: ImageZoomModal,
      format: CustomFormatModal,
      page: CustomPageModal,
    };

    const Component = modalType[modal.type];

    return (
      Component && (
        <Component
          locale={t}
          onClose={handleModalClose}
          data={modal.data}
          onSubmit={handleCustomSubmit}
          custom={custom}
        />
      )
    );
  };

  const renderExtraInformation = (option) => {
    const { digital_product_extra_information_option } = t;
    const extraInformation = { description: '', extra_price: null };

    if (option.name === 'Fechado' || option.name === 'Aberto') {
      extraInformation.description = digital_product_extra_information_option[option.name].DESCRIPTION || '';
      extraInformation.extra_price = option.extra_price;
    }

    return (
      <>
        {extraInformation.description && <div className="description">{extraInformation.description}</div>}
        {extraInformation.extra_price > 0 && (
          <div className="price-title">
            <span className="cost">+R${extraInformation.extra_price}</span>
          </div>
        )}
      </>
    );
  };

  const renderCustom = (attribute, productPart, limits) => (
    <Option
      key="custom"
      id="custom"
      name={`${productPart}-${attribute}`}
      value="custom"
      checked={false}
      onChange={() => handleModalOpen(attribute, { productPart, attribute, limits })}
      label={t.CUSTOM_OPTION}
      image={viewType === 'photos' && '/assets/final/img/imagem-indisponivel-semtexto2.jpg'}
    />
  );

  const renderOption = (part) =>
    attributesList(part, selection).map(({ key, name, options: attrOptions, customLimits }) => (
      <div key={`${part.id}-${key}`}>
        <Text as="h3" size="lg" weight="bold" margin="0 0 1rem">
          {name}
        </Text>
        <S.WrapperOptions>
          {attrOptions
            .sort((a, b) => a.position - b.position || a.id - b.id)
            .map((option) => (
              <Option
                key={`${part.id}-${option.id}`}
                id={`${part.id}-${option.id}`}
                name={`${part.id}-${key}`}
                value={option.id}
                onChange={() => selectSize(part.id, key, option.id)}
                label={option.name}
                checked={selection[part.id] && option.id === selection[part.id][key]}
                disabled={disabled}
                image={viewType === 'photos' && `${option.imageSmall}`}
                zoomable
                extraInformation={renderExtraInformation(option)}
                onZoomClick={() => handleModalOpen('image', { link: option.imageBig, text: '' })}
              />
            ))}

          {((key === 'format' && part.hasCustomFormat) || (key === 'page' && part.hasCustomPage)) &&
            renderCustom(key, part.id, customLimits)}
        </S.WrapperOptions>
      </div>
    ));

  return (
    <>
      {parts.length === 1 ? (
        renderOption(parts[0])
      ) : (
        <S.WrapperCollapse>
          <Collapse kind="accordion">
            {parts.map((part) => (
              <CollapseItem key={part.id} maxHeight="100%">
                <Text weight="bold" size="xlg">
                  {part.name}
                  {part.canBeRemoved && (
                    <S.RemoveButton
                      kind="link"
                      role="button"
                      type="button"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        return handlePartRemove(part.id);
                      }}
                    >
                      {t.REMOVE}
                    </S.RemoveButton>
                  )}
                </Text>
                <S.CollapseBody>{renderOption(part)}</S.CollapseBody>
              </CollapseItem>
            ))}
          </Collapse>
        </S.WrapperCollapse>
      )}

      {modal.isOpen && renderModal()}

      {optionsFetchError && (
        <Modal infos={{ title: 'Atenção' }} close={handleSettingsOptionsReset}>
          <Text size="sm" align="center">
            {optionsFetchError}
          </Text>
        </Modal>
      )}
    </>
  );
};

SelectOption.propTypes = {
  t: PropTypes.object.isRequired,
  parts: PropTypes.array.isRequired,
  selection: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  selectSize: PropTypes.func.isRequired,
  viewType: PropTypes.string.isRequired,
};

export default SelectOption;
