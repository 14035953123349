import { theme, Row } from '@printi/printi-components';
import styled from 'styled-components';

const { space, color } = theme;

export const UseTermRow = styled(Row)`
  margin-bottom: ${space.xl};
`;

export const WrapperRow = styled(Row)`
  width: 100%;
  padding: ${space.lg} 0 ${space.md};
  background-color: ${color.warning.lighter};
`;

export const CenterItem = styled.div`
  width: 100%;
  text-align: center;
`;
