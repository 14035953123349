import { Box, theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, space, font } = theme;

export const DebugMatrix = styled(Box)`
  display: none;

  @media (min-width: ${breakpoint.xl}) {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 20rem;
    max-height: ${(p) => (p.isOpen ? '32rem' : space.xl3)};
    overflow-y: auto;
    cursor: pointer;
  }
`;

export const MatrixList = styled.ul`
  margin: 0;
  font-size: ${font.size.small};

  & > li,
  ul {
    margin-bottom: ${space.xs2};
  }
`;
