import React, { memo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Page from '@components/Page';
import { Loader, Row, Col, Breadcrumb, Text, theme } from '@printi/printi-components';
import { basicCartLoadRequest } from '@store/basicCart/actions';
import {
  settingsFetch,
  settingsSourceFetch,
  settingsZipcodeReset,
  settingsProductReset,
} from '@store/settings/actions';
import { isAdminUser, useWindowSize } from '@utils';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';

import * as S from './ProductsSettings.styles';
import useAddToCart from './useAddToCart';
import useFetchPickUpPlaces from './useFetchPickUpPlaces';

const Sources = dynamic(() => import('./Sources'));
const Summary = dynamic(() => import('./Summary'));
const Options = dynamic(() => import('./Options'));
const Matrix = dynamic(() => import('./Matrix'));
const DebugMatrix = dynamic(() => import('./DebugMatrix'));
const CartZipcodeModal = dynamic(() => import('./Modals/CartZipcodeModal'));
const DefaultCombinationsBlock = dynamic(() => import('./DefaultCombinations'));

const SEO = {
  title: 'Configuração de produto | Printi',
  description: 'Página de configuração de produto da Printi',
  abstract: 'Configuração de produto | Printi',
};

const ProductsSettings = ({ slugId, combId, templateId, templateIsEditable }) => {
  const { breakpoint } = theme;
  const breakpointXL = parseInt(breakpoint.xl, 10) * 16;
  const windowSize = useWindowSize();
  const isMobile = windowSize && windowSize.width < breakpointXL;
  const dispatch = useDispatch();
  const { fetchPickUpPlaces, pickupPlaces } = useFetchPickUpPlaces();
  const { addToCart, addToCartLoading } = useAddToCart();
  const { locale } = useSelector((state) => state.locale);
  const productSettingsState = useSelector((state) => state.settings);
  const { loading: cartLoading, zipcode: cartZipcode } = useSelector((state) => state.basicCart);
  const [isCartLoaded, setIsCartLoaded] = useState(false);
  const [isOpenArtModal, setIsOpenArtModal] = useState(false);
  const [isCartZipcodeModalOpen, setIsCartZipcodeModalOpen] = useState(false);
  const [isSourceAutoSelectedFlag, setIsSourceAutoSelectedFlag] = useState(false);
  const {
    source,
    options,
    defaultCombinations,
    config,
    art_creation_rules,
    alert_product_message,
    isSourceAutoSelected,
    finalProduct,
    product,
    templates,
    selection,
    calculator,
    matrix,
    isRunning,
    isLoaded,
    hasError,
    error,
  } = productSettingsState;
  const { enabledCombinations, isSelectedCombination, selectedCombination } = defaultCombinations;
  const isDigital = finalProduct.tangibility_type === 'DIGITAL';
  const { selectedSource, isRunning: isRunningSource, isLoaded: isLoadedSource } = source;
  const isAdmin = isAdminUser();

  const handleSource = useCallback(
    (sourceId, combinationId = 0) => {
      dispatch(settingsSourceFetch(finalProduct.id, sourceId, combinationId));
    },
    [dispatch, finalProduct.id],
  );

  useEffect(() => {
    if (window.dataLayer && finalProduct && finalProduct.id) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'SettingsPage',
        eventAction: finalProduct.id,
        eventLabel: 'View',
        eventValue: 0,
        eventNonInteraction: 1,
      });
    }
  }, [finalProduct]);

  useEffect(() => {
    dispatch(settingsFetch(slugId));
    fetchPickUpPlaces();

    return () => {
      dispatch(settingsProductReset());
    };
  }, [dispatch, fetchPickUpPlaces, slugId]);

  useEffect(() => {
    if (isLoaded && isSourceAutoSelected) {
      setIsSourceAutoSelectedFlag(isSourceAutoSelected);
    }
  }, [isLoaded, isSourceAutoSelected]);

  useEffect(() => {
    if (!cartLoading && !isCartLoaded) {
      setIsCartLoaded(true);
      dispatch(basicCartLoadRequest());
    }

    if (!config.showSteps.source && selectedSource && !isRunningSource && !isLoadedSource) {
      handleSource(selectedSource);
    }
  }, [
    config,
    dispatch,
    cartLoading,
    isCartLoaded,
    isSourceAutoSelected,
    selectedSource,
    isRunningSource,
    isLoadedSource,
    handleSource,
  ]);

  const handleCartAdd = useCallback(() => {
    const zipcode = config.zipcode.usePickupPlaces ? config.zipcode.pickupPlaceZipcode : config.zipcode.value;

    if (!isDigital && cartZipcode && cartZipcode.replace('-', '') !== zipcode.replace('-', '')) {
      setIsCartZipcodeModalOpen(true);
      return;
    }
    addToCart(templateId, templateIsEditable, selectedCombination, isMobile);
  }, [
    config.zipcode.usePickupPlaces,
    config.zipcode.pickupPlaceZipcode,
    config.zipcode.value,
    isDigital,
    cartZipcode,
    addToCart,
    templateId,
    templateIsEditable,
    selectedCombination,
    isMobile,
  ]);

  const handleCartClear = useCallback(() => {
    addToCart(templateId, templateIsEditable);
  }, [addToCart, templateId, templateIsEditable]);

  const handleCepReset = useCallback(() => {
    dispatch(settingsZipcodeReset());
  }, [dispatch]);

  const handleArtModal = useCallback((isOpen, isSubmit) => {
    setIsOpenArtModal(isOpen);
    if (isSubmit) {
      setIsSourceAutoSelectedFlag(false);
    }
  }, []);

  // will return true in case more than one combination is visible
  const hasDefaultConbinationsActive =
    enabledCombinations && enabledCombinations.some((item) => item.is_visible_site === '1');

  const hasNilPositionActive =
    enabledCombinations && enabledCombinations.some((item) => item.is_visible_site === '1' && item.position === '0');

  const showCombinationsBlock =
    config.showSteps.defaultCombinations &&
    source.selectedSource &&
    hasDefaultConbinationsActive &&
    !(isSourceAutoSelectedFlag && source.selectedSource === 'art_creation') &&
    (hasDefaultConbinationsActive || source.selectedSource === 'art_creation');

  const showOptions =
    ((config.showSteps.options && source.selectedSource && isSelectedCombination && selectedCombination === 'custom') ||
      !hasDefaultConbinationsActive) &&
    ((!isSourceAutoSelectedFlag && source.selectedSource === 'art_creation') ||
      source.selectedSource !== 'art_creation');

  const showMatrixBlock =
    config.showSteps.matrix &&
    options.selectionIsComplete &&
    isSelectedCombination &&
    !(isSourceAutoSelectedFlag && source.selectedSource === 'art_creation') &&
    ((hasDefaultConbinationsActive
      ? !hasNilPositionActive && hasDefaultConbinationsActive && String(selectedCombination) !== '0'
      : true) ||
      hasNilPositionActive);

  const orders = {
    sources: 1,
    defaultCombinations: () => {
      if (config.showSteps.source) {
        return 2;
      }

      return 1;
    },
    options: () => {
      if (config.showSteps.source && showCombinationsBlock) {
        return 3;
      }

      if (!config.showSteps.source && showCombinationsBlock) {
        return 2;
      }

      if (config.showSteps.source && !showCombinationsBlock) {
        return 2;
      }

      return 1;
    },
    matrix: () => {
      if (config.showSteps.source && showCombinationsBlock && showOptions) {
        return 4;
      }

      if (!config.showSteps.source && showCombinationsBlock && showOptions) {
        return 3;
      }

      if (config.showSteps.source && !showCombinationsBlock && showOptions) {
        return 3;
      }

      if (config.showSteps.source && showCombinationsBlock && !showOptions) {
        return 3;
      }

      return 2;
    },
  };

  return (
    <Page seo={SEO}>
      {isRunning && (
        <Row>
          <Col xs={12}>
            <Loader size="medium" wrapper="8rem" />
          </Col>
        </Row>
      )}

      {!isRunning && product && (
        <S.Container>
          <Row>
            <Col xs={12}>
              <Breadcrumb
                nextLink={Link}
                pages={[
                  {
                    label: 'Home',
                    path: '/',
                  },
                  {
                    label: product.title,
                    path: `/${product.slug}`,
                  },
                  {
                    label: 'Configure',
                  },
                ]}
              />
              <Text as="h1" size="xlg" weight="bold" margin="0 0 1.5rem">
                {`${locale.translate.page.product_settings.TITLE}: ${product.title}`}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} xl={9}>
              <S.Main>
                {config.showSteps.source && (
                  <Sources
                    order={orders.sources}
                    finalProduct={finalProduct}
                    source={source}
                    artCreationRules={art_creation_rules}
                    onSourceChange={handleSource}
                    combId={combId}
                    templateId={templateId}
                    isOpenArt={isOpenArtModal}
                    isSourceAutoSelected={isSourceAutoSelectedFlag}
                    handleArtModal={handleArtModal}
                  />
                )}

                {showCombinationsBlock && alert_product_message?.content && (
                  <S.WarningBox>
                    <Text color="danger">
                      <p dangerouslySetInnerHTML={{ __html: alert_product_message?.content }} />
                    </Text>
                  </S.WarningBox>
                )}

                {showCombinationsBlock && (
                  <DefaultCombinationsBlock
                    order={orders.defaultCombinations()}
                    source={source}
                    onChange={handleSource}
                    defaultCombinations={defaultCombinations}
                  />
                )}

                {showOptions && (
                  <Options
                    order={orders.options()}
                    source={source}
                    options={options}
                    selection={selection}
                    calculator={calculator}
                    finalProduct={finalProduct}
                    isLoading={options.isRunning || !options.isLoaded}
                  />
                )}

                {showMatrixBlock && (
                  <>
                    <Matrix
                      order={orders.matrix()}
                      locale={{
                        ...locale.translate.page.upload.flash_messages,
                        ...locale.translate.page.product_settings.options,
                        ...locale.translate.page.product_settings.matrix,
                        AND: locale.translate.common.AND,
                        REMOVE: locale.translate.page.cart.voucher.REMOVE,
                        flash_messages: {
                          ...locale.translate.page.upload.flash_messages,
                          delivery_text: locale.translate.page.upload.delivery_text,
                          UPLOAD_DUE_DATE:
                            locale.translate.account.my_orders.order_details.item_details.UPLOAD_DUE_DATE,
                        },
                        PRICE: locale.translate.page.cart.product_list.PRICE,
                        AFTER_ART_APPROVAL: locale.translate.page.cart.product_list.AFTER_ART_APPROVAL,
                        ERROR_DELIVERY_DEADLINE_PER_QUANTITY:
                          locale.translate.page.cart.product_list.ERROR_DELIVERY_DEADLINE_PER_QUANTITY,
                        ...locale.translate.msg.neutral,
                        common: locale.translate.common,
                        COUNTRY_CODE: locale.translate.COUNTRY_CODE,
                        LANGUAGE: locale.translate.LANGUAGE,
                      }}
                      selection={selection}
                      selectedSource={source.selectedSource}
                      matrix={matrix}
                      product={product}
                      selectedCombination={selectedCombination}
                      isCustomEnabled={finalProduct.custom_qty === '1'}
                      config={config}
                      pickupPlaces={pickupPlaces}
                      isDigital={isDigital}
                      isLoaded={
                        matrix.selection.isCustom
                          ? matrix.customQuantity.isLoaded && !matrix.customQuantity.isRunning
                          : matrix.isLoaded && !matrix.isRunning
                      }
                    />

                    <S.AddCartButton
                      kind="success"
                      isDisabled={matrix.selection.date <= 0 || matrix.selection.quantity <= 0}
                      isLoading={config.isAddingToCart || addToCartLoading}
                      onClick={handleCartAdd}
                      data-optimum="product-settings-advance-button"
                    >
                      {locale.translate.page.product_settings.ADD_TO_CART}
                    </S.AddCartButton>
                  </>
                )}
                {isSourceAutoSelectedFlag && source.selectedSource === 'art_creation' && (
                  <S.AddCartButtonMobile
                    kind="success"
                    onClick={() => handleArtModal(!isOpenArtModal)}
                    data-optimum="product-settings-advance-button"
                  >
                    {locale.translate.page.product_settings.ADD_TO_CART}
                  </S.AddCartButtonMobile>
                )}
              </S.Main>
            </Col>
            <Col xs={3}>
              <Summary
                selectedSource={source.selectedSource || ''}
                selectionIsComplete={options.selectionIsComplete}
                productTitle={product.title}
                templates={templates}
                matrix={matrix}
                options={options}
                config={config}
                handleCartAdd={handleCartAdd}
                productSettingsState={productSettingsState}
                loading={config.isAddingToCart || addToCartLoading}
                isArtCreationAutoSelected={isSourceAutoSelectedFlag && source.selectedSource === 'art_creation'}
                handleArtModal={() => handleArtModal(!isOpenArtModal)}
              />
            </Col>
          </Row>
        </S.Container>
      )}

      {hasError && error && (
        <Text color="danger" size="lg" weight="bold" align="center" margin="4rem 0 6rem">
          {error}
        </Text>
      )}

      {isCartZipcodeModalOpen && (
        <CartZipcodeModal
          close={() => setIsCartZipcodeModalOpen(false)}
          onZipcodeChange={handleCepReset}
          onCartClear={handleCartClear}
          cartZipcode={cartZipcode}
          matrixZipcode={config.zipcode.usePickupPlaces ? config.zipcode.pickupPlaceZipcode : config.zipcode.value}
        />
      )}

      {matrix && isAdmin && <DebugMatrix matrix={matrix} />}
    </Page>
  );
};

ProductsSettings.propTypes = {
  slugId: PropTypes.string.isRequired,
  combId: PropTypes.string,
  templateId: PropTypes.string,
  templateIsEditable: PropTypes.string,
};

export default memo(ProductsSettings);
