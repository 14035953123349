import { Row, theme } from '@printi/printi-components';
import styled, { css } from 'styled-components';

const { breakpoint, borders, space, color, font } = theme;

export const BenefitsRow = styled(Row)`
  margin-bottom: ${space.md};
  margin-left: -${space.sm};

  @media (min-width: ${breakpoint.xl}) {
    margin-left: 0;
  }
`;

export const Benefits = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

export const AdvantagesContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 ${space.xl4};
  margin-right: -${space.lg};
  position: relative;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media (min-width: ${breakpoint.xl}) {
    justify-content: center;
    margin-right: 0;
    padding: 0 ${space.md} ${space.xl4};
  }
`;

export const BenefitsCard = styled.ul`
  z-index: ${(p) => (p.isMain ? '2' : '1')};
  display: flex;
  flex-direction: column;
  min-width: ${(p) => (p.isMain ? '24rem' : '18rem')};
  margin: 0;
  padding: 0;
  box-shadow: ${(p) => (p.isMain ? '0 0.4rem 0.8rem 0 rgba(83, 117, 153, 0.5)' : 'none')};
  background-color: ${color.grayscale.lighter};

  @media (min-width: ${breakpoint.xl}) {
    &:nth-of-type(1) {
      order: 1;
    }

    &:nth-of-type(3) {
      order: 2;
    }

    min-width: auto;
    width: ${(p) => (p.isMain ? '39rem' : '30rem')};
  }
`;

const cardItemTitle = (p) => css`
  height: ${p.isBig ? '7.5rem' : '3.5rem'};
  border-bottom: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  background-color: ${color.grayscale.white};
  text-transform: uppercase;
  font-weight: ${font.weight.bold};
  color: ${color.dark.dark};
`;

export const BenefitsCardItem = styled.li`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.alignLeft ? 'flex-start' : 'center')};
  height: ${(p) => (p.isBig ? space.xl4 : space.xl3)};
  padding: 0 ${space.xl};
  color: ${color.grayscale.darker};
  text-align: center;

  ${(p) => p.isTitle && cardItemTitle}

  &:nth-of-type(2n) {
    background-color: ${color.grayscale.white};
  }
`;

export const SubscriptText = styled.sub`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0;
  position: absolute;
  bottom: ${space.xl};
  right: ${space.xl};
  left: inherit;
  color: ${color.grayscale.darker};
`;
