import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { settingsAdditionalOptionsFetch } from '@store/settings/actions';
import { formatMoney } from '@utils';
import PropTypes from 'prop-types';

import * as S from './styles';

const MatrixDigitalProduct = ({ matrix }) => {
  const dispatch = useDispatch();
  const { rows, dates, selection } = matrix;

  const handleDigitalProductData = useCallback(
    (timestamp, quantity) => {
      dispatch(settingsAdditionalOptionsFetch(timestamp, quantity));
    },
    [dispatch],
  );

  return (
    <S.MatrixDigitalProduct>
      {Object.keys(rows).map((quantity) =>
        Object.keys(dates).map((timestamp, index) => {
          const priceTotal = rows[quantity] && rows[quantity][timestamp] ? rows[quantity][timestamp].prices.total : 0;
          const isExpress = index === 0;
          const isShipping = index === Object.keys(dates).length - 1;
          const businessDays = dates[timestamp].business_days;

          return (
            <S.ButtonProduct
              key={timestamp}
              isExpress={isExpress}
              isShipping={isShipping}
              isChecked={selection.date === timestamp}
              onClick={() => handleDigitalProductData(timestamp, quantity)}
            >
              <S.Day>
                <span>{businessDays}</span>
                {businessDays === 1 ? 'Dia útil' : 'Dias úteis'}*
              </S.Day>
              <S.Price>{formatMoney(priceTotal)}</S.Price>
            </S.ButtonProduct>
          );
        }),
      )}
    </S.MatrixDigitalProduct>
  );
};

MatrixDigitalProduct.propTypes = {
  matrix: PropTypes.shape({
    customQuantity: PropTypes.object.isRequired,
    dates: PropTypes.object.isRequired,
    deliveryMethod: PropTypes.oneOf(['', 'zipcode', 'pickupPlaces']).isRequired,
    rows: PropTypes.object.isRequired,
    selection: PropTypes.shape({
      date: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
      isCustom: PropTypes.bool,
      quantity: PropTypes.number,
    }).isRequired,
    error: PropTypes.string,
    isLoaded: PropTypes.bool,
    isRunning: PropTypes.bool,
  }).isRequired,
};

export default memo(MatrixDigitalProduct);
