import { Col, Button, theme } from '@printi/printi-components';
import styled from 'styled-components';
const { space } = theme;

export const ColBlock = styled(Col)`
  vertical-align: middle;
  text-align: left;
  margin: auto;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
`;

export const ColBlockPickup = styled(Col)`
  padding: ${space.md} 0;
`;

export const CustomButton = styled(Button)`
  margin: auto;
  text-align: center;
`;

export const SubTitleBlockPickup = styled(Col)`
  width: 100%;
  display: flex;
  margin-bottom: ${space.lg};
`;
