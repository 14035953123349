import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Icon, Text } from '@printi/printi-components';
import { settingsSourceSelect } from '@store/settings/actions';
import { formatMoney } from '@utils';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { vsprintf } from 'sprintf-js';

import * as S from './Source.styles';
const FunnelBlock = dynamic(() => import('@components/Funnel/FunnelBlock'));
const SendArt = dynamic(() => import('./Icons/SendArt'));
const CreateOnlineTemplate = dynamic(() => import('./Icons/CreateOnlineTemplate'));
const HireDesigner = dynamic(() => import('./Icons/HireDesigner'));
const Cimpress3d = dynamic(() => import('./Icons/Cimpress3d'));

const ArtCreationModal = dynamic(() => import('../Modals/ArtCreation'));

const generateSourceList = (sources) => Object.keys(sources).filter((sourceId) => sources[sourceId]);

const Sources = ({
  onSourceChange,
  finalProduct,
  source,
  artCreationRules,
  order,
  combId,
  templateId,
  isSourceAutoSelected,
  isOpenArt,
  handleArtModal,
}) => {
  const dispatch = useDispatch();
  const { selectedSource, enabledSources } = source;
  const [currentSource, setSelectedSource] = useState(selectedSource);
  const [isReselection, setIsReselection] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { defaultCombination, id: fpId } = finalProduct;
  const { locale } = useSelector((state) => state.locale);

  const t = useMemo(
    () => ({
      ...locale.translate.page.product_settings.source,
      upload: {
        ...locale.json.page.funnel.settings.send_final_art,
      },
      template: {
        ...locale.json.page.funnel.settings.create_template,
      },
      cimpress_3d: {
        TITLE: 'Criar um Design 3D',
        DESCRIPTION: 'O nosso estúdio de design 3D simplifica a criação da sua embalagem, tornando tudo mais fácil',
        DESCRIPTION_FULL:
          'Ferramenta de design 3D perfeita para profissionais, empreendedores e iniciantes. ' +
          'Com uma interface intuitiva, permite criar embalagens visualmente atraentes, tornando o design 3D acessível a todos. ' +
          'Transforme ideias de embalagem com facilidade e estilo!',
      },
      art_creation: {
        ...locale.json.page.funnel.settings.hire_creation,
      },
    }),
    [locale],
  );

  const handleSourceChange = useCallback(
    (sourceId, combinationId) => {
      dispatch(settingsSourceSelect(fpId, sourceId));
      setSelectedSource(sourceId);
      setIsReselection(false);
      onSourceChange(sourceId, combinationId);
    },
    [dispatch, fpId, onSourceChange],
  );

  const mount = useCallback(() => {
    if (isMounted) return;

    if (combId) {
      const combination = defaultCombination.find((comb) => parseInt(comb.id, 10) === parseInt(combId, 10));

      if (combination) {
        handleSourceChange(combination.source, combination.position || 0);
        return;
      }
    }

    if (templateId) {
      handleSourceChange('template');
      return;
    }

    if (currentSource && !(isSourceAutoSelected && selectedSource === 'art_creation')) {
      handleSourceChange(currentSource);
    }

    setIsMounted(true);
  }, [
    combId,
    currentSource,
    defaultCombination,
    handleSourceChange,
    isMounted,
    isSourceAutoSelected,
    selectedSource,
    templateId,
  ]);

  useEffect(() => {
    mount();
  }, [mount]);

  const handleArtModalOpen = useCallback(() => {
    handleArtModal(!isOpenArt);
  }, [handleArtModal, isOpenArt]);

  const handleModal = useCallback(() => {
    handleArtModal(!isOpenArt);
  }, [handleArtModal, isOpenArt]);

  const handleReselection = useCallback(() => {
    setIsReselection(true);
  }, []);

  const handleArtModalSubmit = useCallback(() => {
    handleArtModal(false, true);
    handleSourceChange('art_creation');
  }, [handleArtModal, handleSourceChange]);

  const handleSelectSource = useCallback(
    (ev) => {
      handleSourceChange(ev.currentTarget.value);
    },
    [handleSourceChange],
  );

  const sourceImage = {
    upload: <SendArt />,
    template: <CreateOnlineTemplate />,
    art_creation: <HireDesigner />,
    cimpress_3d: <Cimpress3d />,
  };
  const renderSelectedBlock = () => (
    <S.SelectedContainer>
      <S.SelectedWarp>
        <S.SelectedSource>
          <S.SelectedSourceImage>{sourceImage[currentSource]}</S.SelectedSourceImage>
          <S.SelectedSourceTitle>{t[currentSource].TITLE}</S.SelectedSourceTitle>
          {currentSource === 'cimpress_3d' && (
            <S.TagBadge kind="filled" color="danger" margin="0 0 1rem">
              Novo
            </S.TagBadge>
          )}
        </S.SelectedSource>

        <S.SelectedInfoText>
          <S.ArtPrice>
            {currentSource === 'cimpress_3d' && t[currentSource].DESCRIPTION_FULL}
            {currentSource !== 'cimpress_3d' &&
              vsprintf(t[currentSource].DESCRIPTION, formatMoney(finalProduct.product_montagemarte_price))}
          </S.ArtPrice>

          {selectedSource === 'art_creation' && (
            <S.ArtDeadline>
              <S.ArtDeadlineIcon>
                <Icon name="AlertTriangleOutline" />
              </S.ArtDeadlineIcon>

              <Text size="sm" padding="0.75rem">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: artCreationRules.teaser }} />
              </Text>
            </S.ArtDeadline>
          )}
        </S.SelectedInfoText>
      </S.SelectedWarp>
      <S.RefreshButton type="button" onClick={handleReselection}>
        <Icon name="Refresh" size="lg" /> Alterar forma de criar o produto
      </S.RefreshButton>
    </S.SelectedContainer>
  );
  const renderBlock = (sourceId) => (
    <S.WrapSourceOption key={sourceId}>
      <S.SourceOption
        onKeyUp={({ target, code }) => code === 'Enter' && target.querySelector('input').click()}
        htmlFor={`config-${sourceId}`}
      >
        {sourceImage[sourceId]}
        <S.WarpInput>
          <div>
            <S.Input
              type="radio"
              id={`config-${sourceId}`}
              name={sourceId}
              value={sourceId}
              onChange={sourceId === 'art_creation' ? handleArtModalOpen : handleSelectSource}
            />
            <Icon name="CheckmarkOutline" size="sm" />
          </div>
          <p>{t[sourceId].TITLE}</p>
        </S.WarpInput>
        {sourceId === 'cimpress_3d' && (
          <S.TagBadgeMobile kind="filled" color="danger" margin="0 0 0.5rem">
            Novo
          </S.TagBadgeMobile>
        )}
      </S.SourceOption>
      <Text size="sm" color="weak" padding="0.5rem 0 1.5rem">
        {sourceId === 'art_creation' && (
          <span>
            {vsprintf(t[sourceId].DESCRIPTION, ' ')}
            <S.TextWithPlusIconArtCreation size="sm" color="weak" padding="0.5rem 0 1.5rem">
              <b>+</b> {formatMoney(finalProduct.product_montagemarte_price)}
            </S.TextWithPlusIconArtCreation>
          </span>
        )}
        {sourceId !== 'art_creation' && t[sourceId].DESCRIPTION}
      </Text>
    </S.WrapSourceOption>
  );

  let BlockSource = (
    <Row isFull>
      {generateSourceList(enabledSources).length === 0 && (
        <Col xs={12}>
          <Text color="danger" size="lg" weight="bold" align="center" margin="2rem 0 4rem">
            Nenhuma forma de opção foi adicionada, por favor, tente novamente.
          </Text>
        </Col>
      )}

      {generateSourceList(enabledSources).length > 0 &&
        generateSourceList(enabledSources).map((sourceId) => renderBlock(sourceId))}
    </Row>
  );

  if (currentSource && !isReselection && !(isSourceAutoSelected && currentSource === 'art_creation')) {
    BlockSource = renderSelectedBlock();
  }

  return (
    <FunnelBlock title={t.TITLE} order={order} isComplete={!!currentSource} infos={t.more_info.TEXT}>
      {BlockSource}
      {isOpenArt && (
        <ArtCreationModal artCreationRules={artCreationRules} onClose={handleModal} onSubmit={handleArtModalSubmit} />
      )}
    </FunnelBlock>
  );
};

Sources.propTypes = {
  handleArtModal: PropTypes.func.isRequired,
  onSourceChange: PropTypes.func.isRequired,
  finalProduct: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  artCreationRules: PropTypes.object.isRequired,
  order: PropTypes.number.isRequired,
  combId: PropTypes.string,
  templateId: PropTypes.string,
  isSourceAutoSelected: PropTypes.bool,
  isOpenArt: PropTypes.bool,
};
export default memo(Sources);
