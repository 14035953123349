import React, { memo, useState } from 'react';

import { Modal } from '@printi/printi-components';
import PropTypes from 'prop-types';
import { sprintf } from 'sprintf-js';

import * as S from './Modal.styles';

const brlNumber = (value) => {
  const newValue = value.replace('.', ',');
  return newValue;
};

const usNumber = (value) => {
  const newValue = parseFloat(value.replace(',', '.'));
  return newValue;
};

const CustomFormatModal = ({ onClose, data, locale, onSubmit, custom }) => {
  const { limits } = data;
  const [state, setState] = useState({
    hasErrorWidth: true,
    hasErrorHeight: true,
    errorMsgWidth: '',
    errorMsgHeight: '',
    qtyWidth: null,
    qtyHeight: null,
  });

  const handleResponseWidth = (e) => {
    const { minWidth, maxWidth } = limits;
    const qty = usNumber(e.currentTarget.value);

    setState({
      ...state,
      hasErrorWidth: false,
      errorMsgWidth: '',
      qtyWidth: qty,
    });

    if (!qty) {
      setState({
        ...state,
        hasErrorWidth: true,
        errorMsgWidth: 'Insira um valor',
        qtyHeight: qty,
      });
    }

    if (qty < minWidth) {
      setState({
        ...state,
        hasErrorWidth: true,
        errorMsgWidth: `Insira um valor maior que ${brlNumber(minWidth)}`,
        qtyWidth: qty,
      });
    }

    if (qty > maxWidth) {
      setState({
        ...state,
        hasErrorWidth: true,
        errorMsgWidth: `Insira um valor menor que ${brlNumber(maxWidth)}`,
        qtyWidth: qty,
      });
    }

    if (!!qty && (qty < parseFloat(limits.min_width, 10) || qty > parseFloat(limits.max_width, 10))) {
      setState({
        ...state,
        hasErrorWidth: true,
        errorMsgWidth: sprintf(
          'A %s inserida é inválida. O valor mínimo permitido é de %s cm e o máximo é de %s cm.',
          locale.modal.format.WIDTH,
          brlNumber(limits.min_width),
          brlNumber(limits.max_width),
        ),
        qtyWidth: qty,
      });
    }
  };

  const handleResponseHeight = (e) => {
    const { minHeight, maxHeight } = limits;
    const qty = usNumber(e.currentTarget.value);

    setState({
      ...state,
      hasErrorHeight: false,
      errorMsgHeight: '',
      qtyHeight: qty,
    });

    if (!qty) {
      setState({
        ...state,
        hasErrorHeight: true,
        errorMsgHeight: 'Insira um valor válido',
        qtyHeight: qty,
      });
    }

    if (qty < minHeight) {
      setState({
        ...state,
        hasErrorHeight: true,
        errorMsgHeight: `Insira um valor maior que ${brlNumber(minHeight)}`,
        qtyHeight: qty,
      });
    }

    if (qty > maxHeight) {
      setState({
        ...state,
        hasErrorHeight: true,
        errorMsgHeight: `Insira um valor menor que ${brlNumber(maxHeight)}`,
        qtyHeight: qty,
      });
    }
    if (!!qty && (qty < parseFloat(limits.min_height, 10) || qty > parseFloat(limits.max_height, 10))) {
      setState({
        ...state,
        hasErrorHeight: true,
        errorMsgHeight: sprintf(
          'A %s inserida é inválida. O valor mínimo permitido é de %s cm e o máximo é de %s cm.',
          locale.modal.format.HEIGHT,
          brlNumber(limits.min_height),
          brlNumber(limits.max_height),
        ),

        qtyHeight: qty,
      });
    }
  };

  const isValid = () =>
    !!state.qtyWidth &&
    !!state.qtyHeight &&
    state.qtyWidth >= parseFloat(limits.min_width, 10) &&
    state.qtyWidth <= parseFloat(limits.max_width, 10) &&
    state.qtyHeight >= parseFloat(limits.min_height, 10) &&
    state.qtyHeight <= parseFloat(limits.max_height, 10);

  const handleSubmit = () => {
    const { qtyWidth, qtyHeight } = state;
    onSubmit(`${qtyWidth}/${qtyHeight}`, data.attribute, data.productPart);
    onClose();
  };

  return (
    <Modal
      size="small"
      close={onClose}
      infos={{ title: locale.modal.format.TITLE }}
      isDisabled={state.hasErrorWidth || state.hasErrorHeight || !isValid()}
      isLoading={custom.isRunning}
      error={custom.error}
      func={handleSubmit}
      buttonName="Confirmar"
    >
      <S.StyledInput
        isBlock
        label="Largura (cm)"
        type="text"
        hasMask
        rightIcon={!state.hasErrorWidth && 'CheckmarkCircle2Outline'}
        options={{ numeral: true, numeralDecimalMark: ',' }}
        onChange={handleResponseWidth}
        hasError={state.hasErrorWidth && Boolean(state.errorMsgWidth)}
        error={state.errorMsgWidth}
        removeErrorSpace
      />
      <S.StyledInput
        isBlock
        label="Altura (cm)"
        type="text"
        rightIcon={!state.hasErrorHeight && 'CheckmarkCircle2Outline'}
        onChange={handleResponseHeight}
        hasError={state.hasErrorHeight && Boolean(state.errorMsgHeight)}
        error={state.errorMsgHeight}
        removeErrorSpace
      />
    </Modal>
  );
};

CustomFormatModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  locale: PropTypes.object,
  custom: PropTypes.object,
};

export default memo(CustomFormatModal);
