import { theme } from '@printi/printi-components';
import styled, { css } from 'styled-components';
const { breakpoint, space, color, borders, font } = theme;

export const BlockButtons = styled.div`
  display: ${(p) => (p.hidden ? 'none' : 'flex')};
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: ${space.md};

  @media (min-width: ${breakpoint.sm}) {
    flex-wrap: nowrap;
    margin-bottom: ${space.lg};
  }
`;

export const ButtonDeliveryMethod = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${space.xl3};
  padding: ${space.md};
  appearance: none;
  border: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  width: 100%;
  margin-bottom: ${space.md};
  outline: 0;
  font-size: ${font.size.small};
  line-height: ${font.lineHeight.root};
  font-weight: ${font.weight.bold};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px ${color.grayscale.black};
  }

  ${(p) =>
    p.checked &&
    css`
      border-color: ${color.primary.pure};
      background-color: ${color.primary.pale};
    `}

  @media (min-width: ${breakpoint.sm}) {
    width: 49%;
    margin-bottom: 0;
  }
`;
