/* eslint-disable react/no-danger */
import React, { memo } from 'react';

import { Text, Col } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './MainContentLP.styles';

const MainContentLP = ({ content }) => {
  const { main_title, content_title_1, content_1, content_title_2, content_2 } = content;

  return (
    <S.ContentRow isFull>
      <Col>
        {main_title && (
          <Text as="h2" weight="bold" size="xxlg">
            {main_title}
          </Text>
        )}
        {content_1 && (
          <S.DetailsBox>
            {content_title_1 && (
              <Text size="xlg" weight="bold" margin="0 0 0.75rem">
                {content_title_1}
              </Text>
            )}
            <S.TextContent dangerouslySetInnerHTML={{ __html: content_1 }} />
          </S.DetailsBox>
        )}
        {content_2 && (
          <S.DetailsBox>
            {content_title_2 && (
              <Text size="xlg" weight="bold" margin="0 0 0.75rem">
                {content_title_2}
              </Text>
            )}
            <S.TextContent dangerouslySetInnerHTML={{ __html: content_2 }} />
          </S.DetailsBox>
        )}
      </Col>
    </S.ContentRow>
  );
};

MainContentLP.propTypes = {
  content: PropTypes.shape({
    main_title: PropTypes.string,
    content_title_1: PropTypes.string,
    content_1: PropTypes.string,
    content_title_2: PropTypes.string,
    content_2: PropTypes.string,
  }).isRequired,
};

export default memo(MainContentLP);
