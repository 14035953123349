import React, { memo, useState } from 'react';

import { Col, Text } from '@printi/printi-components';
import dynamic from 'next/dynamic';

import * as S from './TextVideo.styles';
const ModalVideo = dynamic(() => import('./ModalVideo'));

const TextVideo = () => {
  const [modalVideoIsOpen, setModalVideoIsOpen] = useState(false);

  return (
    <>
      <S.TextVideoRow>
        <Col xs={12} lg={6}>
          <Text as="h3" size="xlg" weight="bold" transform="uppercase" margin="0 0 1rem">
            Parque Gráfico
          </Text>
          <Text size="xlg" weight="bold" margin="0 0 1rem">
            Nossa gráfica produz impressão offset de alta qualidade!
          </Text>
          <Text margin="0 0 1rem">
            Produzimos altas tiragens e grandes formatos com os preços mais baixos do mercado. Veja nosso parque gráfico
            ao lado com seus próprios olhos.
          </Text>
          <S.List>
            <S.ListItem>Maquinário avançado;</S.ListItem>
            <S.ListItem>Impressão offset e digital;</S.ListItem>
            <S.ListItem>Grandes formatos;</S.ListItem>
            <S.ListItem>Altas tiragens;</S.ListItem>
            <S.ListItem last>Variedade de acabamentos.</S.ListItem>
          </S.List>
        </Col>

        <Col xs={12} lg={6}>
          <S.ImageVideo
            onClick={() => setModalVideoIsOpen(true)}
            role="button"
            src="/images/parque.png"
            alt="Foto do Parque Gráfico"
            width="576"
            height="325"
          />
        </Col>
      </S.TextVideoRow>

      {modalVideoIsOpen && <ModalVideo setModalVideoIsOpen={setModalVideoIsOpen} />}
    </>
  );
};

export default memo(TextVideo);
