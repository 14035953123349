import React, { memo, useCallback, useEffect, useState } from 'react';

import { Modal, SelectCard } from '@printi/printi-components';
import PropTypes from 'prop-types';

import PickupCardDisplay from './PickupCardDisplay';
import * as S from './PickupPlace.style';

const getSelected = (data, state, cityName, nb) => {
  if (nb === 'all') {
    return data.filter(
      (i) =>
        i.stateCode.localeCompare(state, undefined, { sensitivity: 'base' }) === 0 &&
        i.city.localeCompare(cityName, undefined, { sensitivity: 'base' }) === 0,
    );
  }
  return data.filter(
    (i) =>
      i.stateCode.localeCompare(state, undefined, { sensitivity: 'base' }) === 0 &&
      i.city.localeCompare(cityName, undefined, { sensitivity: 'base' }) === 0 &&
      i.neighborhood.localeCompare(nb, undefined, { sensitivity: 'base' }) === 0,
  );
};

const addressReduce = (acc, item, i) => {
  if (i === 0) {
    const res = {};
    res[item.stateCode] = {};
    res[item.stateCode][item.city] = [item.neighborhood];
    return res;
  }

  if (!acc[item.stateCode]) acc[item.stateCode] = {};
  if (!acc[item.stateCode][item.city]) {
    acc[item.stateCode][item.city] = [item.neighborhood];
    return acc;
  }

  const index = acc[item.stateCode][item.city].indexOf(item.neighborhood);
  if (index !== -1) {
    return acc;
  }

  acc[item.stateCode][item.city].push(item.neighborhood);
  return acc;
};

const PickupPlaceModal = ({ callback, close, pickupPlaces, pickupPlaceAddr }) => {
  const [selects, setSelects] = useState('');
  const [selected, setSelected] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [nb, setNb] = useState('');
  const [pickupPlaceId, setPickupPlaceId] = useState('');

  const pickupPlacesUnifier = (oldPlaces) => {
    if (!oldPlaces) return oldPlaces;

    const places = oldPlaces;

    const states = Object.keys(places);

    states.forEach((stateA, indexStateA) => {
      states.forEach((stateB, indexStateB) => {
        if (
          indexStateA !== indexStateB &&
          // localeCompare with sensitivity 'base' is used to make an insensitive string comparison, if result is zero it means the compared strings are equal
          stateA.localeCompare(stateB, undefined, { sensitivity: 'base' }) === 0 &&
          places[stateA] &&
          places[stateB]
        ) {
          const citiesB = Object.keys(places[stateB]);

          citiesB.forEach((cityName) => {
            if (!places[stateA][cityName]) {
              places[stateA][cityName] = places[stateB][cityName];
            }
          });

          delete places[stateB];
        }
      });

      if (places[stateA]) {
        const citiesA = Object.keys(places[stateA]);

        citiesA.forEach((cityA, indexA) => {
          const neighborhoods = places[stateA][cityA];

          citiesA.forEach((cityB, indexB) => {
            if (
              places[stateA][cityA] &&
              places[stateA][cityB] &&
              indexA !== indexB &&
              cityA.localeCompare(cityB, undefined, { sensitivity: 'base' }) === 0
            ) {
              places[stateA][cityB].forEach((pickupPlace) => {
                if (!places[stateA][cityA].includes(pickupPlace)) {
                  places[stateA][cityA].push(pickupPlace);
                }
              });
              delete places[stateA][cityB];
            }
          });

          if (neighborhoods && neighborhoods.length > 1) {
            neighborhoods.forEach((neighborhoodA, indexNeighborhoodA) => {
              neighborhoods.forEach((neighborhoodB, indexNeighborhoodB) => {
                if (
                  neighborhoodA.localeCompare(neighborhoodB, undefined, { sensitivity: 'base' }) === 0 &&
                  indexNeighborhoodA !== indexNeighborhoodB
                ) {
                  delete neighborhoods[indexNeighborhoodB];
                }
              });
            });
          }
        });
      }
    });
    return places;
  };

  useEffect(() => {
    const selectsPickup = pickupPlacesUnifier(pickupPlaces.reduce(addressReduce, {}));
    const selectedState = selectsPickup[pickupPlaceAddr.stateCode] ? pickupPlaceAddr.stateCode : 'SP';
    const selectedCity = selectsPickup[selectedState][pickupPlaceAddr.city]
      ? pickupPlaceAddr.city
      : Object.keys(selectsPickup[selectedState])[0];
    const nbIndex = selectsPickup[selectedState][selectedCity].indexOf(pickupPlaceAddr.neighborhood);

    const selectedNb = nbIndex !== -1 ? pickupPlaceAddr.neighborhood : selectsPickup[selectedState][selectedCity][0];
    setSelects(selectsPickup);
    setState(selectedState);
    setCity(selectedCity);
    setNb(selectedNb);
    setSelected(getSelected(pickupPlaces, selectedState, selectedCity, selectedNb));
  }, [pickupPlaceAddr, pickupPlaces]);

  const handleCallbackFunc = useCallback(() => {
    callback(pickupPlaceId);
    close();
  }, [callback, close, pickupPlaceId]);

  const handlePickupChange = (event) => {
    setPickupPlaceId(event.currentTarget.value);
  };

  const handleChangeState = (event) => {
    const stateCode = event.currentTarget.value;
    const selectedCity = Object.keys(selects[stateCode])[0];
    const selectedNb = selects[stateCode][selectedCity][0];
    setState(stateCode);
    setCity(selectedCity);
    setNb(selectedNb);
    setSelected(getSelected(pickupPlaces, stateCode, selectedCity, selectedNb));
  };

  const handleChangeCity = (e) => {
    const cityCode = e.currentTarget.value;
    const nbCode = selects[state][cityCode][0];
    setCity(cityCode);
    setNb(nbCode);
    setSelected(getSelected(pickupPlaces, state, cityCode, nbCode));
  };

  const handleChangeNb = (e) => {
    const nbCode = e.currentTarget.value;
    setNb(nbCode);
    setSelected(getSelected(pickupPlaces, state, city, nbCode));
  };

  const isValid = !!state && !!nb && !!city;
  const isCity = !!state;
  const isNb = !!state && !!city;
  const qty = selected.length;
  return (
    <Modal
      size="large"
      infos={{
        title: 'Retirada',
        subtitle: 'Selecione o Estado, Cidade e Bairro para ver os pontos de retirada:',
      }}
      close={close}
      func={handleCallbackFunc}
      buttonName="Confirmar"
      isDisabled={!isValid || !pickupPlaceId}
    >
      <S.Wrapper>
        <S.SelectSize onChange={handleChangeState} value={state} data-optimum="select-uf">
          {Object.keys(selects)
            .sort()
            .map((st) => (
              <option value={st} key={`select-${st}`}>
                {st}
              </option>
            ))}
        </S.SelectSize>{' '}
        <S.SelectSize onChange={handleChangeCity} value={city} data-optimum="select-city">
          {isCity &&
            Object.keys(selects[state])
              .sort()
              .map((ct) => (
                <option value={ct} key={`select-${ct}`}>
                  {ct}
                </option>
              ))}
        </S.SelectSize>{' '}
        <S.SelectSize onChange={handleChangeNb} value={nb} data-optimum="select-neighborhood">
          {isNb && (
            <option value="all" key="select-all">
              Todos
            </option>
          )}
          {isNb &&
            selects[state][city].sort().map((nbh) => (
              <option value={nbh} key={`select-${nbh}`}>
                {nbh}
              </option>
            ))}
        </S.SelectSize>
      </S.Wrapper>

      <S.AlertStyle kind="warning">
        <strong>Atenção!</strong> Você deve se dirigir ao ponto de retirada somente após o recebimento do e-mail de
        autorização para retirar.
      </S.AlertStyle>

      <p>
        Encontramos
        {` ${qty} `}
        ponto
        {qty > 1 ? 's' : ''} de retirada.
      </p>

      <S.WrapperUl>
        {selected &&
          selected.map((i) => (
            <li key={`selected-${i.id}`}>
              <SelectCard
                name="select-pickup-place"
                value={i.id}
                onChange={handlePickupChange}
                data-optimum="pickup-place"
              >
                <PickupCardDisplay
                  message={i.freeText}
                  neighborhood={i.neighborhood}
                  receiverName={i.receiverName}
                  street={i.street}
                  number={i.number}
                  additionalAddress={i.additionalAddress}
                  city={i.city}
                  state={i.stateCode}
                  name={i.name}
                />
              </SelectCard>
            </li>
          ))}
      </S.WrapperUl>
    </Modal>
  );
};

PickupPlaceModal.propTypes = {
  callback: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  pickupPlaces: PropTypes.array.isRequired,
  pickupPlaceAddr: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default memo(PickupPlaceModal);
