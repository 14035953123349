import React from 'react';

const SendArt = () => (
  <svg width="245" height="230" viewBox="0 0 245 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="245" height="230" fill="white" />
    <rect width="245" height="230" fill="#DBDBDB" />
    <path
      d="M191.603 90.9499C179.877 83.5725 145.632 66.5084 137.928 60.7961C130.224 55.0959 98.2198 35.6618 70.6251 64.0534C43.0425 92.457 31.2802 101.062 44.3144 131.714C57.3486 162.366 103.295 182.809 135.263 181.448C167.231 180.087 189.205 177.632 202.966 133.987C207.993 109.983 199.089 95.6657 191.603 90.9499Z"
      fill="#E5F4FD"
    />
    <path
      d="M210.72 196.869C210.72 199.009 163.007 200.737 115.585 200.737C68.1632 200.737 39 199.009 39 196.869C39 194.728 68.1632 193 115.585 193C163.007 193 210.72 194.742 210.72 196.869Z"
      fill="#979797"
      fillOpacity="0.64"
    />
    <path
      d="M80.1159 55.108C76.1305 54.8649 72.1088 54.7434 68.0387 54.7434C63.9685 54.7434 59.9105 54.8649 55.8645 55.108C55.0529 55.1566 54.3261 55.8251 54.2171 56.6273L51.54 75.7454C51.322 77.2767 52.5091 78.638 54.0475 78.638C54.0475 78.638 54.2655 78.638 54.3382 78.638C57.9238 78.7717 60.6009 82.0411 60.3344 85.6386C59.9226 91.1686 55.3315 95.4225 49.9047 95.4225C49.2626 95.4225 48.7175 95.8965 48.6206 96.5407L48.6085 96.6257C48.5237 97.2213 48.8508 97.7804 49.408 98.0113C52.9209 99.4455 56.8942 99.6764 60.7221 98.3759C70.5341 95.07 77.1238 86.4529 78.529 76.6326L81.3272 56.6151C81.4484 55.8251 80.9154 55.1566 80.1159 55.108Z"
      fill="#0E55DE"
    />
    <path
      d="M119.085 111.879H18.0823C15.8291 111.879 14 110.044 14 107.783V37.0959C14 34.8352 15.8291 33 18.0823 33H119.085C121.338 33 123.168 34.8352 123.168 37.0959V107.783C123.168 110.044 121.338 111.879 119.085 111.879ZM18.0823 34.5071C16.6529 34.5071 15.5021 35.6739 15.5021 37.0959V107.783C15.5021 109.217 16.665 110.372 18.0823 110.372H119.085C120.515 110.372 121.665 109.205 121.665 107.783V37.0959C121.665 35.6617 120.503 34.5071 119.085 34.5071H18.0823V34.5071Z"
      fill="white"
    />
    <path
      d="M122.417 41.7266H14.739V37.1081C14.739 35.2607 16.229 33.7657 18.0702 33.7657H119.073C120.914 33.7657 122.404 35.2607 122.404 37.1081L122.417 41.7266Z"
      fill="white"
    />
    <path
      d="M21.3289 37.3997C21.3289 38.2261 20.6626 38.9068 19.8268 38.9068C18.991 38.9068 18.3247 38.2383 18.3247 37.3997C18.3247 36.5732 18.991 35.8926 19.8268 35.8926C20.6626 35.8926 21.3289 36.5732 21.3289 37.3997Z"
      fill="#7F25FA"
    />
    <path
      d="M25.823 37.3997C25.823 38.2261 25.1568 38.9068 24.3209 38.9068C23.4851 38.9068 22.8188 38.2383 22.8188 37.3997C22.8188 36.5732 23.4851 35.8926 24.3209 35.8926C25.1568 35.8926 25.823 36.5732 25.823 37.3997Z"
      fill="#B16CFB"
    />
    <path
      d="M30.3291 37.3997C30.3291 38.2261 29.6629 38.9068 28.827 38.9068C28.0033 38.9068 27.325 38.2383 27.325 37.3997C27.325 36.5732 27.9912 35.8926 28.827 35.8926C29.6508 35.8926 30.3291 36.5732 30.3291 37.3997Z"
      fill="#B16CFB"
    />
    <path d="M170.629 50.4652H131.587V68.1005H170.629V50.4652Z" fill="#0E55DE" />
    <g opacity="0.7">
      <g opacity="0.7">
        <path opacity="0.7" d="M149.878 53.8197H136.008V55.2903H149.878V53.8197Z" fill="white" />
      </g>
    </g>
    <g opacity="0.7">
      <g opacity="0.7">
        <path opacity="0.7" d="M156.661 56.7488H147.479V58.2194H156.661V56.7488Z" fill="white" />
      </g>
    </g>
    <g opacity="0.7">
      <g opacity="0.7">
        <path opacity="0.7" d="M146.801 56.7488H140.526V58.2194H146.801V56.7488Z" fill="white" />
      </g>
    </g>
    <g opacity="0.7">
      <g opacity="0.7">
        <path opacity="0.7" d="M161.192 59.6779H140.526V61.1485H161.192V59.6779Z" fill="white" />
      </g>
    </g>
    <g opacity="0.7">
      <g opacity="0.7">
        <path opacity="0.7" d="M151.477 62.6191H140.526V64.0898H151.477V62.6191Z" fill="white" />
      </g>
    </g>
    <path d="M96.8811 119.609H60.6494V135.968H96.8811V119.609Z" fill="#0E55DE" />
    <path d="M96.8811 119.609H60.6494V135.968H96.8811V119.609Z" fill="#0E55DE" />
    <g opacity="0.7">
      <g opacity="0.7">
        <path
          opacity="0.7"
          d="M71.794 127.788L76.2397 129.405V131.167L69.7468 128.663V126.889L76.2397 124.373V126.148L71.794 127.788Z"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M87.8444 128.663L81.3516 131.167V129.405L85.7972 127.788L81.3516 126.148V124.373L87.8444 126.889V128.663Z"
          fill="white"
        />
      </g>
    </g>
    <g opacity="0.7">
      <g opacity="0.7">
        <path opacity="0.7" d="M77.0508 131.605H78.565L80.1034 123.96H78.6013L77.0508 131.605Z" fill="white" />
      </g>
    </g>
    <path
      d="M99.9333 102.921C93.6827 102.921 88.595 97.8167 88.595 91.5452C88.595 85.2738 93.6827 80.1692 99.9333 80.1692C106.184 80.1692 111.272 85.2738 111.272 91.5452C111.272 97.8167 106.184 102.921 99.9333 102.921ZM99.9333 81.579C94.4579 81.579 90.0123 86.0517 90.0123 91.5331C90.0123 97.0267 94.4579 101.487 99.9333 101.487C105.409 101.487 109.854 97.0145 109.854 91.5331C109.854 86.0517 105.396 81.579 99.9333 81.579Z"
      fill="#777475"
    />
    <path
      d="M104.391 97.4763H96.9047C96.8684 97.452 96.8199 97.4763 96.7715 97.4642C96.4807 97.4642 96.19 97.1239 96.1658 96.7593C96.1537 96.5405 96.1658 96.3217 96.1658 96.103C96.1658 96.0543 96.1658 96.0057 96.2142 95.9693C96.2869 95.9328 96.3596 95.9449 96.4444 95.9449C98.6248 95.9449 100.805 95.9449 102.998 95.9449C103.046 95.9449 103.107 95.9449 103.155 95.9449C103.422 95.9449 103.543 95.8234 103.555 95.556C103.555 95.471 103.579 95.398 103.555 95.313C103.555 94.1219 103.555 92.9308 103.555 91.7519C103.555 91.6911 103.555 91.6182 103.555 91.5574C103.543 91.3629 103.41 91.2292 103.216 91.2171C103.155 91.2171 103.095 91.2171 103.034 91.2171C100.842 91.2171 98.637 91.2171 96.4444 91.2171C96.3596 91.2171 96.2869 91.2292 96.2142 91.1806C96.1658 91.132 96.1779 91.0712 96.1779 91.0105C96.1779 89.4669 96.1779 87.9112 96.1779 86.3677C96.1779 86.3069 96.19 86.2583 96.2021 86.1975C96.2869 85.8086 96.5534 85.5898 96.9532 85.5898C97.8496 85.5898 98.7581 85.5898 99.6545 85.5898C100.454 85.5898 101.241 85.5898 102.041 85.5898C102.077 85.5898 102.126 85.5777 102.162 85.602C102.186 85.6628 102.186 85.7235 102.186 85.7843C102.186 86.4041 102.198 87.0361 102.186 87.656C102.174 88.0935 102.356 88.4338 102.852 88.5311C102.913 88.5554 102.962 88.604 103.01 88.6526C103.676 89.3211 104.355 90.0017 105.021 90.6702C105.069 90.7188 105.13 90.7674 105.142 90.8403C105.154 90.8889 105.154 90.9375 105.154 90.9862C105.154 92.8822 105.154 94.766 105.154 96.6499C105.154 96.9051 105.081 97.1239 104.9 97.294C104.803 97.3913 104.694 97.4399 104.56 97.452C104.488 97.4763 104.427 97.452 104.391 97.4763Z"
      fill="#CAC9C9"
    />
    <path
      d="M96.7834 97.4762C96.7834 97.4641 96.7834 97.4641 96.7834 97.4519C96.8198 97.4519 96.8683 97.4397 96.9046 97.4762H96.7834Z"
      fill="#E9E9E9"
    />
    <path
      d="M104.5 97.4642C104.5 97.4764 104.5 97.4764 104.5 97.4885H104.379C104.415 97.4399 104.464 97.4642 104.5 97.4642Z"
      fill="#E9E9E9"
    />
    <path
      d="M96.2142 95.6289C95.8629 95.6289 95.5116 95.6289 95.1603 95.6289C94.8817 95.6289 94.7363 95.483 94.7363 95.2035C94.7363 94.0002 94.7363 92.8092 94.7363 91.6059C94.7363 91.3264 94.8817 91.1805 95.1603 91.1805C95.5116 91.1805 95.8629 91.1805 96.2142 91.1805C96.2626 91.1805 96.3111 91.1805 96.3596 91.1805C98.6248 91.1805 100.878 91.1805 103.143 91.1805C103.482 91.1805 103.603 91.3142 103.603 91.6545C103.603 92.8213 103.603 93.9881 103.603 95.1549C103.603 95.2156 103.603 95.2764 103.603 95.325C103.555 95.5681 103.446 95.6653 103.204 95.6775C103.167 95.6775 103.119 95.6775 103.083 95.6775C100.878 95.6775 98.6611 95.6775 96.4565 95.6775C96.3596 95.6775 96.2748 95.6896 96.2142 95.6289Z"
      fill="#777475"
    />
    <path
      d="M102.15 85.6383L105.057 88.5552C105.033 88.6403 104.96 88.6282 104.912 88.6282C104.694 88.6282 104.476 88.6282 104.27 88.6282C103.87 88.6282 103.47 88.6282 103.071 88.6282C102.998 88.6282 102.937 88.616 102.877 88.5795C102.441 88.5552 102.138 88.2392 102.138 87.8139C102.138 87.1332 102.138 86.4405 102.138 85.7598C102.126 85.7112 102.114 85.6748 102.15 85.6383Z"
      fill="#231F20"
    />
    <path
      d="M96.2143 95.6289C96.2627 95.6289 96.3112 95.6289 96.3596 95.6289C98.6249 95.6289 100.878 95.6289 103.143 95.6289C103.398 95.6289 103.507 95.556 103.591 95.3251C103.591 95.4223 103.591 95.5195 103.591 95.6168C103.591 95.8477 103.434 96.0057 103.204 96.0057C100.866 96.0057 98.5279 96.0057 96.2021 96.0057C96.2143 95.872 96.2143 95.7505 96.2143 95.6289Z"
      fill="#A7A5A6"
    />
    <path
      d="M102.865 88.5796C103.555 88.5796 104.246 88.5796 104.924 88.5796C104.973 88.5796 105.021 88.5918 105.057 88.5553C105.082 88.5796 105.082 88.6161 105.082 88.6404C105.082 89.3575 105.082 90.0867 105.082 90.8038C105.057 90.7916 105.033 90.7673 105.021 90.7552C104.318 90.0502 103.616 89.3453 102.913 88.6404C102.901 88.6282 102.877 88.6039 102.865 88.5796Z"
      fill="#A7A5A6"
    />
    <path
      d="M98.3342 93.4897C98.3342 93.2223 98.3342 92.9549 98.3342 92.6875C98.3342 92.5538 98.3948 92.4809 98.5159 92.4687C98.8188 92.4566 99.1337 92.4322 99.4366 92.4809C99.9211 92.5659 100.236 92.9913 100.212 93.514C100.188 94.1338 99.8 94.5106 99.1822 94.5106C98.9762 94.5106 98.7703 94.5106 98.5765 94.5106C98.4069 94.5106 98.3463 94.4498 98.3463 94.2675C98.3342 94.0123 98.3342 93.7449 98.3342 93.4897Z"
      fill="#FEFEFE"
    />
    <path
      d="M96.3473 93.4656C96.3473 93.1982 96.3473 92.9308 96.3473 92.6634C96.3473 92.5054 96.42 92.4325 96.5654 92.4325C96.8198 92.4325 97.062 92.4203 97.3164 92.4446C97.7162 92.4932 97.9827 92.8093 97.9463 93.2225C97.91 93.6479 97.6435 93.9031 97.2195 93.9152C97.0862 93.9152 96.953 93.9152 96.8197 93.9152C96.7471 93.9152 96.735 93.9396 96.735 94.0003C96.735 94.1097 96.735 94.2191 96.7228 94.3406C96.7107 94.4622 96.638 94.5229 96.529 94.5108C96.42 94.5108 96.3473 94.4257 96.3352 94.3163C96.3352 94.3042 96.3352 94.3042 96.3352 94.292C96.3473 94.0246 96.3473 93.7451 96.3473 93.4656Z"
      fill="#FEFEFE"
    />
    <path
      d="M101.35 92.4689C101.544 92.4689 101.726 92.4567 101.92 92.481C102.017 92.4932 102.065 92.5418 102.077 92.639C102.089 92.7362 102.041 92.797 101.956 92.8456C101.908 92.8699 101.871 92.8699 101.823 92.8699C101.593 92.8699 101.35 92.8699 101.12 92.8699C101.035 92.8699 101.011 92.8942 101.023 92.9793C101.035 93.0766 101.023 93.1616 101.023 93.2589C101.023 93.3318 101.048 93.3561 101.108 93.3561C101.302 93.3561 101.484 93.3561 101.678 93.3561C101.714 93.3561 101.75 93.3561 101.787 93.3682C101.896 93.3926 101.98 93.4898 101.98 93.5749C101.98 93.6721 101.883 93.745 101.762 93.745C101.556 93.745 101.338 93.7572 101.132 93.745C101.035 93.745 101.011 93.7693 101.023 93.8666C101.035 94.0246 101.023 94.1826 101.023 94.3406C101.023 94.4864 100.951 94.5593 100.842 94.5593C100.721 94.5593 100.636 94.4864 100.636 94.3406C100.636 93.8058 100.636 93.271 100.636 92.7362C100.636 92.5904 100.696 92.5175 100.842 92.5175C100.999 92.4567 101.181 92.4689 101.35 92.4689Z"
      fill="#FEFEFE"
    />
    <path
      d="M98.7219 93.4897C98.7219 93.2953 98.7219 93.113 98.7219 92.9185C98.7219 92.8456 98.734 92.8091 98.8188 92.8213C98.9884 92.8334 99.1701 92.8091 99.3397 92.8334C99.5456 92.8699 99.6789 92.9793 99.7637 93.1737C99.9454 93.5748 99.7516 94.0488 99.3639 94.1339C99.1701 94.1825 98.9763 94.146 98.7825 94.1582C98.7219 94.1582 98.734 94.1096 98.734 94.0731C98.7219 93.8787 98.7219 93.6842 98.7219 93.4897Z"
      fill="#777475"
    />
    <path
      d="M96.7351 93.1495C96.7472 93.0523 96.6867 92.8943 96.7594 92.8214C96.832 92.7484 96.9774 92.8092 97.0985 92.7971C97.1591 92.7971 97.2197 92.7971 97.2681 92.8092C97.4619 92.8335 97.5831 93.0037 97.5709 93.1981C97.5588 93.3804 97.4135 93.5263 97.2197 93.5263C97.0864 93.5263 96.9411 93.5263 96.8078 93.5263C96.7351 93.5263 96.723 93.502 96.723 93.4412C96.7351 93.3683 96.7351 93.2711 96.7351 93.1495Z"
      fill="#777475"
    />
    <path
      d="M172.75 134.038C177.498 134.33 186.22 138.316 187.674 143.287C188.049 145.888 187.54 149.826 186.995 154.955C186.995 154.955 147.638 154.578 147.675 155.307C148.002 151.746 148.583 148.732 149.54 145.293C150.921 140.844 160.212 134.828 163.277 134.233C163.64 134.172 168.716 133.795 172.75 134.038Z"
      fill="#FDC62E"
    />
    <path
      d="M158.444 103.836C157.378 117.655 167.675 129.736 181.448 130.817C195.221 131.887 207.262 121.556 208.34 107.737C209.406 93.9182 199.109 81.8372 185.336 80.7555C171.551 79.6738 159.51 90.0168 158.444 103.836Z"
      fill="#151C28"
    />
    <path
      d="M169.285 103.471C164.755 101.94 158.286 102.377 156.36 110.058C155.016 115.443 153.174 124.813 156.506 126.284C157.983 126.94 163.834 126.855 163.834 126.855L161.509 135.497C165.991 138.316 172.762 134.075 172.762 134.075L171.732 124.655C171.745 124.643 184.234 108.527 169.285 103.471Z"
      fill="#4F292A"
    />
    <path
      d="M178.31 103.751C186.305 115.552 177.111 122.966 171.745 124.643C170.049 122.93 168.874 120.122 168.874 120.122C168.68 119.32 171.563 117.558 170.533 115.139C169.818 113.462 168.377 112.66 167.093 113.851C166.875 114.057 166.451 113.851 166.669 112.866C167.02 111.274 167.493 108.904 165.991 106.425C164.44 103.86 162.768 102.535 160.249 103.787C163.156 99.1565 174.555 99.6306 178.31 103.751Z"
      fill="#151C28"
    />
    <path d="M150.219 143.871L144.186 155.149L151.527 154.991L150.219 143.871Z" fill="#FDC62E" />
    <path
      d="M182.49 137.538C182.49 137.538 190.751 141.671 194.215 152.804C194.433 153.497 194.652 154.226 194.857 154.967L185.518 155.016L182.49 137.538Z"
      fill="#FDC62E"
    />
    <path d="M155.343 119.405L153.259 118.056L155.961 112.55L155.343 119.405Z" fill="#482223" />
    <path
      d="M222.125 155.879H121.122C118.869 155.879 117.04 154.044 117.04 151.783V81.0959C117.04 78.8352 118.869 77 121.122 77H222.125C224.378 77 226.207 78.8352 226.207 81.0959V151.783C226.219 154.044 224.378 155.879 222.125 155.879ZM121.122 78.5071C119.692 78.5071 118.542 79.6739 118.542 81.0959V151.783C118.542 153.217 119.705 154.372 121.122 154.372H222.125C223.554 154.372 224.705 153.205 224.705 151.783V81.0959C224.705 79.6617 223.542 78.5071 222.125 78.5071H121.122Z"
      fill="white"
    />
    <path
      d="M225.468 85.7264H117.791V81.1079C117.791 79.2606 119.281 77.7656 121.122 77.7656H222.125C223.966 77.7656 225.456 79.2606 225.456 81.1079V85.7264H225.468Z"
      fill="white"
    />
    <path
      d="M124.368 81.3995C124.368 82.226 123.702 82.9066 122.866 82.9066C122.043 82.9066 121.364 82.2382 121.364 81.3995C121.364 80.5731 122.031 79.8925 122.866 79.8925C123.702 79.9046 124.368 80.5731 124.368 81.3995Z"
      fill="#7F25FA"
    />
    <path
      d="M128.875 81.3995C128.875 82.226 128.209 82.9066 127.373 82.9066C126.537 82.9066 125.871 82.2382 125.871 81.3995C125.871 80.5731 126.537 79.8925 127.373 79.8925C128.209 79.8925 128.875 80.5731 128.875 81.3995Z"
      fill="#B16CFB"
    />
    <path
      d="M133.369 81.3995C133.369 82.226 132.702 82.9066 131.867 82.9066C131.043 82.9066 130.365 82.2382 130.365 81.3995C130.365 80.5731 131.031 79.8925 131.867 79.8925C132.702 79.9046 133.369 80.5731 133.369 81.3995Z"
      fill="#B16CFB"
    />
    <path
      d="M132.024 148.732C131.697 149.243 131.031 149.389 130.522 149.06L95.5017 126.564C94.9929 126.235 94.8476 125.567 95.1746 125.056L109.432 102.718C109.759 102.207 110.426 102.061 110.934 102.389C110.934 102.389 128.124 113.437 133.248 116.719C133.95 117.169 139.159 116.136 139.862 116.585C142.793 118.469 147.929 121.763 147.929 121.763C148.438 122.091 148.583 122.759 148.256 123.27L132.024 148.732Z"
      fill="#FDC62E"
    />
    <path
      d="M110.159 119.429C109.832 119.94 109.99 120.608 110.486 120.936L135.501 137.004C136.01 137.332 136.676 137.186 137.003 136.675L145.494 123.367C145.822 122.856 145.664 122.188 145.167 121.872L120.153 105.805C119.656 105.476 118.978 105.634 118.651 106.133L110.159 119.429Z"
      fill="#FFF7E6"
    />
    <path
      d="M95.1868 125.069C94.8598 125.579 95.0051 126.248 95.5139 126.576L130.534 149.073C131.043 149.401 131.709 149.243 132.024 148.745L146.282 126.406C146.609 125.895 146.464 125.227 145.955 124.899C145.955 124.899 128.766 113.851 123.642 110.569C122.939 110.119 121.703 104.93 121.001 104.48C118.069 102.596 112.933 99.3024 112.933 99.3024C112.424 98.9743 111.758 99.1201 111.431 99.6306L95.1868 125.069Z"
      fill="#FDC62E"
    />
    <path
      d="M155.852 147.517L146.294 141.148C146.294 141.148 144.574 134.403 143.35 132.47C142.515 131.145 139.729 128.933 138.844 128.107C137.96 127.28 133.914 125.871 133.914 125.871C133.914 125.871 133.296 127.086 134.326 127.888C134.907 128.35 137.548 129.711 137.548 129.711L140.249 133.673L134.847 133.212L131.516 133.649L129.129 134.366C129.129 134.366 129.42 135.825 130.268 135.837C130.886 135.837 132.582 135.521 132.582 135.521L135.307 135.618L137.911 136.287L134.556 136.578L131.649 136.809L129.347 136.955C129.347 136.955 129.529 138.413 130.474 138.559C131.419 138.705 133.369 138.705 133.369 138.705L136.567 138.462L131.491 140.358C131.491 140.358 132.012 141.367 132.545 141.549C133.805 141.962 137.378 140.115 137.378 140.115C137.378 140.115 139.086 143.348 139.886 144.308C140.698 145.268 142.612 146.8 142.939 146.897C143.278 146.994 148.608 151.637 148.608 151.637C148.608 151.637 153.078 152.719 155.852 147.517Z"
      fill="#4F292A"
    />
    <path
      d="M209.938 151.503C203.688 151.503 198.6 146.399 198.6 140.127C198.6 133.856 203.688 128.751 209.938 128.751C216.189 128.751 221.277 133.856 221.277 140.127C221.289 146.399 216.201 151.503 209.938 151.503ZM209.938 130.173C204.463 130.173 200.017 134.646 200.017 140.127C200.017 145.621 204.475 150.081 209.938 150.081C215.414 150.081 219.859 145.609 219.859 140.127C219.871 134.634 215.414 130.173 209.938 130.173Z"
      fill="#777475"
    />
    <path
      d="M214.408 146.07H206.922C206.886 146.046 206.837 146.07 206.789 146.058C206.498 146.058 206.207 145.718 206.183 145.353C206.171 145.135 206.183 144.916 206.183 144.697C206.183 144.648 206.183 144.6 206.232 144.563C206.304 144.527 206.377 144.539 206.462 144.539C208.642 144.539 210.823 144.539 213.015 144.539C213.064 144.539 213.124 144.539 213.173 144.539C213.439 144.539 213.56 144.418 213.572 144.15C213.572 144.065 213.597 143.992 213.572 143.907C213.572 142.716 213.572 141.525 213.572 140.346C213.572 140.285 213.572 140.212 213.572 140.152C213.56 139.957 213.427 139.823 213.233 139.811C213.173 139.811 213.112 139.811 213.051 139.811C210.859 139.811 208.654 139.811 206.462 139.811C206.377 139.811 206.304 139.823 206.232 139.775C206.183 139.726 206.195 139.665 206.195 139.605C206.195 138.061 206.195 136.505 206.195 134.962C206.195 134.901 206.207 134.852 206.219 134.792C206.304 134.403 206.571 134.184 206.97 134.184C207.867 134.184 208.775 134.184 209.672 134.184C210.471 134.184 211.259 134.184 212.058 134.184C212.095 134.184 212.143 134.172 212.179 134.196C212.204 134.257 212.204 134.318 212.204 134.378C212.204 134.998 212.216 135.63 212.204 136.25C212.191 136.688 212.373 137.028 212.87 137.125C212.93 137.15 212.979 137.198 213.027 137.247C213.694 137.915 214.372 138.596 215.038 139.264C215.087 139.313 215.147 139.362 215.159 139.434C215.171 139.483 215.171 139.532 215.171 139.58C215.171 141.476 215.171 143.36 215.171 145.244C215.171 145.499 215.099 145.718 214.917 145.888C214.82 145.985 214.711 146.034 214.578 146.046C214.493 146.058 214.445 146.046 214.408 146.07Z"
      fill="#CAC9C9"
    />
    <path
      d="M206.801 146.07C206.801 146.058 206.801 146.058 206.801 146.046C206.837 146.046 206.886 146.034 206.922 146.07H206.801Z"
      fill="#E9E9E9"
    />
    <path
      d="M214.517 146.046C214.517 146.058 214.517 146.058 214.517 146.07H214.396C214.433 146.034 214.481 146.046 214.517 146.046Z"
      fill="#E9E9E9"
    />
    <path
      d="M206.22 144.211C205.868 144.211 205.517 144.211 205.166 144.211C204.887 144.211 204.742 144.065 204.742 143.785C204.742 142.582 204.742 141.391 204.742 140.188C204.742 139.908 204.887 139.763 205.166 139.763C205.517 139.763 205.868 139.763 206.22 139.763C206.268 139.763 206.316 139.763 206.365 139.763C208.63 139.763 210.883 139.763 213.149 139.763C213.488 139.763 213.609 139.896 213.609 140.237C213.609 141.403 213.609 142.57 213.609 143.737C213.609 143.798 213.609 143.858 213.609 143.907C213.56 144.15 213.451 144.247 213.209 144.26C213.173 144.26 213.124 144.26 213.088 144.26C210.883 144.26 208.666 144.26 206.462 144.26C206.377 144.26 206.292 144.272 206.22 144.211Z"
      fill="#777475"
    />
    <path
      d="M212.167 134.22L215.075 137.137C215.05 137.222 214.978 137.21 214.929 137.21C214.711 137.21 214.493 137.21 214.287 137.21C213.887 137.21 213.488 137.21 213.088 137.21C213.015 137.21 212.955 137.198 212.894 137.161C212.458 137.137 212.155 136.821 212.155 136.396C212.155 135.715 212.155 135.022 212.155 134.342C212.143 134.305 212.131 134.257 212.167 134.22Z"
      fill="#231F20"
    />
    <path
      d="M206.22 144.211C206.268 144.211 206.317 144.211 206.365 144.211C208.63 144.211 210.883 144.211 213.149 144.211C213.403 144.211 213.512 144.138 213.597 143.907C213.597 144.004 213.597 144.101 213.597 144.199C213.597 144.43 213.439 144.588 213.209 144.588C210.871 144.588 208.533 144.588 206.208 144.588C206.232 144.466 206.22 144.345 206.22 144.211Z"
      fill="#A7A5A6"
    />
    <path
      d="M212.882 137.162C213.572 137.162 214.263 137.162 214.941 137.162C214.99 137.162 215.038 137.174 215.074 137.137C215.099 137.162 215.099 137.198 215.099 137.222C215.099 137.939 215.099 138.669 215.099 139.386C215.074 139.374 215.05 139.349 215.038 139.337C214.335 138.632 213.633 137.927 212.93 137.222C212.906 137.21 212.894 137.186 212.882 137.162Z"
      fill="#A7A5A6"
    />
    <path
      d="M208.351 142.072C208.351 141.804 208.351 141.537 208.351 141.27C208.351 141.136 208.412 141.063 208.533 141.051C208.836 141.039 209.151 141.014 209.454 141.063C209.938 141.148 210.253 141.573 210.229 142.096C210.205 142.716 209.817 143.093 209.199 143.093C208.993 143.093 208.787 143.093 208.594 143.093C208.424 143.093 208.363 143.032 208.363 142.85C208.351 142.594 208.351 142.339 208.351 142.072Z"
      fill="#FEFEFE"
    />
    <path
      d="M206.365 142.06C206.365 141.792 206.365 141.525 206.365 141.257C206.365 141.099 206.438 141.026 206.583 141.026C206.837 141.026 207.08 141.014 207.334 141.039C207.734 141.087 208 141.403 207.964 141.816C207.927 142.242 207.661 142.497 207.237 142.509C207.104 142.509 206.971 142.509 206.837 142.509C206.765 142.509 206.753 142.534 206.753 142.594C206.753 142.704 206.753 142.813 206.74 142.935C206.728 143.056 206.656 143.117 206.547 143.105C206.438 143.105 206.365 143.02 206.353 142.91C206.353 142.898 206.353 142.898 206.353 142.886C206.365 142.607 206.365 142.327 206.365 142.06Z"
      fill="#FEFEFE"
    />
    <path
      d="M211.368 141.051C211.562 141.051 211.743 141.039 211.937 141.063C212.034 141.075 212.082 141.124 212.095 141.221C212.107 141.318 212.058 141.379 211.973 141.428C211.925 141.452 211.889 141.452 211.84 141.452C211.61 141.452 211.368 141.452 211.138 141.452C211.053 141.452 211.029 141.476 211.041 141.561C211.053 141.659 211.041 141.744 211.041 141.841C211.041 141.914 211.065 141.938 211.125 141.938C211.319 141.938 211.501 141.938 211.695 141.938C211.731 141.938 211.767 141.938 211.804 141.95C211.913 141.975 211.998 142.072 211.998 142.157C211.998 142.254 211.901 142.327 211.78 142.327C211.574 142.327 211.356 142.339 211.15 142.327C211.053 142.327 211.029 142.351 211.041 142.449C211.053 142.607 211.041 142.765 211.041 142.923C211.041 143.068 210.968 143.141 210.859 143.141C210.738 143.141 210.653 143.068 210.653 142.923C210.653 142.388 210.653 141.853 210.653 141.318C210.653 141.172 210.714 141.1 210.859 141.1C211.016 141.039 211.198 141.051 211.368 141.051Z"
      fill="#FEFEFE"
    />
    <path
      d="M208.739 142.072C208.739 141.877 208.739 141.695 208.739 141.501C208.739 141.428 208.751 141.391 208.836 141.403C209.006 141.415 209.187 141.391 209.357 141.415C209.563 141.452 209.696 141.561 209.781 141.756C209.963 142.157 209.769 142.631 209.381 142.716C209.187 142.765 208.993 142.728 208.8 142.74C208.739 142.74 208.751 142.692 208.751 142.655C208.739 142.461 208.739 142.266 208.739 142.072Z"
      fill="#777475"
    />
    <path
      d="M206.752 141.731C206.765 141.634 206.704 141.476 206.777 141.403C206.849 141.33 206.995 141.391 207.116 141.379C207.176 141.379 207.237 141.379 207.285 141.391C207.479 141.415 207.6 141.586 207.588 141.78C207.576 141.962 207.431 142.108 207.237 142.108C207.104 142.108 206.958 142.108 206.825 142.108C206.752 142.108 206.74 142.084 206.74 142.023C206.752 141.95 206.752 141.853 206.752 141.731Z"
      fill="#777475"
    />
    <g opacity="0.8">
      <path
        opacity="0.5"
        d="M215.462 108.029L210.254 109.05C209.539 109.196 209.066 109.888 209.2 110.605L211.223 121.058C211.368 121.775 212.059 122.249 212.773 122.115L220.586 120.596C221.301 120.45 221.774 119.757 221.64 119.04L220.126 111.201L215.462 108.029ZM214.191 118.068C214.336 118.821 214.033 119.15 213.452 119.271C213.318 119.296 213.137 119.308 213.003 119.295L212.979 118.797C213.064 118.809 213.173 118.809 213.294 118.785C213.549 118.736 213.682 118.591 213.597 118.177L213.27 116.512L213.863 116.391L214.191 118.068ZM216.286 117.412C216.117 117.643 215.838 117.788 215.487 117.861C215.414 117.873 215.341 117.886 215.281 117.886L215.462 118.834L214.869 118.943L214.36 116.342C214.542 116.269 214.796 116.196 215.16 116.135C215.523 116.063 215.802 116.087 216.008 116.184C216.201 116.281 216.359 116.476 216.407 116.743C216.456 116.998 216.419 117.241 216.286 117.412ZM219.315 117.959C219.145 118.056 218.818 118.214 218.467 118.275C217.982 118.372 217.619 118.311 217.328 118.129C217.037 117.946 216.843 117.63 216.771 117.241C216.601 116.366 217.146 115.734 218.006 115.564C218.345 115.503 218.624 115.516 218.757 115.552L218.721 116.05C218.564 116.014 218.37 116.002 218.091 116.05C217.594 116.148 217.279 116.5 217.389 117.071C217.498 117.618 217.897 117.873 218.382 117.776C218.515 117.752 218.624 117.715 218.673 117.679L218.564 117.132L218.152 117.217L218.067 116.755L219.048 116.561L219.315 117.959ZM216.347 112.611C215.983 112.684 215.693 112.732 215.693 112.732L215.063 109.463L218.951 112.088L216.347 112.611Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M215.281 116.573C215.159 116.597 215.075 116.622 215.038 116.646L215.184 117.424C215.232 117.424 215.305 117.412 215.39 117.399C215.705 117.339 215.874 117.144 215.814 116.865C215.777 116.634 215.583 116.524 215.281 116.573Z"
        fill="black"
      />
    </g>
  </svg>
);

export default SendArt;
