import StickyBox from 'react-sticky-box';

import { Box, RadioButton, theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, borders, space, font, color } = theme;

export const StickyBoxContainer = styled(StickyBox)`
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;

  @media (max-width: ${breakpoint.xl}) {
    z-index: 99;
  }
`;

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 17rem;
  height: 100vh;
  transform: ${(p) => (p.isActive ? '' : 'translateX(17rem)')};
  transition: all 0.3s ease-in-out;

  @media (min-width: ${breakpoint.xl}) {
    position: static;
    width: 100%;
    height: auto;
    transform: translateX(0);
  }
`;

export const ButtonStickMobile = styled.button`
  position: absolute;
  top: ${space.xl5};
  left: -${space.xl3};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${space.xl3};
  height: ${space.xl2};
  border: none;
  border-radius: ${borders.radius.md} 0 0 ${borders.radius.md};
  box-shadow: -0.2rem 0.4rem 0.6rem 0.2rem rgb(83 117 153 / 50%);
  background-color: ${color.grayscale.white};
  text-align: center;

  svg {
    fill: ${color.grayscale.black};
    z-index: 11;
    position: relative;
    vertical-align: middle;
  }

  @media (min-width: ${breakpoint.xl}) {
    display: none;
  }
`;

export const WrapperSummary = styled.div`
  max-height: 100%;
  overflow-y: auto;
  padding-top: ${space.md};

  @media (min-width: ${breakpoint.xl}) {
    padding-top: 0;
  }
`;

export const BoxSummary = styled(Box)`
  margin-bottom: ${space.md};
`;

export const Summary = styled.div`
  width: 100%;
  padding: ${space.md} ${space.md} 0;
`;

export const SelectionPart = styled.div`
  margin-bottom: ${space.sm};
`;

export const AttributeList = styled.ul`
  margin: 0;
  padding-left: 0;
`;

export const AttributeListItem = styled.li`
  color: ${color.grayscale.darker};
  font-size: ${font.size.small};
  line-height: ${font.lineHeight.root};
`;

export const CartSummarySubtotal = styled.div`
  margin-bottom: ${space.xs};
  padding: ${space.xs} ${space.md} 0;
  border-top: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  font-size: ${font.size.h5};
  font-weight: ${font.weight.bold};
  color: ${color.primary.dark};

  b {
    display: inline-flex;
    padding-right: ${space.xs};
    color: ${color.grayscale.black};
    font-size: ${font.size.small};
  }
`;

export const CartSummaryWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${space.xl};
  background-color: ${color.warning.lighter};
  color: ${color.grayscale.black};
  font-size: ${font.size.xsmall};
  font-weight: ${font.weight.bold};

  svg {
    display: inline-flex;
    width: auto;
    height: 1.25rem;
    margin-right: ${space.xs};
    transform: scaleX(-1);
  }
`;

export const ContainerPrePressTemplate = styled(Box)`
  margin-bottom: ${space.md};
  padding: ${space.md};
  width: 100%;
`;

export const Orientation = styled.div`
  display: flex;
  margin-bottom: ${space.sm};
`;

export const RadioButtonOrientation = styled(RadioButton)`
  &:first-child {
    margin-right: ${space.xs2};
  }
`;

export const OptionUl = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`;

export const OptionLi = styled.li`
  display: inline-flex;
  position: relative;
`;

export const ButtonDownload = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  margin-right: ${space.xs2};

  svg {
    position: relative;
    width: ${space.xl2};
    height: auto;
    fill: ${color.primary.pure};
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 1.3rem;
    width: ${space.xl2};
    height: 100%;
    color: ${color.primary.pure};
    font-size: 0.563rem;
    text-align: center;
  }
`;

export const SummaryButton = styled.div`
  display: none;
  border-radius: ${borders.radius.md};
  background-color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.xl}) {
    display: block;
  }
`;
export const LoaderButton = styled.div`
  position: absolute;
  left: 0;
  top: 0.75rem;
  width: 100%;
  height: 100%;
`;
