import { theme, Box, Button, Input, Text } from '@printi/printi-components';
import styled, { css } from 'styled-components';
const { breakpoint, color, font, space } = theme;

export const CepInput = styled(Input)`
  padding: 0 0 ${space.lg};
  span {
    padding-right: 0;

    p {
      font-size: ${font.size.root};
      font-weight: ${font.weight.bold};
      color: ${color.grayscale.gray} !important;
    }
  }
`;

export const BlockBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const BlockButton = styled(Button)`
  margin-left: ${space.xs};
  margin-top: ${space.lg};
  width: 3.5rem;
  min-width: 3.5rem;
`;

export const DeliveryBlockText = styled.div`
  margin-right: ${space.xs};
`;

export const DeliveryCep = styled.div`
  ${(p) =>
    p.hidden &&
    css`
      display: none;
    `}
`;

export const DeliveryCepInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  ${(p) =>
    p.hidden &&
    css`
      display: none;
    `}
`;

export const TextCep = styled(Text)`
  display: inline-block;
`;

export const ZipBox = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${breakpoint.sm}) {
    width: 49%;
  }
`;
