import Slider from 'react-slick';

import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { space, color } = theme;

export const ProductImages = styled(Slider)`
  position: relative;
  margin-bottom: ${space.xs};

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .slick-arrow {
    z-index: 1;
    background-color: transparent;
    width: ${space.xl};
    color: hsla(221, 7%, 45%, 0.3);
    transition: 0.3s ease;
    height: ${space.xl};
    transform: scale(2);

    &:hover {
      color: ${color.primary.pure};
    }

    &:before {
      display: none;
    }
  }

  .slick-next {
    right: ${space.md};
  }

  .slick-prev {
    left: ${space.xl};
  }

  .slick-dots {
    position: absolute;
    bottom: 1rem;
    right: 50%;
    transform: translateX(50%);
    width: auto;
    height: auto;
    line-height: 0;

    li {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 0.5rem;

      button {
        background-color: hsla(0, 0%, 100%, 0.3);
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
        padding: 0;
        transition: 0.3s ease;

        &:before {
          display: none;
        }

        &:hover,
        &:focus {
          background-color: #fff;
        }
      }

      &.slick-active {
        button {
          background-color: #fff;
        }
      }
    }
  }
`;
