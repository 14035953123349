import React, { memo, useCallback } from 'react';

import { Text, Button } from '@printi/printi-components';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import * as S from './CallToAction.styles';

const CallToAction = ({ productInfo }) => {
  const router = useRouter();

  const handleRedirect = useCallback(() => {
    router.push(`configuracao-${productInfo.slug}`);
  }, [router, productInfo]);

  return (
    <S.CallToActionRow>
      <S.CallToActionCol xs={12}>
        <Text color="light" as="h3" size="xlg" margin="0 0 2rem">
          Imprima já o seu produto {productInfo.title} pelo melhor preço
        </Text>
      </S.CallToActionCol>
      <S.CallToActionCol xs={12}>
        <Button kind="success" onClick={handleRedirect}>
          Configure aqui
        </Button>
      </S.CallToActionCol>
      <S.CallToActionCol xs={12}>
        <Text color="light" margin="2rem 0 0">
          Ainda tem dúvidas? Ligue já (11) 4130-3799
        </Text>
      </S.CallToActionCol>
    </S.CallToActionRow>
  );
};

CallToAction.propTypes = {
  productInfo: PropTypes.object.isRequired,
};

export default memo(CallToAction);
