/* eslint-disable react/no-danger */
import React, { memo } from 'react';

import { Col, Text } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Details.styles';

const Details = ({ details }) => {
  const { lp_title1, lp_teaser1, lp_title2, lp_teaser2 } = details;

  if (lp_title1 === '' && lp_teaser1 === '' && lp_title2 === '' && lp_teaser2 === '') {
    return null;
  }

  return (
    <S.DetailsRow isFull>
      <Col xs={12}>
        <Text as="h3" size="xlg" weight="bold" transform="uppercase" margin="0 0 1rem">
          Detalhes do produto
        </Text>
        <Text as="h4" size="xlg" weight="bold" margin="0 0 0.5rem">
          {lp_title1}
        </Text>
        <S.ContentText dangerouslySetInnerHTML={{ __html: lp_teaser1 }} />
        <Text as="h4" size="xlg" weight="bold" margin="0 0 0.5rem">
          {lp_title2}
        </Text>
        <S.ContentText dangerouslySetInnerHTML={{ __html: lp_teaser2 }} noMargin />
      </Col>
    </S.DetailsRow>
  );
};

Details.propTypes = {
  details: PropTypes.shape({
    lp_title1: PropTypes.string,
    lp_teaser1: PropTypes.string,
    lp_title2: PropTypes.string,
    lp_teaser2: PropTypes.string,
  }).isRequired,
};

export default memo(Details);
