const attributesList = (part, selection) => {
  const { attributes } = part;
  let hasEmpty = false;

  return attributes.filter((attribute) => {
    if (hasEmpty || (!attribute.visible && attribute.options.length === 1)) {
      return false;
    }

    if (
      !selection[part.id] ||
      !selection[part.id][attribute.key] ||
      !{}.hasOwnProperty.call(selection[part.id], attribute.key)
    ) {
      hasEmpty = true;
    }

    return true;
  });
};

export default attributesList;
