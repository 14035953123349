import React, { memo, useEffect, useState, useCallback } from 'react';

import { Text, Loader } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Summary.styles';
import useDownloadTemplate from './useDownloadTemplate';
import useFetchPrepressTemplates from './useFetchPrepressTemplates';

const extensions = {
  photoshop: 'PSD',
  illustrator: 'AI',
  corel: 'CDR',
  pdf: 'PDF',
  canva: 'Canva',
};

const PrePressTemplate = ({ t, selection, productId, productTitle }) => {
  const [orientation, setOrientation] = useState('');
  const [currentExtension, setCurrentExtension] = useState('');
  const [optionsLength, setOptionsLength] = useState(0);
  const { fetchDownloadTemplate, downloadTemplateLoading } = useDownloadTemplate();
  const { fetchPrepressTemplates, options, parts, prepressTemplatesLoading } = useFetchPrepressTemplates();

  useEffect(() => {
    fetchPrepressTemplates(productId, selection);
  }, [fetchPrepressTemplates, productId, selection]);

  useEffect(() => {
    if (!prepressTemplatesLoading && !orientation && options) {
      setOrientation(Object.keys(options)[0]);
      setOptionsLength(Object.keys(options).length);
    }
  }, [prepressTemplatesLoading, orientation, options]);

  const handleOrientation = useCallback(({ target }) => {
    setOrientation(() => target.value);
  }, []);

  const handleDownload = useCallback(
    (extension) => {
      setCurrentExtension(extension);
      const fileName = `${productTitle} ${extension.replace(/\b\w/g, (l) => l.toUpperCase())}`;
      fetchDownloadTemplate(extension, orientation, parts, fileName);
    },
    [fetchDownloadTemplate, orientation, productTitle, parts],
  );

  return (
    <>
      {options && optionsLength > 0 && (
        <S.ContainerPrePressTemplate type="gold" noBorder>
          <Text weight="bold" margin="0 0 0.25rem">
            {t.SUB_TITLE}
          </Text>

          {optionsLength === 0 && (
            <Text color="danger" align="center" margin="1rem 0">
              Este produto não possui nenhum gabarito disponível.
            </Text>
          )}

          {optionsLength > 1 && (
            <div className="mol-prepress-orientarion">
              <Text color="gray" margin="0 0 0.75rem">
                1. {t.ORIENTATION_CHOICE}:
              </Text>
              <S.Orientation>
                {Object.keys(options).map((option) => (
                  <S.RadioButtonOrientation
                    key={option}
                    id={option}
                    name="orientation"
                    value={option}
                    checked={option === orientation}
                    label={option}
                    onChange={handleOrientation}
                    size="md"
                  />
                ))}
              </S.Orientation>
            </div>
          )}

          <Text color="gray" margin="0 0 0.75rem">
            {optionsLength > 1 && '2. '}
            {t.SOFTWARE_CHOICE}:
          </Text>
          <S.OptionUl>
            {[...new Set(options[orientation])].map((option) => (
              <S.OptionLi key={option}>
                <S.ButtonDownload
                  type="button"
                  onClick={() => handleDownload(option)}
                  disabled={downloadTemplateLoading}
                >
                  <svg aria-hidden="true" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H224q-40 0-68-28t-28-68V96q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640h-416q-40 0-68-28t-28-68V128H256v1536h1280z" />
                  </svg>
                  {extensions[option] && <span>{extensions[option]}</span>}
                  {downloadTemplateLoading && currentExtension === option && (
                    <S.LoaderButton>
                      <Loader size="small" wrapper="1.5rem" />
                    </S.LoaderButton>
                  )}
                </S.ButtonDownload>
              </S.OptionLi>
            ))}
          </S.OptionUl>
        </S.ContainerPrePressTemplate>
      )}
    </>
  );
};

PrePressTemplate.propTypes = {
  t: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  productTitle: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default memo(PrePressTemplate);
