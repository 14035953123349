import { useCallback } from 'react';

import { api } from '@utils';

const useFetchProductUnavailable = () => {
  const postProductUnavailable = useCallback(
    (email, productId) =>
      api.post('/v2/finalproducts/unavailable', {
        email: email.value,
        final_product_id: productId,
      }),
    [],
  );

  return { postProductUnavailable };
};

export default useFetchProductUnavailable;
