/* eslint-disable no-undef,func-names,prefer-rest-params */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Loader, Text } from '@printi/printi-components';
import { settingsAdditionalOptionsFetch } from '@store/settings/actions';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { AlertShipping } from '../Warning/Alert';

const FunnelBlock = dynamic(() => import('@components/Funnel/FunnelBlock'));
const Matrix = dynamic(() => import('./MatrixBlock'));
const Warning = dynamic(() => import('../Warning'));

const MatrixBlock = ({ locale, order, matrix, matrixQty, selectedSource, config, pickupPlaces, handleMatrix }) => {
  const dispatch = useDispatch();
  const {
    selection: { date: selectedDate },
    customQuantity,
    rows,
    dates,
  } = matrix;
  const [matrixDates, setMatrixDates] = useState(dates);
  const [matrixRows, setMatrixRows] = useState(rows);
  const [isFreeShippingAvailable, setIsFreeShippingAvailable] = useState(false);
  const { currentQty, isCustomQty } = matrixQty;

  const handleSelectMatrix = useCallback(
    (qty, date, isCustom = false) => {
      dispatch(settingsAdditionalOptionsFetch(date, qty, isCustom));
    },
    [dispatch],
  );

  const isLoading = isCustomQty
    ? !customQuantity.isLoaded && customQuantity.isRunning
    : !matrix.isLoaded && matrix.isRunning;

  const mountMatrix = useCallback(() => {
    if (!isLoading && matrix.deliveryMethod === 'zipcode') {
      handleMatrix(config.zipcode.value);
    }
  }, [config.zipcode.value, handleMatrix, matrix.deliveryMethod]);

  useEffect(() => {
    mountMatrix();
  }, [mountMatrix]);

  useEffect(() => {
    if (!isCustomQty) {
      setMatrixDates(dates);
      setMatrixRows(rows);
    }

    if (customQuantity.isLoaded) {
      setMatrixDates(customQuantity.dates);
      setMatrixRows(customQuantity.rows);
    }
  }, [currentQty, customQuantity, isCustomQty, rows, dates]);

  useEffect(() => {
    setIsFreeShippingAvailable(
      Object.entries(matrixDates)
        // eslint-disable-next-line no-unused-vars
        .find(([key, _]) => matrixRows[currentQty][key].is_free_shipping),
    );
  }, [matrixDates, matrixRows, currentQty]);

  let title = 'Prazo e valor da entrega';
  if (matrix.deliveryMethod === 'pickupPlaces') {
    title = 'Prazo e valor da retirada';
  }

  const hasDeliveryDeadLinePerQuantity =
    isCustomQty && customQuantity.isLoaded ? matrix.customQuantity.rows[currentQty] : matrix.rows[currentQty];

  return (
    <>
      {isLoading && <Loader size="medium" wrapper="8rem" />}
      {!isLoading && (
        <FunnelBlock
          order={order}
          title={title}
          isComplete={matrix.selection.date > 0 && matrix.selection.quantity > 0}
        >
          <Matrix
            matrix={{ matrixDates, matrixRows }}
            quantity={currentQty}
            handleSelect={handleSelectMatrix}
            isCustomQty={isCustomQty}
            selectedDate={selectedDate}
          />
          {matrix.error && (
            <Text color="danger" size="lg" weight="bold" align="center" margin="2rem 0">
              {matrix.error}
            </Text>
          )}
          {!hasDeliveryDeadLinePerQuantity && !matrix.error && (
            <Text color="danger" size="lg" weight="bold" align="center" margin="2rem 0">
              {`* ${locale.ERROR_DELIVERY_DEADLINE_PER_QUANTITY}`}
            </Text>
          )}
          {selectedSource === 'art_creation' && (
            <div className="atm-cart-item-art-approval" key="businness_days">
              {`* ${locale.AFTER_ART_APPROVAL}`}
            </div>
          )}
          <Warning
            locale={locale}
            matrix={matrix}
            pickupPlaces={pickupPlaces}
            config={config}
            selectedSource={selectedSource}
            key="warning"
          />

          {isFreeShippingAvailable && <AlertShipping key="AlertShipping" />}
        </FunnelBlock>
      )}
    </>
  );
};

MatrixBlock.propTypes = {
  locale: PropTypes.object.isRequired,
  order: PropTypes.number.isRequired,
  matrix: PropTypes.shape({
    customQuantity: PropTypes.object.isRequired,
    dates: PropTypes.object.isRequired,
    deliveryMethod: PropTypes.oneOf(['', 'zipcode', 'pickupPlaces']).isRequired,
    rows: PropTypes.object.isRequired,
    selection: PropTypes.shape({
      date: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
      isCustom: PropTypes.bool,
      quantity: PropTypes.number,
    }).isRequired,
    error: PropTypes.string,
    isLoaded: PropTypes.bool,
    isRunning: PropTypes.bool,
  }).isRequired,
  config: PropTypes.object.isRequired,
  matrixQty: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  selectedSource: PropTypes.string.isRequired,
  pickupPlaces: PropTypes.array.isRequired,
  handleMatrix: PropTypes.func.isRequired,
};

export default memo(MatrixBlock);
