import { Text, Row, Col, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, space, color, borders } = theme;

export const TutorialRow = styled(Row)`
  margin-bottom: ${space.xl};
`;

export const LinkPost = styled.a`
  color: ${color.grayscale.black};
`;

export const WrapperPosts = styled(Col)`
  background-color: ${color.grayscale.white};
  padding: ${space.xs};
  height: 28.8rem;
  overflow: auto;
  border-radius: ${borders.radius.md};
`;

export const TitleVideo = styled(Text)`
  position: absolute;
  text-align: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: ${space.xs};
  background: rgba(0, 0, 0, 0.5);
  padding: ${space.xl} 0 ${space.xl3};
  opacity: 1;
`;

export const WrapperVideo = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  position: relative;
  margin-bottom: ${space.xs};

  @media (min-width: ${breakpoint.xl}) {
    margin-bottom: ${space.lg};
  }

  img {
    cursor: pointer;
  }
`;

export const ModalVideo = styled.div`
  margin-top: 0;
  overflow: hidden;
  padding: relative;
  height: 22rem;

  @media (min-width: ${breakpoint.xl}) {
    margin-top: ${space.xs};
  }

  iframe {
    height: 21rem;
    width: 100%;

    @media (min-width: ${breakpoint.xl}) {
      height: 100%;
    }
  }
`;
