import React, { memo } from 'react';

import PropTypes from 'prop-types';

import * as S from './CarouselArrow.styles';

const CarouselArrow = ({ onClick, rotate }) => (
  <S.CarouselArrowContainer rotate={rotate ? 1 : 0} onClick={onClick} aria-label="Mover carrossel">
    <svg xmlns="http://www.w3.org/2000/svg" width="24.06" height="75.64" viewBox="0 0 12.03 37.82">
      <path d="M.62 37.8a.564.564 0 0 1-.3-.08.583.583 0 0 1-.22-.81l10.53-18.23L.1.9A.583.583 0 0 1 .32.09a.6.6 0 0 1 .82.21l10.89 18.38L1.15 37.5a.63.63 0 0 1-.53.3z" />
    </svg>
  </S.CarouselArrowContainer>
);

CarouselArrow.propTypes = {
  onClick: PropTypes.func,
  rotate: PropTypes.bool,
};

export default memo(CarouselArrow);
