import React, { memo, useCallback } from 'react';

import { Text } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Rating.styles';
import RatingStar from './RatingStar';

const RatingWithComment = ({ commentInfos }) => {
  const { datepublish, stars, content, author } = commentInfos;

  const schemaMicrodata = useCallback(
    () => (
      <>
        <S.HiddenTag itemProp="itemReviewed" itemScope itemType="http://schema.org/Organization">
          <span itemProp="name">Printi</span>
        </S.HiddenTag>
        <S.HiddenTag itemProp="datePublished">{datepublish}</S.HiddenTag>
        <S.HiddenTag itemProp="reviewRating" itemScope itemType="http://schema.org/Rating">
          <meta itemProp="worstRating" content="1" />
          <span itemProp="ratingValue">{stars}</span>/<span itemProp="bestRating">5</span>stars
        </S.HiddenTag>
      </>
    ),
    [datepublish, stars],
  );

  return (
    <S.Review itemProp="review" itemScope itemType="http://schema.org/Review">
      {schemaMicrodata()}
      <RatingStar numStar={stars} />
      <Text itemProp="reviewBody" as="h4" size="md" margin="0.25rem 0 0.5rem">
        {`"${content}"`}
      </Text>
      <S.WrapperInfo>
        <Text itemProp="author" size="xs" italic margin="0 0.5rem 0 0">
          {author}
        </Text>
      </S.WrapperInfo>
    </S.Review>
  );
};

RatingWithComment.propTypes = {
  commentInfos: PropTypes.shape({
    datepublish: PropTypes.string,
    stars: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.string,
    author: PropTypes.string,
  }).isRequired,
};

export default memo(RatingWithComment);
