import React, { memo, useState } from 'react';

import { Modal } from '@printi/printi-components';
import PropTypes from 'prop-types';
import { sprintf } from 'sprintf-js';

import * as S from './Modal.styles';

const CustomPageModal = ({ onClose, data, locale, onSubmit, custom }) => {
  const { limits } = data;
  const [state, setState] = useState({
    hasErrorPage: true,
    errorMsgPage: '',
    qtyPage: null,
  });

  const handleResponsePage = (e) => {
    const { multiplyPage, page_min_nr: minPage, page_max_nr: maxPage } = limits;

    const defaultMultiple = 4;
    let qty = e.currentTarget.value;
    qty = parseInt(qty, 10);
    const mod = qty % (defaultMultiple || multiplyPage);

    setState({
      ...state,
      hasErrorPage: false,
      errorMsgPage: '',
      qtyPage: qty,
    });

    if (!qty) {
      setState({
        ...state,
        hasErrorPage: true,
        errorMsgPage: 'Selecione um valor',
        qtyPage: qty,
      });
    }

    if (multiplyPage > 0 || mod > 0) {
      setState({
        ...state,
        hasErrorPage: true,
        errorMsgPage: `Valor deve ser múltiplo de ${multiplyPage || defaultMultiple}`,
        qtyPage: qty,
      });
    }

    if (qty < minPage) {
      setState({
        ...state,
        hasErrorPage: true,
        errorMsgPage: `Número deve ser maior que ${minPage}`,
        qtyPage: qty,
      });
    }

    if (qty > maxPage) {
      setState({
        ...state,
        hasErrorPage: true,
        errorMsgPage: `Número deve ser menor que ${maxPage}`,
        qtyPage: qty,
      });
    }

    if (!!qty && (qty < parseInt(minPage, 10) || qty > parseInt(maxPage, 10))) {
      setState({
        ...state,
        hasErrorPage: true,
        errorMsgPage: sprintf(
          'Número de páginas inválido. O mínimo é %s e o máximo %s.',
          parseInt(limits.page_min_nr, 10),
          parseInt(limits.page_max_nr, 10),
        ),
        qtyPage: qty,
      });
    }
  };

  const isValid = () =>
    !!state.qtyPage &&
    state.qtyPage >= parseInt(limits.page_min_nr, 10) &&
    state.qtyPage <= parseInt(limits.page_max_nr, 10);

  const handleSubmit = () => {
    const { qtyPage } = state;
    onClose();
    onSubmit(qtyPage, data.attribute, data.productPart);
  };

  return (
    <Modal
      size="small"
      close={onClose}
      infos={{ title: locale.modal.page.TITLE }}
      isLoading={custom.isRunning}
      isDisabled={state.hasErrorPage || !isValid()}
      error={custom.error}
      func={handleSubmit}
      buttonName="Confirmar"
    >
      <S.StyledInput
        hasError={state.hasErrorPage}
        error={state.errorMsgPage}
        onChange={handleResponsePage}
        isBlock
        label="Páginas"
        type="text"
        hasMask
        rightIcon={!state.hasErrorPage && 'CheckmarkCircle2Outline'}
        options={{ numericOnly: true }}
      />
    </Modal>
  );
};

CustomPageModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  locale: PropTypes.object,
  custom: PropTypes.object,
};

export default memo(CustomPageModal);
