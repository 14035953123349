import React, { memo } from 'react';

import PropTypes from 'prop-types';

import ImageFallback from '../../ImageFallback';
import * as S from './OptionBlock.styles';
import ZoomIcon from './ZoomIcon';
const cloudFront = process.env.CLOUDFRONT;
const Option = ({
  id,
  value,
  image,
  name,
  label,
  checked,
  disabled,
  onChange,
  onZoomClick,
  zoomable,
  extraInformation,
}) => (
  <S.OptionContainer
    tabIndex="0"
    onKeyUp={({ target, code }) => code === 'Enter' && target.querySelector('input').click()}
  >
    <S.Label data-testid="label-test" htmlFor={id} checked={!!checked} disabled={disabled}>
      {!!image && zoomable && (
        <S.ZoomButton
          data-testid="zoombutton-test"
          kind="primary"
          type="button"
          aria-label="zoom na imagem"
          onClick={onZoomClick}
        >
          <ZoomIcon />
        </S.ZoomButton>
      )}

      {!!image && (
        <ImageFallback
          data-testid="image-test"
          src={image}
          alt={label}
          title={label}
          width={180}
          height={180}
          fallbackSrc={`${cloudFront}/assets/final/img/imagem-indisponivel-semtexto2.jpg`}
        />
      )}

      <S.Container>
        <S.Input
          data-testid="input-test"
          type="radio"
          id={id}
          name={name}
          checked={!!checked}
          disabled={disabled}
          value={value}
          onChange={disabled ? null : onChange}
        />
        {label}
      </S.Container>
    </S.Label>
    <S.ExtraInfo>{extraInformation}</S.ExtraInfo>
  </S.OptionContainer>
);

Option.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onZoomClick: PropTypes.func,
  value: PropTypes.string.isRequired,
  zoomable: PropTypes.bool,
  extraInformation: PropTypes.node,
};

export default memo(Option);
