/* eslint-disable no-undef,func-names,prefer-rest-params */
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Text } from '@printi/printi-components';
import {
  settingsMatrixFetch,
  settingsMatrixReset,
  settingsMatrixCustomFetch,
  customMatrixFetchReset,
} from '@store/settings/actions';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import FunnelQtyBlock from './Qty';
import { AlertCustom } from './styles';
import * as S from './styles';
const FunnelBlock = dynamic(() => import('@components/Funnel/FunnelBlock'));
const FunnelDeliveryBlock = dynamic(() => import('./Delivery'));
const FunnelMatrixBlock = dynamic(() => import('./MatrixBlock'));
const PickupPlace = dynamic(() => import('./PickupPlace'));
const MatrixDigitalProductBlock = dynamic(() => import('./MatrixDigitalProduct'));
const Warning = dynamic(() => import('./Warning'));

const Matrix = ({
  locale,
  order,
  matrix,
  isCustomEnabled,
  selectedSource,
  pickupPlaces,
  config,
  isDigital,
  selection,
  product,
}) => {
  const dispatch = useDispatch();
  const productSettingsState = useSelector((state) => state.settings);
  const {
    finalProduct,
    matrixQty,
    options,
    source,
    matrix: { customFetchError },
  } = productSettingsState;
  const { custom_qty_multiple: multipleQty } = finalProduct;

  const handleFetchMatrix = useCallback(
    (zipcode, pickupPlaceId) => {
      dispatch(settingsMatrixReset());
      if (matrixQty?.isCustomQty) {
        dispatch(settingsMatrixCustomFetch(matrixQty.currentQty, zipcode, pickupPlaceId));
      } else {
        dispatch(settingsMatrixFetch(zipcode, pickupPlaceId, matrixQty.currentQty ? matrixQty.currentQty : 0));
      }
    },
    [dispatch, matrixQty],
  );

  const handleResetCustomMatrix = useCallback(() => {
    dispatch(customMatrixFetchReset());
  }, [dispatch]);

  useEffect(() => {
    window.hj =
      window.hj ||
      function () {
        (hj.q = hj.q || []).push(arguments);
      };
    window.hj('trigger', 'matrix_loaded');
  }, []);

  const showMatrixBlock =
    (config.zipcode.isZipcodeValid || matrix.deliveryMethod === 'pickupPlaces') &&
    matrixQty.currentQty > 0 &&
    source.selectedSource &&
    config.showSteps.matrix &&
    options.selectionIsComplete;

  const showMatrixBlockPickupPlaces =
    (matrix.deliveryMethod === 'pickupPlaces' && config.zipcode.pickupPlaceId > 0) ||
    matrix.deliveryMethod === 'zipcode';

  const showZipBlock =
    matrixQty.currentQty > 0 && source.selectedSource && config.showSteps.matrix && options.selectionIsComplete;

  const showDeliveryBlock =
    (config.zipcode.isZipcodeValid || config.zipcode.usePickupPlaces || matrix.deliveryMethod === 'pickupPlaces') &&
    matrixQty.currentQty > 0 &&
    source.selectedSource &&
    config.showSteps.matrix &&
    options.selectionIsComplete;

  const showPickupPlaceBlock = showDeliveryBlock && matrix.deliveryMethod === 'pickupPlaces';

  useEffect(() => {
    if (isDigital) {
      dispatch(settingsMatrixReset());
      dispatch(settingsMatrixFetch(''));
    }
  }, [dispatch, isDigital]);

  if (isDigital) {
    return (
      <S.MatrixSection>
        <FunnelBlock
          order={order}
          title={!isDigital ? locale.TITLE : locale.TITLE_DIGITAL_PRODUCT}
          isComplete={matrix.selection.date > 0 && matrix.selection.quantity > 0}
          infos={!isDigital ? locale.MORE_INFO_TEXT : ''}
        >
          <MatrixDigitalProductBlock
            isCustomEnabled={isCustomEnabled}
            key="matrix"
            locale={locale}
            matrix={matrix}
            selectedSource={selectedSource}
          />
          <Warning
            locale={locale}
            matrix={matrix}
            pickupPlaces={pickupPlaces}
            config={config}
            selectedSource={selectedSource}
            isDigital={isDigital}
          />
        </FunnelBlock>
      </S.MatrixSection>
    );
  }

  return (
    <S.MatrixSection>
      <AlertCustom>
        <Text size="sm" align="left">
          Mudamos a forma de apresentar nossos preços! Agora você consegue saber o preço do produto e do frete
          separadamente :)
        </Text>
      </AlertCustom>

      <FunnelQtyBlock
        locale={locale}
        order={order}
        options={options}
        selection={selection}
        finalProduct={finalProduct}
        isLoading={options.isRunning || !options.isLoaded}
        source={source}
        multipleQty={parseInt(multipleQty, 10)}
        matrixQty={matrixQty}
      />

      {(showDeliveryBlock || showZipBlock) && (
        <FunnelDeliveryBlock
          order={order + 1}
          pickupPlaces={pickupPlaces}
          deliveryMethod={matrix.deliveryMethod}
          handleMatrix={handleFetchMatrix}
          zipcode={config.zipcode}
        />
      )}

      {showPickupPlaceBlock && (
        <PickupPlace
          order={order + 1}
          pickupPlaces={pickupPlaces}
          deliveryMethod={matrix.deliveryMethod}
          handleMatrix={handleFetchMatrix}
        />
      )}

      {showMatrixBlockPickupPlaces && showMatrixBlock && (
        <FunnelMatrixBlock
          locale={locale}
          order={order + 2}
          matrix={matrix}
          isCustomEnabled={isCustomEnabled}
          selectedSource={selectedSource}
          pickupPlaces={pickupPlaces}
          config={config}
          selection={selection}
          product={product}
          matrixQty={matrixQty}
          handleMatrix={handleFetchMatrix}
          isLoaded={
            (matrix.isLoaded && !matrix.isRunning) ||
            (matrix.customQuantity.isLoaded && !matrix.customQuantity.isRunning)
          }
        />
      )}

      {customFetchError && (
        <Modal infos={{ title: 'Atenção' }} close={handleResetCustomMatrix} func={handleResetCustomMatrix}>
          <Text size="sm" align="center">
            {customFetchError}
          </Text>
        </Modal>
      )}
    </S.MatrixSection>
  );
};

Matrix.propTypes = {
  locale: PropTypes.object.isRequired,
  order: PropTypes.number.isRequired,
  matrix: PropTypes.shape({
    customQuantity: PropTypes.object.isRequired,
    dates: PropTypes.object.isRequired,
    deliveryMethod: PropTypes.oneOf(['', 'zipcode', 'pickupPlaces']).isRequired,
    rows: PropTypes.object.isRequired,
    selection: PropTypes.shape({
      date: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
      isCustom: PropTypes.bool,
      quantity: PropTypes.number,
    }).isRequired,
    error: PropTypes.string,
    isLoaded: PropTypes.bool,
    isRunning: PropTypes.bool,
  }).isRequired,
  isDigital: PropTypes.bool,
  isCustomEnabled: PropTypes.bool.isRequired,
  selectedSource: PropTypes.string.isRequired,
  pickupPlaces: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default Matrix;
