import React from 'react';

export const DiscountIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" fill="transparent" viewBox="0 0 48 49">
    <path
      fill="transparent"
      stroke="#323232"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M44 31.33v5.528a2 2 0 01-2 2H6a2 2 0 01-2-2V31.33c0-.29.164-.554.422-.684v0A6.468 6.468 0 008 24.858v0a6.471 6.471 0 00-3.578-5.788v0A.766.766 0 014 18.386v-5.528a2 2 0 012-2h36a2 2 0 012 2v5.528c0 .29-.164.554-.422.684l-.088.046A6.468 6.468 0 0040 24.858v0a6.472 6.472 0 003.576 5.788h.002c.258.13.422.394.422.684z"
      clipRule="evenodd"
    ></path>
    <path
      fill="transparent"
      stroke="#323232"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M18.86 29.998l10-10M28.998 29.358a.5.5 0 10.004 1 .5.5 0 00-.004-1M18.998 19.358a.5.5 0 10.004 1 .5.5 0 00-.004-1"
    ></path>
  </svg>
);
