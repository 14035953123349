import { Button, Row, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, space, borders, color } = theme;

export const RatingRow = styled(Row)`
  margin-bottom: ${space.xl};
`;

export const WrapperDescriptionRating = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${space.xs};
`;

export const ButtonTranparent = styled(Button)`
  background: transparent;
  border: ${borders.sizes.sm} solid ${color.primary.pure};
`;

export const SendComment = styled.div`
  margin-bottom: ${space.lg};
  max-width: 100%;

  @media (min-width: ${breakpoint.xl}) {
    max-width: 10rem;
  }
`;

export const WrapperComments = styled.div`
  max-height: 18.75rem;
  overflow-y: auto;
  margin-bottom: ${space.xs};
  background: #fff;
  padding: 0 ${space.xs2};
  border-radius: 4px;
`;

export const HiddenTag = styled.span`
  display: none;
`;
