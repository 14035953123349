import React, { memo } from 'react';

import { Icon } from '@printi/printi-components';
import { formatMoney, formatDate } from '@utils';
import PropTypes from 'prop-types';

import * as S from './Summary.styles';

const SummaryPrice = ({ t, matrix, selectedSource }) => {
  let dateSelected;
  if (matrix.selection.date && matrix.selection.quantity) {
    dateSelected =
      matrix.dates[matrix.selection.date] && formatDate(matrix.dates[matrix.selection.date].delivery_due_date);
    let matrixCustomDates = matrix.dates;

    if (matrix.customQuantity) {
      matrixCustomDates = {
        ...matrix.dates,
        ...matrix.customQuantity.dates,
      };
    }

    if (selectedSource === 'art_creation' && matrixCustomDates[matrix.selection.date]) {
      dateSelected = `${matrixCustomDates[matrix.selection.date].business_days} ${
        t.translate.page.cart.product_list.BUSINESS_DAYS
      }*`;
    }
  }

  return (
    !!(matrix.selection.date && matrix.selection.quantity) && (
      <>
        <S.CartSummarySubtotal>
          <b>{t.translate.page.cart.values.TOTAL}:</b>
          {formatMoney(
            matrix.selection.isCustom
              ? matrix.customQuantity.rows[matrix.selection.quantity][matrix.selection.date].prices.total
              : matrix.rows[matrix.selection.quantity][matrix.selection.date].prices.total,
          )}
        </S.CartSummarySubtotal>
        <S.CartSummaryWarning>
          <Icon name="Car" size="xs" />
          {t.translate.page.product_settings.sidebar.ESTIMATED_DELIVERY}: {dateSelected}
        </S.CartSummaryWarning>
      </>
    )
  );
};

SummaryPrice.propTypes = {
  t: PropTypes.object.isRequired,
  matrix: PropTypes.object.isRequired,
  selectedSource: PropTypes.string.isRequired,
};

export default memo(SummaryPrice);
