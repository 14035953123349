import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import BannerLP from '@components/BannerLP';
import MainContentLP from '@components/MainContentLP';
import ProductList from '@components/ProductList';
import ProductsCategories from '@components/ProductsCategories';
import { Text, Row, Col } from '@printi/printi-components';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import Details from './Details';
import generateContentInfos from './generateContentInfos';
import { hasBanner, hasMainContent, hasProductList } from './hasSection';

const Advantages = dynamic(() => import('@components/Advantages'));
const Benefits = dynamic(() => import('@components/Benefits'));
const TextVideo = dynamic(() => import('@components/TextVideo'));
const UseTerm = dynamic(() => import('@components/UseTerm'));

const Dynamic = ({ content }) => {
  const { locale } = useSelector((state) => state.locale);
  const infos = generateContentInfos(content);

  const {
    banners_top,
    banners_bottom,
    content_lp_description,
    main_lp_content,
    product_box_list,
    product_categories_carousel,
    settings,
    subtitle,
  } = infos;

  const {
    hide_banner_title,
    show_advantages_info,
    show_products_carousel,
    show_static_advantages,
    show_static_printing_plant,
    show_static_warranty,
  } = settings;

  return (
    <Row>
      <Col xs={12}>
        {hasBanner(banners_top) && <BannerLP content={banners_top[0]} showTitle={!hide_banner_title} />}
        {subtitle !== '' && (
          <Row isFull>
            <Col>
              <Text as="h3" weight="bold" size="xlg" margin="0 0 0.5rem">
                {subtitle}
              </Text>
            </Col>
          </Row>
        )}
        {hasMainContent(main_lp_content) && <MainContentLP content={main_lp_content} />}
        {hasProductList(product_box_list) && <ProductList content={product_box_list} />}
        {show_products_carousel && product_categories_carousel && (
          <ProductsCategories categories={product_categories_carousel} />
        )}
        {show_static_warranty && <UseTerm />}
        {show_static_advantages && locale && (
          <Benefits advantages={locale.translate.page.product_landing_page.advantages} />
        )}
        {show_static_printing_plant && <TextVideo />}
        {content_lp_description && <Details content={content_lp_description} />}
        {show_advantages_info && locale && <Advantages locale={locale.translate.page.dynamic_page.more_advantages} />}
        {hasBanner(banners_bottom) && <BannerLP content={banners_bottom[0]} showTitle={!hide_banner_title} />}
      </Col>
    </Row>
  );
};

Dynamic.propTypes = {
  content: PropTypes.shape({
    banners_bottom: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    banners_top: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    content_lp: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    content_lp_description: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    faq: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    main_lp_content: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    product_box_list: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    product_categories_carousel: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    settings: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
    subtitle: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentList: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
};

export default memo(Dynamic);
