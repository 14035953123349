/* eslint-disable react/no-danger */
import React, { memo, useCallback, useState } from 'react';

import { Modal, Checkbox } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './ModalArtCreation.styles';

const ModalArtCreation = ({ close, redirect, artCreationRules }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { content, content_title } = artCreationRules;

  const handleChecked = useCallback(() => {
    setIsChecked((oldChecked) => !oldChecked);
  }, []);

  const handleRedirect = useCallback(() => {
    close();
    redirect();
  }, [close, redirect]);

  return (
    <Modal
      size="full"
      infos={{ title: content_title }}
      buttonName="OK"
      close={close}
      func={handleRedirect}
      isDisabled={!isChecked}
      otherAction={
        <Checkbox
          id="checkbox-confirm"
          name="checkbox-confirm"
          checked={isChecked}
          value="Checkbox"
          onChange={handleChecked}
          label="Li e aceito as regras da montagem de arte."
        />
      }
    >
      <S.ArtCreationSection dangerouslySetInnerHTML={{ __html: content }} />
    </Modal>
  );
};

ModalArtCreation.propTypes = {
  close: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  artCreationRules: PropTypes.shape({
    content: PropTypes.string.isRequired,
    content_title: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(ModalArtCreation);
