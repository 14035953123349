import { Row, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { space, color, font } = theme;

export const ContentRow = styled(Row)`
  margin-bottom: ${space.xl2};
`;

export const DetailsBox = styled.div`
  margin: ${space.md} 0 0;
`;

export const TextContent = styled.div`
  font-size: 1.1rem;
  font-weight: ${font.weight.normal};
  color: ${color.grayscale.darker};

  p {
    margin-bottom: ${space.xs};
  }
`;
