import { Input, theme, Modal } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, font, color, space } = theme;

export const Container = styled.div`
  color: ${color.grayscale.darker};
  font-family: ${font.family.text};
  font-size: ${font.size.xxsmall};

  .title-modal {
    margin-bottom: 1.5rem;
  }

  .content-row {
    display: flex;
    flex-direction: column;
    margin: ${space.lg} 0;

    @media (min-width: ${breakpoint.md}) {
      flex-direction: row;
    }
  }

  .col-50p {
    padding: 0 ${space.xs2};

    @media (min-width: ${breakpoint.md}) {
      padding: 0 ${space.md};
    }
  }

  h4 {
    font-size: ${font.size.small};
    line-height: ${font.lineHeight.small};
    margin-bottom: ${space.md};
  }

  h5 {
    font-size: ${font.size.xsmall};
    line-height: ${font.lineHeight.xsmall};
    margin-bottom: ${space.md};
  }

  p {
    font-size: ${font.size.xsmall};
    margin-bottom: ${space.md};
  }

  ul {
    li {
      font-size: ${font.size.xsmall};
      line-height: ${font.lineHeight.xsmall};
      list-style-type: square;
      margin-bottom: ${space.xs};
    }
  }
`;
export const Title = styled.h2`
  font-size: ${font.size.h5};
`;

export const StyledInput = styled(Input)`
  height: 6.5rem;

  svg {
    color: ${color.success.dark};
    fill: ${color.success.dark};
  }

  span {
    position: relative;
  }
`;

export const StyledModal = styled(Modal)`
  button:last-child {
    background: ${color.success.pure};
    border-color: ${color.success.pure};
  }
`;
