import React, { memo, useCallback, useState } from 'react';

import { Text, Button, Input, Modal } from '@printi/printi-components';
import { assertValidEmail, assertRequiredField } from '@utils';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import useFetchProductUnavailable from '../useFetchProductUnavailable';
import * as S from './Featured.styles';
const ModalArtCreation = dynamic(() => import('./ModalArtCreation'));
const ProductImages = dynamic(() => import('./ProductImages'));

const INITIAL_EMAIL = {
  value: '',
  error: '',
  hasError: true,
  dirty: false,
};

const Featured = ({ details }) => {
  const router = useRouter();
  const { combId } = router.query;
  const [artCreationModalIsOpen, setArtCreationModalIsOpen] = useState(false);
  const [productUnavailableModalIsOpen, setProductUnavailableModalIsOpen] = useState(false);
  const [email, setEmail] = useState(INITIAL_EMAIL);
  const { postProductUnavailable } = useFetchProductUnavailable();
  const {
    imagelist,
    title,
    subtitle,
    teaser_lp,
    minimum_price,
    minimum_quantity,
    product_disabled,
    final_product_id,
    is_digital,
    art_creation_rules,
  } = details;

  const handleRedirect = useCallback(() => {
    const productConfig = `configuracao-${details.slug}`;
    let productLink = productConfig;

    if (combId) {
      productLink = `${productConfig}?combId=${combId}`;
    }

    router.push(productLink, undefined, { shallow: true });
  }, [details.slug, combId, router]);

  const handleCloseModal = useCallback(() => {
    setProductUnavailableModalIsOpen(false);
  }, []);

  const handleChange = useCallback((e) => {
    const {
      currentTarget: { value, name },
      type,
    } = e;
    let error = '';

    if (name === 'email') {
      error = assertRequiredField(value) || assertValidEmail(value);
    }

    const newEmail = {
      value,
      error,
      hasError: Boolean(error),
    };

    if (type === 'blur') {
      newEmail.dirty = true;
    }

    setEmail(newEmail);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      postProductUnavailable(email, final_product_id);
      setProductUnavailableModalIsOpen(true);
    },
    [email, final_product_id, postProductUnavailable],
  );

  let buttonConfigure = {
    kind: 'inverse',
    name: 'Produto indisponível',
    disabled: true,
    action: null,
  };
  let inStock = false;

  if (!product_disabled) {
    buttonConfigure = {
      kind: 'success',
      name: 'Configure este produto',
      disabled: false,
      action: handleRedirect,
    };
    inStock = true;
  }

  if (is_digital) {
    buttonConfigure = {
      ...buttonConfigure,
      name: 'Contratar o Designer',
      action: () => setArtCreationModalIsOpen(true),
    };
  }

  return (
    <>
      <S.Wrapper isFull>
        <S.WrapperInfos>
          <ProductImages banners={imagelist} />
        </S.WrapperInfos>
        <S.WrapperInfos>
          <Text as="h1" weight="bold" size="h3" itemProp="name">
            {title}
          </Text>

          <Text as="h2" weight="bold" size="lg" margin="0 0 1rem 0" itemProp="description">
            {subtitle}
          </Text>

          <Text size="xs">A partir de*</Text>

          <div>
            <Text weight="bold" size="h3" color="primary" margin="0 0 1rem">
              <span>{`R$ `}</span>
              <span>{minimum_price}</span>
              <Text as="span" size="xs">
                / {minimum_quantity} un
              </Text>
            </Text>
          </div>

          <Button
            isBlock
            kind={buttonConfigure.kind}
            onClick={buttonConfigure.action}
            isDisabled={buttonConfigure.disabled}
            data-optimum="product-cta"
          >
            {buttonConfigure.name}
          </Button>

          {!inStock && (
            <>
              <Text margin="1rem 0 0">Avise-me quando o produto estiver disponível</Text>

              <S.ProductAvailableForm onSubmit={handleSubmit}>
                <fieldset>
                  <Input
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    isBlock
                    value={email.value || ''}
                    hasError={email.hasError && email.dirty}
                    error={email.error}
                    onChange={handleChange}
                    onBlur={handleChange}
                    css={{ paddingBottom: '0' }}
                  />
                  <Button type="submit" kind="success" isDisabled={email.hasError}>
                    Enviar
                  </Button>
                </fieldset>
              </S.ProductAvailableForm>
            </>
          )}

          {inStock && (
            <Text size="xs" padding="0.5rem 0 0">
              Na próxima tela você pode configurar as especificações do seu produto e conferir o preço
            </Text>
          )}

          <S.WrapperTeaser dangerouslySetInnerHTML={{ __html: teaser_lp }} />
        </S.WrapperInfos>
      </S.Wrapper>

      {productUnavailableModalIsOpen && (
        <Modal close={handleCloseModal} func={handleCloseModal} hideCancelButton buttonName="OK">
          <Text align="center">Seus dados foram enviados com sucesso!</Text>
        </Modal>
      )}

      {artCreationModalIsOpen && (
        <ModalArtCreation
          close={() => setArtCreationModalIsOpen(false)}
          redirect={handleRedirect}
          artCreationRules={art_creation_rules}
        />
      )}
    </>
  );
};

Featured.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    teaser_lp: PropTypes.string.isRequired,
    product_disabled: PropTypes.string.isRequired,
    final_product_id: PropTypes.string.isRequired,
    minimum_price: PropTypes.string.isRequired,
    minimum_quantity: PropTypes.string.isRequired,
    is_digital: PropTypes.bool.isRequired,
    art_creation_rules: PropTypes.shape({
      content: PropTypes.string,
      content_title: PropTypes.string,
    }),
    imagelist: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        filename: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default memo(Featured);
