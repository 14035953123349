import React, { memo, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import * as S from './Modal.styles';

const CustomQtyModal = ({ onClose, onSubmit, multipleQty, blockedValues, isUpdating }) => {
  const [state, setState] = useState({
    hasErrorQty: true,
    errorMsg: '',
    qty: null,
  });
  const [isBootstrapping, setIsBootstrapping] = useState(false);

  useEffect(() => {
    if (isBootstrapping && !isUpdating) {
      onClose();
    }
  }, [isBootstrapping, isUpdating, onClose]);

  const handleResponse = (e) => {
    let qty = e.currentTarget.value;
    qty = parseInt(qty, 10);
    const mod = qty % multipleQty;

    setState({
      ...state,
      hasErrorQty: false,
      errorMsg: '',
      qty,
    });

    if (!qty) {
      setState({
        ...state,
        hasErrorQty: true,
        errorMsg: 'Digite um valor',
        qty,
      });
    }

    if (blockedValues && blockedValues.indexOf(qty) >= 0) {
      setState({
        ...state,
        hasErrorQty: true,
        errorMsg: 'Quantidade já existente',
        qty,
      });
    }

    if (multipleQty > 0 && mod > 0) {
      setState({
        ...state,
        hasErrorQty: true,
        errorMsg: `Quantidade deve ser múltiplo de ${multipleQty}`,
        qty,
      });
    }
  };

  const isValid = () => !!state.qty;

  const handleSubmit = () => {
    const { qty } = state;
    if (typeof onSubmit === 'function') {
      setIsBootstrapping(true);
      onSubmit(qty, true, false);
    }
  };

  return (
    <S.StyledModal
      size="small"
      close={onClose}
      infos={{
        title: 'Adicionar nova quantidade',
      }}
      buttonName="Confirmar"
      func={() => handleSubmit()}
      isDisabled={state.hasErrorQty || !isValid() || isUpdating}
    >
      <>
        <S.StyledInput
          hasError={state.hasErrorQty}
          error={state.errorMsg}
          onChange={handleResponse}
          isBlock
          label="Digite a quantidade desejada"
          type="text"
          hasMask
          disabled={isUpdating}
          rightIcon={!state.hasErrorQty ? 'CheckmarkCircle2Outline' : ''}
          options={{ numericOnly: true }}
        />
      </>
    </S.StyledModal>
  );
};

CustomQtyModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  multipleQty: PropTypes.number,
  isUpdating: PropTypes.bool,
  blockedValues: PropTypes.arrayOf(PropTypes.number),
};

export default memo(CustomQtyModal);
