import { createSelector } from 'reselect';

const settingsSelector = (state) => state;

const filteredSettingsSelector = createSelector(settingsSelector, (settings) =>
  settings.options.parts.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.name]: curr.attributes.reduce(
        (pa, ca) => ({
          ...pa,
          [ca.name]: ca.options
            .filter(
              (opt) =>
                settings.selection && settings.selection[curr.id] && opt.id === settings.selection[curr.id][ca.key],
            )
            .reduce((po, co) => co.name, ''),
        }),
        {},
      ),
    }),
    {},
  ),
);

export default filteredSettingsSelector;
