import React, { memo } from 'react';

import Head from 'next/head';
import PropTypes from 'prop-types';

const StructuredData = ({ data }) => (
  <Head>
    <script
      key="structured-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  </Head>
);

StructuredData.propTypes = {
  data: PropTypes.object,
};

export default memo(StructuredData);
