import React, { memo, useCallback, useState } from 'react';

import { Modal, Checkbox } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Modal.styles';

const ArtCreation = ({ onClose, onSubmit, artCreationRules }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = useCallback(async () => {
    await onSubmit();
    onClose();
  }, [onClose, onSubmit]);

  const otherAction = (
    <Checkbox
      isChecked
      checked={isChecked}
      id="checkbox"
      kind="square"
      label="Li e aceito as regras da montagem de arte."
      name="checkbox"
      onChange={() => {
        setIsChecked(!isChecked);
      }}
      value="checkbox"
      data-optimum="accept-art-creation"
    />
  );

  return (
    <Modal
      size="full"
      buttonName="OK"
      close={onClose}
      infos={{ title: artCreationRules.content_title }}
      func={handleSubmit}
      isDisabled={!isChecked}
      otherAction={otherAction}
      showActions
      hideCancelButton
    >
      {artCreationRules && (
        <S.Container>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: artCreationRules.content }} />
        </S.Container>
      )}
    </Modal>
  );
};

ArtCreation.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  artCreationRules: PropTypes.object,
};

export default memo(ArtCreation);
