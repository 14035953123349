import { theme, Button, Box } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, space, font } = theme;

export const Container = styled.div`
  margin: ${space.xl} 0;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const WarningBox = styled(Box).attrs({
  type: 'gold',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.md};
  text-align: justify;
  padding: 1rem;

  @media screen and (max-width: ${breakpoint.hd}) {
    font-size: ${font.size.small};
    text-align: justify;
  }
  a {
    margin: ${space.xs2};
  }
  a:hover {
    text-decoration: none;
  }
`;

export const AddCartButton = styled(Button)`
  min-width: 16rem;
  display: none;

  @media (max-width: ${breakpoint.md}) {
    display: block;
    width: 100%;
  }

  @media (max-width: ${breakpoint.xl}) {
    display: block;
  }
`;

export const AddCartButtonMobile = styled(Button)`
  display: none;

  @media (max-width: ${breakpoint.md}) {
    display: block;
    width: 100%;
  }

  @media (max-width: ${breakpoint.xl}) {
    display: block;
  }
`;
