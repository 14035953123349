import React, { memo } from 'react';

import PropTypes from 'prop-types';

import * as S from './styles';

const PickupCardDisplay = ({ neighborhood, street, number, additionalAddress, city, state, zipCode, name }) => (
  <S.PickupCardContainer>
    <S.DisplayTitle>{name}</S.DisplayTitle>
    <S.Message>
      {street}
      {', '}
      {number}
      {additionalAddress ? `, ${additionalAddress}` : ''}
      <br />
      <span>Bairro:</span> {neighborhood}
      <br />
      <span>Cidade:</span> {city} - {state}
      <br />
      <span>CEP:</span> {zipCode}
    </S.Message>
  </S.PickupCardContainer>
);

PickupCardDisplay.propTypes = {
  neighborhood: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  additionalAddress: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  name: PropTypes.string,
};

export default memo(PickupCardDisplay);
