import React, { memo } from 'react';

import { Modal } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './ModalVideo.styles';

const ModalVideo = ({ setModalVideoIsOpen }) => (
  <Modal size="large" showActions={false} close={() => setModalVideoIsOpen(false)}>
    <S.WrapperVideo>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/VAh2g-F6nCg"
        title="Tour guiado pela fábrica da Printi - entenda porque somos mais que uma gráfica online"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </S.WrapperVideo>
  </Modal>
);

ModalVideo.propTypes = {
  setModalVideoIsOpen: PropTypes.func,
};

export default memo(ModalVideo);
