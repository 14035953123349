import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { color, space } = theme;

export const FourBusinessSection = styled.section`
  margin-bottom: ${space.xl4};
`;

export const FourBusinessHorizontalSeparator = styled.hr`
  margin: ${space.lg} 0 ${space.lg} 0;
  border: ${color.warning.light} solid ${space.xl};
  width: ${space.xl5};
  border-width: ${space.xs} 0 0 0;
`;

export const FourBusinessImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;
