import { useState, useCallback } from 'react';

import { customApi } from '@utils';

const useFetchPickUpPlaces = () => {
  const [pickupPlaces, setPickupPlaces] = useState([]);

  const fetchPickUpPlaces = useCallback(async () => {
    await customApi.get('/v1/pickup_places/addresses').then(({ data }) => setPickupPlaces(data));
  }, []);

  return {
    fetchPickUpPlaces,
    pickupPlaces,
  };
};

export default useFetchPickUpPlaces;
