import React, { memo } from 'react';

import { Row, Col, Icon } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Advantages.styles';

const Advantages = ({ locale }) => (
  <Row isFull>
    <Col>
      <S.AdvantagesBox shadow>
        <S.AdvantagesTitle>{locale.TITLE}</S.AdvantagesTitle>
        <S.AdvantagesList>
          {Object.keys(locale).map(
            (item) =>
              item !== 'TITLE' && (
                <li key={item}>
                  <Icon name="Checkmark" width="3.1rem" fill="#5DD97F" />
                  {locale[item]}
                </li>
              ),
          )}
        </S.AdvantagesList>
      </S.AdvantagesBox>
    </Col>
  </Row>
);

Advantages.propTypes = {
  locale: PropTypes.shape({
    TITLE: PropTypes.string.isRequired,
    ADVANTAGE_1: PropTypes.string.isRequired,
    ADVANTAGE_2: PropTypes.string.isRequired,
    ADVANTAGE_3: PropTypes.string.isRequired,
    ADVANTAGE_4: PropTypes.string.isRequired,
    ADVANTAGE_5: PropTypes.string.isRequired,
    ADVANTAGE_6: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Advantages);
