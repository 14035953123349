import React, { memo } from 'react';

import PropTypes from 'prop-types';

import * as S from './Image.styles';

const Image = ({ src, alt, width, height }) => <S.ImageResponsive width={width} height={height} src={src} alt={alt} />;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default memo(Image);
