import React, { memo, useCallback } from 'react';

import { Col, Icon, Logo, Button, Text } from '@printi/printi-components';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import * as S from './Benefits.styles';
import PrintingCompaniesCard from './PrintingCompaniesCard';

const Benefits = ({ advantages, product }) => {
  const router = useRouter();
  const { OTHER_ONLINE_PRINTING_COMPANIES, LIST, TRADITIONAL_PRINTING_COMPANIES, TITLE } = advantages;

  const handleNavigate = useCallback(
    (e) => {
      e.preventDefault();
      if (product) {
        const formattedHref = `configuracao-${product.slug}`;
        router.push(formattedHref);
        return true;
      }

      return true;
    },
    [product, router],
  );

  return (
    <S.BenefitsRow isFull>
      <Col col={12}>
        <S.Benefits>
          <Text as="h3" weight="bold" size="lg" margin="0 0 1rem">
            {TITLE}
          </Text>

          <S.AdvantagesContainer>
            <S.BenefitsCard isMain>
              <S.BenefitsCardItem isTitle isBig>
                <Logo id="printi-logo" title="Printi" size="md" />
              </S.BenefitsCardItem>
              {LIST.map((item) => (
                <S.BenefitsCardItem key={`advantages-${item}`} alignLeft>
                  <Icon name="Checkmark" width="1.6rem" fill="#5DD97F" />
                  {item}
                </S.BenefitsCardItem>
              ))}

              <S.BenefitsCardItem isBig>
                {product && product.product_disabled === '0' && (
                  <Button
                    css={{ display: 'flex', alignItems: 'center' }}
                    kind="success"
                    onClick={(e) => handleNavigate(e)}
                  >
                    Configure aqui
                  </Button>
                )}
              </S.BenefitsCardItem>
            </S.BenefitsCard>

            <PrintingCompaniesCard title={OTHER_ONLINE_PRINTING_COMPANIES} positionsCheck={[0, 2]} />

            <PrintingCompaniesCard title={TRADITIONAL_PRINTING_COMPANIES} positionsCheck={[5, 6, 7]} />
          </S.AdvantagesContainer>
        </S.Benefits>
      </Col>
    </S.BenefitsRow>
  );
};

Benefits.propTypes = {
  advantages: PropTypes.shape({
    LIST: PropTypes.arrayOf(PropTypes.string),
    OTHER_ONLINE_PRINTING_COMPANIES: PropTypes.string,
    TITLE: PropTypes.string,
    TRADITIONAL_PRINTING_COMPANIES: PropTypes.string,
  }).isRequired,
  product: PropTypes.object,
};

export default memo(Benefits);
