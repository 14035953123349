import React, { memo, useState, useCallback } from 'react';

import { RatingChart, RatingWithComment } from '@components/Rating';
import { Text, Button, Col } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Rating.styles';

const Rating = ({ ratingInfo }) => {
  const commentsPerPage = 3;
  const [commentsToShow, setCommentsToShow] = useState(commentsPerPage);
  const { list, count, itemReviewed } = ratingInfo;

  const handleShowMoreComments = useCallback(() => {
    setCommentsToShow((prevCommentsToShow) => prevCommentsToShow + commentsPerPage);
  }, []);

  const renderComments = () =>
    list.slice(0, commentsToShow).map((comment) => <RatingWithComment key={comment.id} commentInfos={comment} />);

  return (
    <S.RatingRow>
      <Col xs={12} md={5}>
        <Text as="h3" size="lg" weight="bold" transform="uppercase" margin="0 0 1rem">
          Mais opiniões
        </Text>

        <S.WrapperDescriptionRating>
          <Text size="xs" margin="0 1rem 0 0">
            Avaliação geral de {count} avaliações.
          </Text>
        </S.WrapperDescriptionRating>

        <RatingChart infos={ratingInfo} />
      </Col>
      <Col xs={12} md={7}>
        <Text as="h3" size="lg" weight="bold" margin="1rem 0 1rem">
          E você, o que achou?
        </Text>
        <S.SendComment>
          <Button as="a" href={`mailto:suporte@printi.com.br?subject=Avaliação do produto ${itemReviewed}`}>
            Enviar comentário
          </Button>
        </S.SendComment>
        <S.WrapperComments>{renderComments()}</S.WrapperComments>
        {list.length >= commentsToShow && (
          <S.ButtonTranparent kind="inverse" isBlock onClick={handleShowMoreComments}>
            carregar mais avaliações ({list.length - commentsToShow})
          </S.ButtonTranparent>
        )}
      </Col>
    </S.RatingRow>
  );
};

Rating.propTypes = {
  ratingInfo: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
    count: PropTypes.number.isRequired,
    avgNumber: PropTypes.string.isRequired,
    itemReviewed: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Rating);
