import React from 'react';

import { Text } from '@printi/printi-components';

import { DiscountIcon } from './icons';
import * as S from './styles';

export const AlertShipping = () => (
  <S.MatrixInfoBox>
    <div>
      <DiscountIcon />
      <S.MatrixWarningInfo>
        <Text>
          Na escolha da data de entrega <strong>elegível a frete grátis</strong>, utilize o cupom disponibilizado no{' '}
          <strong>carrinho de compras</strong>.
        </Text>
      </S.MatrixWarningInfo>
    </div>
  </S.MatrixInfoBox>
);
