import { useState, useCallback } from 'react';

import { customApi } from '@utils';
const useFetchPrepressTemplates = () => {
  const [options, setOptions] = useState();
  const [parts, setParts] = useState();
  const [prepressTemplatesLoading, setPrepressTemplatesLoading] = useState(false);

  const fetchPrepressTemplates = useCallback(async (productId, selection) => {
    setPrepressTemplatesLoading(true);

    const body = Object.keys(selection).reduce(
      (prevPart, currentPart) => ({
        ...prevPart,
        [currentPart]: Object.keys(selection[currentPart]).reduce(
          (prevAttribute, currentAttribute) => ({
            ...prevAttribute,
            [`product_${currentAttribute}_id`]: selection[currentPart][currentAttribute],
          }),
          {},
        ),
      }),
      {},
    );

    await customApi.post(`/v1/prepress_template/download_options/${productId}`, body).then(({ data }) => {
      setOptions(() => data.options);
      setParts(() => data.parts);
    });

    setPrepressTemplatesLoading(false);
  }, []);

  return {
    fetchPrepressTemplates,
    options,
    parts,
    prepressTemplatesLoading,
  };
};

export default useFetchPrepressTemplates;
