import React, { memo } from 'react';

import { Text, Row, Col } from '@printi/printi-components';
import { cloudFront } from '@utils';

import * as S from './FourBusiness.styles';

const FourBusiness = ({ fourBusiness }) => (
  <S.FourBusinessSection>
    <Row>
      <Col xs={12} lg={7}>
        <S.FourBusinessImg
          src={`${cloudFront}${fourBusiness?.image?.filename}`}
          alt="4Business"
          width="700"
          height="350"
        />
      </Col>

      <Col xs={12} lg={5}>
        <Text as="h5" weight="bold" size="h3" margin="0 0 1rem">
          {fourBusiness?.title}
        </Text>
        <S.FourBusinessHorizontalSeparator />
        <div dangerouslySetInnerHTML={{ __html: fourBusiness.text }} />
      </Col>
    </Row>
  </S.FourBusinessSection>
);

export default memo(FourBusiness);
