import React, { memo, useCallback, useEffect, useState } from 'react';

import { Row, Col, Text, Icon } from '@printi/printi-components';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import PickupCardDisplay from './PickupCardDisplay';
import * as S from './styles';

const FunnelBlock = dynamic(() => import('@components/Funnel/FunnelBlock'));
const BoxCard = dynamic(() => import('../BoxCard'));
const PickupPlaceModal = dynamic(() => import('@components/Funnel/PickupPlaceModal'));

const PickupPlace = ({ order, pickupPlaces, handleMatrix }) => {
  const pickupIdStorage = localStorage.getItem('pickupPlaceId');
  const [pickupId, setPickupId] = useState(pickupIdStorage || null);
  const [isPickupPlaceModalOpen, setIsPickupPlaceModalOpen] = useState(!pickupIdStorage);
  const [pickupPlaceAddr, setPickupPlaceAddr] = useState('');
  const [filteredPickupPlaceAddr, setFilteredPickupPlaceAddr] = useState([]);

  const getPickupPlacesAddressById = (pickupPlacesList, id) =>
    pickupPlacesList.find((place) => parseInt(place.id, 10) === parseInt(id, 10));

  const handlePickupChange = useCallback(
    (pickupPlaceId) => {
      const id = parseInt(pickupPlaceId, 10);
      const pickupPlaceAddrValid = getPickupPlacesAddressById(pickupPlaces, id);
      localStorage.setItem('pickupPlaceId', id);
      setPickupId(id);
      setPickupPlaceAddr(pickupPlaceAddrValid);
      setFilteredPickupPlaceAddr([pickupPlaceAddrValid]);
      handleMatrix(pickupPlaceAddrValid.zipCode, id);
    },
    [handleMatrix, pickupPlaces],
  );
  const handleResetSelect = useCallback(async () => {
    setPickupId('');
  }, []);

  useEffect(() => {
    if (pickupId && pickupId > 0) {
      handlePickupChange(pickupId);
    }
  }, [handlePickupChange]);

  return (
    <FunnelBlock order={order} title="Pontos de retirada" isComplete={!!pickupId}>
      <Row isFull>
        {filteredPickupPlaceAddr &&
          filteredPickupPlaceAddr.map((i) => (
            <Col xs={12} lg={6} key={`selected-${i.id}`}>
              <BoxCard
                kind="base"
                name="pickup-place"
                onChange={() => handlePickupChange(i.id)}
                value={i.id}
                checked={pickupId === i.id}
                hidden={!!pickupId && pickupId !== i.id}
              >
                <PickupCardDisplay
                  message={i.freeText}
                  neighborhood={i.neighborhood}
                  receiverName={i.receiverName}
                  street={i.street}
                  number={i.number}
                  additionalAddress={i.additionalAddress}
                  city={i.city}
                  state={i.stateCode}
                  zipCode={i.zipCode}
                  name={i.name}
                />
              </BoxCard>
            </Col>
          ))}
        <S.ColBlockPickup xs={12} lg={12}>
          <S.CustomButton isSmall isBlock kind="primary" onClick={() => setIsPickupPlaceModalOpen(true)}>
            Quero ver outros pontos de retirada
          </S.CustomButton>
        </S.ColBlockPickup>
        {filteredPickupPlaceAddr.length > 1 && pickupId > 0 && (
          <Col xs={12} lg={12}>
            <S.CustomButton kind="inverse" isOutlined onClick={handleResetSelect}>
              <Text color="gray" weight="normal" size="xs" margin=" 0 0.5rem 0 1rem">
                Alterar
              </Text>
              <Icon fill="#2C2C3A" id="activity" name="ArrowIosDownwardOutline" size="sm" title="Activity" />
            </S.CustomButton>
          </Col>
        )}
      </Row>
      {isPickupPlaceModalOpen && (
        <PickupPlaceModal
          close={() => setIsPickupPlaceModalOpen(false)}
          callback={handlePickupChange}
          pickupPlaces={pickupPlaces}
          pickupPlaceAddr={pickupPlaceAddr || []}
        />
      )}
    </FunnelBlock>
  );
};

PickupPlace.propTypes = {
  order: PropTypes.number.isRequired,
  pickupPlaces: PropTypes.array.isRequired,
  handleMatrix: PropTypes.func.isRequired,
};

export default memo(PickupPlace);
