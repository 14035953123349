import { theme } from '@printi/printi-components';
import styled, { css } from 'styled-components';
const { breakpoint, borders, color, font, space } = theme;

export const MatrixDigitalProduct = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const Day = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.primary.dark};

  span {
    padding-right: ${space.xs};
    font-size: ${font.size.h3};
    line-height: 1;
    text-transform: capitalize;
  }
`;

export const Price = styled.span`
  color: ${color.grayscale.black};
  font-size: ${font.size.h6};
  line-height: 1;
`;

export const ButtonProduct = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(50% - ${space.xs2});
  height: 100%;
  margin-right: ${space.xs};
  margin-bottom: ${space.xs};
  padding: ${space.xs} 0 ${space.sm};
  border-radius: ${borders.radius.md};
  border: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  font-weight: ${font.weight.bold};
  text-align: center;
  outline: none;

  &:nth-of-type(even) {
    margin-right: 0;
  }

  ${(p) =>
    p.isExpress &&
    css`
      background-color: ${color.warning.pale};
    `}

  ${(p) =>
    p.isShipping &&
    css`
      background-color: ${color.success.pale};
    `}

  ${(p) =>
    p.isChecked &&
    css`
      background-color: ${color.primary.darker};

      ${Day},
      ${Price} {
        color: ${color.grayscale.white};
      }
    `}

  &:hover {
    background-color: ${color.primary.darker};

    span {
      color: ${color.grayscale.white};
    }
  }

  @media (min-width: ${breakpoint.md}) {
    width: calc(33.33% - 0.67rem);

    &:nth-of-type(odd) {
      margin-right: 0;
    }

    &:not(:nth-of-type(3n)) {
      margin-right: ${space.md};
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    width: calc(25% - ${space.sm});

    &:not(:nth-of-type(3n)) {
      margin-right: 0;
    }

    &:not(:nth-of-type(4n)) {
      margin-right: ${space.md};
    }
  }
`;
