import React, { memo } from 'react';

import { Bar } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Rating.styles';
import RatingStar from './RatingStar';

const interactions = [5, 4, 3, 2, 1];

const RatingChart = ({ infos }) =>
  interactions.map((item) => (
    <S.WrapperRow key={item}>
      <S.ColStars>
        <RatingStar numStar={item} />
      </S.ColStars>
      <S.ColChartBar>
        <Bar hasCounter={false} color="#009BFF" total={infos.count} achieved={infos.stars[item] || 0} />
      </S.ColChartBar>
      <S.ColCounter>{infos.stars[item] || 0}</S.ColCounter>
    </S.WrapperRow>
  ));

RatingChart.propTypes = {
  infos: PropTypes.shape({
    count: PropTypes.number,
    stars: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.number), PropTypes.arrayOf(PropTypes.number)]),
  }).isRequired,
};

export default memo(RatingChart);
