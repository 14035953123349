import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { borders, color, font, space } = theme;

export const FunnelBlock = styled.div`
  width: 100%;
  margin-bottom: ${space.md};
  border: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  border-radius: ${borders.radius.md};
  background-color: ${color.grayscale.white};
`;

export const FunnelBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${space.md};
  padding: ${space.md};
`;

export const FunnelBlockTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const Order = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${space.xl};
  min-width: ${space.xl};
  height: ${space.xl};
  margin-right: ${space.sm};
  border-radius: ${borders.radius.circle};
  background-color: ${(p) => (p.complete ? color.success.pure : color.primary.pure)};
  color: ${color.grayscale.white};
  font-family: ${font.family.heading};
  font-size: ${font.size.h6};
  font-weight: ${font.weight.bold};
`;

export const FunnelBlockBody = styled.div`
  padding: 0 ${space.md};
`;
