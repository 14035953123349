import { Row, Col, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { space, color } = theme;

export const CallToActionRow = styled(Row)`
  margin-bottom: ${space.xl};
  padding: ${space.xl} 0;
  background-color: ${color.dark.dark};
`;

export const CallToActionCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
