import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { borders, color, space } = theme;

export const PageBackground = styled.section`
  margin-top: -${space.xl};
  padding-bottom: ${space.xl};
  z-index: 0;
  background-color: white;
  border-bottom: ${borders.sizes.sm} solid ${color.grayscale.lighter};
`;
