import React, { memo, useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Text, Icon, theme } from '@printi/printi-components';
import { settingsMatrixReset, settingsSetDeliveryMethod } from '@store/settings/actions';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import Zip from '../Zip';
import * as S from './styles';
const FunnelBlock = dynamic(() => import('@components/Funnel/FunnelBlock'));

const DeliveryBlock = ({ order, handleMatrix, zipcode }) => {
  const dispatch = useDispatch();
  const [deliveryMethod, setDeliveryMethod] = useState('');

  let title = 'Como você quer receber';
  if (deliveryMethod === 'pickupPlaces') {
    title = 'Pontos de retirada';
  }

  const isComplete = useMemo(
    () => deliveryMethod.length > 0 && ['zipcode', 'pickupPlaces'].includes(deliveryMethod) && zipcode.isZipcodeValid,
    [deliveryMethod, zipcode.isZipcodeValid],
  );

  const handleDeliveryMethod = useCallback(
    (method, zip) => {
      setDeliveryMethod(method);
      dispatch(settingsSetDeliveryMethod(method));
      if (isComplete && deliveryMethod === 'zipcode') {
        handleMatrix(zip);
      }
    },
    [dispatch, handleMatrix],
  );

  const resetDeliveryMethod = useCallback(() => {
    dispatch(settingsSetDeliveryMethod(''));
    setDeliveryMethod('');
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(settingsMatrixReset());
      resetDeliveryMethod();
    },
    [dispatch, resetDeliveryMethod],
  );

  const mountDelivery = useCallback(() => {
    if (isComplete) handleDeliveryMethod(deliveryMethod, zipcode.value);
  }, [deliveryMethod, handleDeliveryMethod, isComplete, zipcode.value]);

  useEffect(() => {
    mountDelivery();
  }, [mountDelivery]);

  return (
    <FunnelBlock order={order} title={title} isComplete={isComplete}>
      <S.BlockButtons>
        <S.ButtonDeliveryMethod
          checked={deliveryMethod === 'zipcode'}
          onClick={() => handleDeliveryMethod('zipcode', zipcode.value)}
        >
          <Icon name="CarOutline" size="md" fill={theme.color.grayscale.gray} />
          <Text as="span" color="gray" weight="bold" size="md" margin=" 0 0.5rem 0 1rem">
            Entrega
          </Text>
        </S.ButtonDeliveryMethod>
        <S.ButtonDeliveryMethod
          checked={deliveryMethod === 'pickupPlaces'}
          onClick={() => handleDeliveryMethod('pickupPlaces', zipcode.value)}
        >
          <Icon name="PinOutline" size="md" fill={theme.color.grayscale.gray} />
          <Text as="span" color="gray" weight="bold" size="md" margin=" 0 0.5rem 0 1rem">
            Retirada
          </Text>
        </S.ButtonDeliveryMethod>
      </S.BlockButtons>

      {deliveryMethod === 'zipcode' && <Zip />}
    </FunnelBlock>
  );
};

DeliveryBlock.propTypes = {
  order: PropTypes.number.isRequired,
  zipcode: PropTypes.object.isRequired,
  handleMatrix: PropTypes.func.isRequired,
};

export default memo(DeliveryBlock);
