import React, { memo, useState } from 'react';

import { Icon } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Benefits.styles';

const PrintingCompaniesCard = ({ title, positionsCheck, numberItems }) => {
  const [interactions] = useState(Array.from({ length: numberItems }, (v, i) => i));

  return (
    <S.BenefitsCard>
      <S.BenefitsCardItem isTitle>{title}</S.BenefitsCardItem>
      {interactions.map((actualNum, index) => (
        <S.BenefitsCardItem key={actualNum}>
          {positionsCheck.includes(index) ? (
            <Icon name="Checkmark" width="1.6rem" fill="#5DD97F" />
          ) : (
            <Icon name="Close" width="1.4rem" fill="#FA636B" />
          )}
        </S.BenefitsCardItem>
      ))}
    </S.BenefitsCard>
  );
};

PrintingCompaniesCard.propTypes = {
  title: PropTypes.string.isRequired,
  positionsCheck: PropTypes.arrayOf(PropTypes.number).isRequired,
  numberItems: PropTypes.number,
};

PrintingCompaniesCard.defaultProps = {
  numberItems: 8,
};

export default memo(PrintingCompaniesCard);
